import { Paper, ToggleButton, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import styled from 'styled-components'

const drawerWidth = 320

export const useDrawerStyles = makeStyles(() =>
  createStyles({
    root: {
      width: drawerWidth,
      flexShrink: 0,
      borderRight: 0
    },
    paper: {
      width: drawerWidth,
      borderRight: 0
    }
  })
)

export const DrawerHeader = styled(Paper).attrs({ elevation: 2, square: true })`
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: space-between;
  min-height: calc(30px + ${(props) => props.theme.spacing(4)});
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(5)};
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const DrawerFooter = styled(Paper).attrs({ variant: 'outlined', square: true })`
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(4)};
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const DrawerContent = styled.div`
  flex: 1;
  overflow-y: auto;
`

export const DrawerTitle = styled(Typography).attrs({ variant: 'h4' })`
  width: 100%;
`

export const DrawerToggleButton = styled(ToggleButton)`
  flex: 1 0;
  border-radius: 0;
`
