import { ErrorDisplay, ErrorDisplayContainer } from '@client/components/ErrorDisplay/ErrorDisplay'
import { PageLoader } from '@client/components/page-loader/PageLoader'
import { useAuthorization } from '@client/hooks/use-authorization'
import { useUser } from '@client/providers/user'
import { ALGOLIA_INDEX, BRAND_INDEX, DISPENSARY_INDEX, VARIANT_INDEX, getSearchClient } from '@client/services/algolia'
import { DatabaseFilterMap } from '@client/types/filterset'
import { Box, Button } from '@mui/material'
import { SearchClient, SearchIndex } from 'algoliasearch'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

export interface AlgoliaContextInterface {
  globalAlgoliaSearchClients: Record<ALGOLIA_INDEX, SearchClient>
  algoliaSearchClients: Record<ALGOLIA_INDEX, SearchClient>
  searchIndexes: Record<ALGOLIA_INDEX, SearchIndex>
  variantsIndex: SearchIndex
  dispensariesIndex: SearchIndex
  globalIndexes: Record<ALGOLIA_INDEX, SearchIndex>
}

const AlgoliaContext = createContext<AlgoliaContextInterface>({} as AlgoliaContextInterface)

const rootKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY
const variantFacetsToGroup = [DatabaseFilterMap.cmIds[VARIANT_INDEX], DatabaseFilterMap.menuIds[VARIANT_INDEX]]

const AlgoliaProvider: FC<PropsWithChildren<{ useRootKey?: boolean }>> = ({ useRootKey, children }) => {
  const { data, error } = useAuthorization()
  const { logout } = useUser()
  const [algoliaContext, setAlgoliaContext] = useState<AlgoliaContextInterface>()

  useEffect(() => {
    if (data || useRootKey) {
      const algoliaSearchClients = {
        [VARIANT_INDEX]: getSearchClient(
          data ? data.algolia.subscriptionSecureApiKeys.products : rootKey,
          variantFacetsToGroup
        ),
        [DISPENSARY_INDEX]: getSearchClient(data ? data.algolia.subscriptionSecureApiKeys.dispensaries : rootKey),
        [BRAND_INDEX]: getSearchClient(rootKey)
      }
      const globalAlgoliaSearchClients = {
        [VARIANT_INDEX]: getSearchClient(
          data ? data.algolia.globalSecureApiKeys.products : rootKey,
          variantFacetsToGroup
        ),
        [DISPENSARY_INDEX]: getSearchClient(data ? data.algolia.globalSecureApiKeys.dispensaries : rootKey),
        [BRAND_INDEX]: getSearchClient(rootKey)
      }
      const variantsIndex = algoliaSearchClients[VARIANT_INDEX].initIndex(VARIANT_INDEX)
      const dispensariesIndex = algoliaSearchClients[DISPENSARY_INDEX].initIndex(DISPENSARY_INDEX)
      const globalIndexes = {
        [VARIANT_INDEX]: globalAlgoliaSearchClients[VARIANT_INDEX].initIndex(VARIANT_INDEX),
        [DISPENSARY_INDEX]: globalAlgoliaSearchClients[DISPENSARY_INDEX].initIndex(DISPENSARY_INDEX),
        [BRAND_INDEX]: globalAlgoliaSearchClients[BRAND_INDEX].initIndex(BRAND_INDEX)
      }
      setAlgoliaContext({
        globalAlgoliaSearchClients,
        algoliaSearchClients,
        variantsIndex,
        dispensariesIndex,
        globalIndexes,
        searchIndexes: {
          [VARIANT_INDEX]: variantsIndex,
          [DISPENSARY_INDEX]: dispensariesIndex
        }
      })
    }
    return () => {
      if (algoliaContext?.globalAlgoliaSearchClients) {
        Object.values(algoliaContext.globalAlgoliaSearchClients).forEach((client) => {
          if (client.destroy) {
            client.destroy()
          }
        })
      }
      if (algoliaContext?.algoliaSearchClients) {
        Object.values(algoliaContext.algoliaSearchClients).forEach((client) => {
          if (client.destroy) {
            client.destroy()
          }
        })
      }
    }
    // We only want to trigger when the data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (error) {
    return (
      <Box width="100%" height="100%" m="auto auto">
        <ErrorDisplayContainer>
          <ErrorDisplay message="Authorization failed.">
            <Button variant="outlined" onClick={logout}>
              Sign out
            </Button>
          </ErrorDisplay>
        </ErrorDisplayContainer>
      </Box>
    )
  }

  return algoliaContext ? (
    <AlgoliaContext.Provider value={algoliaContext}>{children}</AlgoliaContext.Provider>
  ) : (
    <PageLoader />
  )
}

function useAlgolia() {
  const context = useContext(AlgoliaContext)

  if (!context) {
    throw new Error('useAlgolia must be used within a AlgoliaProvider.')
  }

  return context
}

export { AlgoliaProvider, useAlgolia }
