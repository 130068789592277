import { MenuItemText } from '@client/components/menu'
import { useDrawers } from '@client/hooks/use-drawers'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useSavedViews } from '@client/providers/saved-views'
import { VARIANT_INDEX } from '@client/services/algolia'
import { DrawerState, useDrawerState } from '@client/stores/use-drawer-state'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import {
  ALL_PRODUCT_FILTERS,
  KEYWORD_SEARCH_FILTER,
  applyFilterOverrides,
  extractFilters,
  filtersFromSearchState
} from '@client/types/filterset'
import { DraftFilterset, View, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, ButtonGroup } from '@mui/material'
import clone from 'fast-clone'
import { memo, useCallback } from 'react'
import { SearchState } from 'react-instantsearch-core'

export interface FilterActionsProps {
  isDirty: boolean
  numActiveFilters: number
  searchState: SearchState
  disableUpdateView?: boolean
  onClose: () => void
}

export const FilterActions: React.FC<FilterActionsProps> = memo(function FilterActions({
  isDirty,
  disableUpdateView = false,
  numActiveFilters,
  searchState,
  onClose
}) {
  const { tempFilters: savedTempFilters, globalFilterset, setGlobalFilterset, updateSavedView } = useSavedViews()
  const { drawer } = useDrawerState()
  const { openSaveViewDrawer } = useDrawers()
  const { toast } = useToastNotification()
  const currentFilterset = (drawer?.state.currentFilterset || emptyDraftFilterset()) as DraftFilterset
  const { handleClick: openActionMenu, handleClose: closeActionMenu, menuAnchor: actionMenuAnchor } = useMenuAnchor()

  const customApply = drawer?.state.onApply
  const disableViews = drawer?.state.disableViews
  const tempFilters = drawer?.state.tempFilters || savedTempFilters

  const getSearchStateFilters = useCallback(() => {
    return extractFilters(searchState, tempFilters?.visibleFilters || [...ALL_PRODUCT_FILTERS, KEYWORD_SEARCH_FILTER])
  }, [searchState, tempFilters?.visibleFilters])

  const handleApply = useCallback(async () => {
    // If the filters have changed, apply the selected filters as a new draft filterset
    // and close the drawer.
    if (isDirty) {
      if (customApply) {
        customApply({ filters: getSearchStateFilters() })
      } else {
        const filtersetToApply = applyFilterOverrides(
          {
            filters: globalFilterset.filters
          },
          {
            filters: getSearchStateFilters()
          },
          true
        )
        setGlobalFilterset(filtersetToApply)
      }
      toast.success(`Filters ${numActiveFilters ? 'applied' : 'cleared'}`, { autoHideDuration: 2000 })
    }
    onClose()
  }, [
    customApply,
    getSearchStateFilters,
    globalFilterset.filters,
    isDirty,
    numActiveFilters,
    onClose,
    setGlobalFilterset,
    toast
  ])

  const handleUpdateView = useCallback(async () => {
    // Update the saved view with the selected filters
    // Also set the view as the current filterset and close the drawer.
    const newView = applyFilterOverrides(
      globalFilterset,
      {
        filters: getSearchStateFilters()
      },
      true
    ) as View
    const updatedFilterset = await updateSavedView(newView)
    if (updatedFilterset) {
      setGlobalFilterset(newView)
      toast.success(`View '${newView.name}' updated`, { autoHideDuration: 2000 })
      onClose()
    } else {
      toast.error(`Could not update ${newView.name}`)
    }
  }, [globalFilterset, getSearchStateFilters, updateSavedView, setGlobalFilterset, toast, onClose])

  const handleSaveNewView = useCallback(() => {
    // Pass the selected filters to the 'Save View' drawer.
    // Also update the 'prevDrawer' state with the selected filters
    // so if we cancel back to the filters drawer, these filters
    // will be re-applied
    const filtersetToSave = applyFilterOverrides(
      {
        filters: globalFilterset.filters
      },
      {
        filters: getSearchStateFilters()
      },
      true
    )
    const prevDrawer = clone(drawer as DrawerState)
    prevDrawer.state.currentFilterset = {
      ...currentFilterset,
      filters: filtersFromSearchState(searchState, VARIANT_INDEX)
    }
    openSaveViewDrawer(filtersetToSave, prevDrawer)
  }, [globalFilterset.filters, getSearchStateFilters, drawer, currentFilterset, searchState, openSaveViewDrawer])

  return (
    <>
      <ButtonGroup size="small" color="secondary" variant="contained" sx={{ ml: 2 }}>
        <Button onClick={handleApply} data-testid="filter-actions-apply">
          Apply
        </Button>
        {!disableViews && (
          <Button onClick={openActionMenu} data-testid="filter-actions-drop">
            <ArrowDropDown />
          </Button>
        )}
      </ButtonGroup>
      {!disableViews && (
        <PopupMenu
          id="filters-action-menu"
          anchorEl={actionMenuAnchor}
          onClose={() => closeActionMenu()}
          open={!!actionMenuAnchor}
        >
          {currentFilterset.id && (
            <PopupMenuItem
              disabled={disableUpdateView || numActiveFilters < 1}
              data-testid="filter-actions-update-view"
              onClick={handleUpdateView}
            >
              <MenuItemText primary={`Update '${currentFilterset.name}'`} />
            </PopupMenuItem>
          )}
          <PopupMenuItem
            disabled={numActiveFilters < 1}
            data-testid="filter-actions-save-view"
            onClick={handleSaveNewView}
          >
            <MenuItemText primary="Save as new view" />
          </PopupMenuItem>
        </PopupMenu>
      )}
    </>
  )
})
