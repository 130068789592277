import { DraftFilterset, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { ListItem, ListItemText } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { EditableTitle } from '@client/components/EditableTitle/EditableTitle'
import { SummarizedDataText as FilterSummary } from '@client/components/summarized-data-text/SummarizedDataText'
import { useSavedViews } from '@client/providers/saved-views'
import { getFilterSummary } from '@client/types/filterset'

export interface DraftFiltersetDrawerListItemProps {
  filterset: DraftFilterset
}

export const DraftFiltersetListItem: React.FC<DraftFiltersetDrawerListItemProps> = memo(
  function DraftFiltersetListItem({ filterset }) {
    const { insertSavedView, setGlobalFilterset } = useSavedViews()

    const onChangeName = useCallback(
      async (name: string) => {
        const filtersetToSave = {
          ...filterset,
          name
        }
        const newView = await insertSavedView(filtersetToSave)
        setGlobalFilterset(newView || emptyDraftFilterset())
      },
      [filterset, insertSavedView, setGlobalFilterset]
    )

    const filterSummary = useMemo(() => getFilterSummary(filterset), [filterset])
    return (
      <ListItem selected data-testid="draft-filterset-list-item" divider>
        <ListItemText
          primaryTypographyProps={{ variant: 'h4', fontStyle: 'italic' }}
          primary={
            <EditableTitle
              title=""
              placeholder="Current filters"
              variant="complete"
              allowEmpty={false}
              props={{
                sx: {
                  fontSize: 17,
                  flexGrow: 1
                }
              }}
              onChange={onChangeName}
              BoxProps={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
              buttonLabel="Name this view"
            />
          }
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={<FilterSummary summaryData={filterSummary} />}
        ></ListItemText>
      </ListItem>
    )
  }
)
