export enum toggles {
  global_search = 'global_search',
  analytics_tracking = 'analytics_tracking',
  ux_monitoring = 'ux_monitoring',
  performance_monitoring = 'performance_monitoring',
  feedback_widget = 'feedback_widget',
  account_management = 'account_management',
  menu_analytics_kpi_alerts = 'menu_analytics_kpi_alerts',
  wholesale_product_matching = 'wholesale_product_matching',
  integrations = 'integrations',
  relationship_manager = 'relationship_manager',
  brand_catalog = 'brand_catalog',
  user_profile = 'user_profile'
}

const featureToggles: Partial<Record<toggles, boolean>> = {}

const featureToggleList = (process.env?.REACT_APP_FEATURE_FLAG?.split(/,\s?/) || []) as toggles[]

featureToggleList.reduce((acc, feature) => {
  acc[feature] = true
  return acc
}, featureToggles)

export const featureToggleMap = featureToggles

export const useFeatureToggle = () => {
  const isEnabled = (featureName: toggles) => !!featureToggles[featureName]
  return { isEnabled, toggles }
}
