import { ClearRefinements } from '@client/components/algolia/clear-refinements'
import { QUERY_ATTRIBUTE } from '@client/components/algolia/clear-refinements/ClearRefinements'
import { FilterSwitchGroup } from '@client/components/algolia/filter-switch-group/FilterSwitchGroup'
import { FilterToggleGroup } from '@client/components/algolia/filter-toggle-group/FilterToggleGroup'
import { HierarchicalMenu } from '@client/components/algolia/hierarchical-menu'
import { RefinementList } from '@client/components/algolia/refinement-list'
import { SearchBox } from '@client/components/algolia/search-box'
import { FilterProductSku } from '@client/components/edit-filterset-dialog/components/filter-product-sku'
import { slugify } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { Box } from '@client/styles/theme/box'
import { FilterOption, PRODUCT_SKUS_VARIANT_FIELDS, filtersFromSearchState } from '@client/types/filterset'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse } from '@mui/material'
import { FC, PropsWithChildren, ReactNode, memo, useCallback, useMemo } from 'react'
import { RefinementListExposed, SearchState } from 'react-instantsearch-core'
import { RequireAllOrNone } from 'type-fest'
import * as S from './FilterSection.style'

const refinementListProps: Partial<RefinementListExposed> = {
  showMore: true,
  limit: 5,
  searchable: true
}

type ControlledFilterSectionProps = RequireAllOrNone<
  {
    collapsed: boolean
    onToggleCollapse: (attribute: string, isCollapsed: boolean) => void
  },
  'collapsed' | 'onToggleCollapse'
>

type BaseFilterSectionProps = {
  clearComponent?: ReactNode
  disableExpandCollapse?: boolean
  disabled?: boolean
  filterKey: string
  hidden?: boolean
  initiallyCollapsed?: boolean
  isPopulated?: boolean
  label: string
  index?: number
}

export const BaseFilterSection: FC<PropsWithChildren<BaseFilterSectionProps & ControlledFilterSectionProps>> = memo(
  function BaseFilterSection({
    children,
    clearComponent,
    collapsed,
    disableExpandCollapse,
    disabled,
    filterKey,
    isPopulated,
    hidden,
    initiallyCollapsed,
    label,
    onToggleCollapse,
    index = 0
  }) {
    const isControlled = collapsed !== undefined

    const {
      value: internalIsExpanded,
      setTrue: expand,
      setFalse: collapse
    } = useBooleanState(!disabled && (!initiallyCollapsed || !!isPopulated))

    const isExpanded = disableExpandCollapse || (isControlled ? !disabled && !collapsed : internalIsExpanded)

    const handleClick = useCallback(() => {
      if (isControlled) {
        return onToggleCollapse(label, !collapsed)
      }
      return isExpanded ? collapse() : expand()
    }, [isControlled, isExpanded, collapse, expand, onToggleCollapse, label, collapsed])

    return (
      <S.FilterSection
        data-id={`filter-section-index--${index}`}
        data-testid={`filter-section--${slugify(filterKey)}`}
        hidden={hidden}
      >
        <S.FilterSectionTitleBar>
          <S.FilterSectionTitle
            variant="subtitle1"
            disabled={disabled}
            onClick={disabled ? undefined : handleClick}
            sx={{ cursor: disabled ? undefined : 'pointer' }}
          >
            {label}
          </S.FilterSectionTitle>
          {!disabled && clearComponent}
          <S.ExpandButton
            data-testid="filter-section--expand"
            disabled={disabled || disableExpandCollapse}
            size="small"
            onClick={handleClick}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </S.ExpandButton>
        </S.FilterSectionTitleBar>
        <Collapse in={isExpanded} timeout="auto">
          <Box my={2}>{children}</Box>
        </Collapse>
      </S.FilterSection>
    )
  }
)

export type FilterSectionProps = Omit<
  BaseFilterSectionProps,
  'clearComponent' | 'filterKey' | 'isPopulated' | 'label'
> & {
  filter: FilterOption
  searchState: SearchState
} & ControlledFilterSectionProps

export const FilterSection: React.FC<FilterSectionProps> = memo(function FilterSection({
  filter,
  searchState,
  ...props
}) {
  const isPopulated = (searchState.refinementList?.[(filter as any).attribute]?.length ?? 0) > 0
  // We need to convert the search state to a masters index compatible search state
  // so we convert it to the common filterset format, then convert it back to a
  // search state for the masters index
  const productSkufilters = useMemo(() => filtersFromSearchState(searchState), [searchState])

  return (
    <BaseFilterSection
      {...props}
      isPopulated={isPopulated}
      filterKey={filter.filterKey.toString()}
      label={filter.label}
      clearComponent={
        <>
          {(filter.type === 'refinement-list' || filter.type === 'hierarchical-menu') && (
            <ClearRefinements attribute={filter.attribute} buttonProps={{ py: 0, mr: 1 }} />
          )}
          {filter.type === 'refinement-product-sku-list' && (
            <ClearRefinements attribute={PRODUCT_SKUS_VARIANT_FIELDS} buttonProps={{ py: 0, mr: 1 }} />
          )}
          {filter.type === 'searchbox' && (
            <ClearRefinements clearsQuery attribute={QUERY_ATTRIBUTE} buttonProps={{ py: 0, mr: 1 }} />
          )}
          {filter.type === 'toggle' && (
            <ClearRefinements attribute={filter.attributes} buttonProps={{ py: 0, mr: 1 }} />
          )}
          {filter.type === 'switch' && (
            <ClearRefinements attribute={[filter.attribute]} buttonProps={{ py: 0, mr: 1 }} />
          )}
        </>
      }
    >
      <>
        {filter.type === 'refinement-list' && <RefinementList attribute={filter.attribute} {...refinementListProps} />}
        {filter.type === 'hierarchical-menu' && <HierarchicalMenu attributes={filter.attributes} />}
        {filter.type === 'refinement-product-sku-list' && (
          <FilterProductSku
            label="Select products..."
            attribute={filter.attributes[0]}
            filters={productSkufilters}
            hideArrow
            hideClearRefinements
            spacingProps={{
              my: -2,
              mx: -4,
              px: 4,
              py: 2
            }}
          />
        )}
        {filter.type === 'toggle' && (
          <FilterToggleGroup
            hideLabel
            hideClear
            attributes={filter.attributes}
            attributeLabels={filter.attributeLabels}
          />
        )}
        {filter.type === 'switch' && (
          <FilterSwitchGroup
            hideLabel
            hideClear
            attribute={filter.attribute}
            attributeFalseLabel={filter.attributeFalseLabel}
            attributeTrueLabel={filter.attributeTrueLabel}
          />
        )}
        {filter.type === 'searchbox' && <SearchBox margin="dense" />}
      </>
    </BaseFilterSection>
  )
})
