import { slugify } from '@client/helpers/strings'
import { ButtonProps } from '@mui/material'
import { SpacingProps } from '@mui/system'
import { memo, MouseEventHandler, useCallback, useMemo } from 'react'
import type { CurrentRefinementsProvided, Refinement } from 'react-instantsearch-core'
import * as S from './ClearRefinements.style'

export interface ClearRefinementsProps extends CurrentRefinementsProvided {
  attribute?: string | string[]
  label?: string
  clearsQuery?: boolean
  buttonProps?: ButtonProps & SpacingProps
}

export const QUERY_ATTRIBUTE = 'KEYWORD_SEARCH'
const QUERY_ID = 'query'

export const ClearRefinements: React.FC<ClearRefinementsProps> = memo<ClearRefinementsProps>(function ClearRefinements({
  attribute,
  label,
  clearsQuery,
  refine,
  items,
  buttonProps
}) {
  const buttonClasses = S.useButtonStyles()
  const filteredItems = useMemo(() => {
    if (Array.isArray(attribute)) {
      return items.filter((item) => (clearsQuery && item.id === QUERY_ID) || attribute.includes(item.attribute))
    }
    if (attribute === QUERY_ATTRIBUTE && clearsQuery) {
      return items.filter((item) => item.id === QUERY_ID)
    }
    return attribute ? items.filter((item) => attribute === item.attribute) : items
  }, [attribute, clearsQuery, items])

  const onClear: MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      refine(filteredItems)
    },
    [refine, filteredItems]
  )

  const labelText = useMemo(() => {
    if (label) {
      return label
    }
    const itemCount = filteredItems.reduce((count: number, item: Refinement) => {
      if (item.items?.length) {
        count += item.items.length
      } else {
        count += 1
      }
      return count
    }, 0 as number)
    return itemCount > 1 ? 'Clear all' : 'Clear'
  }, [filteredItems, label])

  return filteredItems.length ? (
    <S.ClearRefinementsButton
      {...buttonProps}
      classes={buttonClasses}
      color="primary"
      size="small"
      onClick={onClear}
      data-testid={`clear-refinements${label ? `-${slugify(label)}` : ''}`}
    >
      {labelText}
    </S.ClearRefinementsButton>
  ) : null
})
