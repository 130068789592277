import { memo } from 'react'
import { connectInfiniteHits, StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-dom'
import { InfiniteHitsList, InfiniteHitsProps } from './InfiniteHits'

export type { InfiniteHitsPropsExposed } from './InfiniteHits'

const ListInner: React.FC<InfiniteHitsProps<unknown> & StateResultsProvided> = ({
  isSearchStalled,
  // Prevent state results props from being passed forward
  /* eslint-disable @typescript-eslint/no-unused-vars */
  searching,
  searchState,
  searchResults,
  allSearchResults,
  error,
  searchingForFacetValues,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...props
}) => {
  return <InfiniteHitsList {...props} searching={isSearchStalled} />
}

const InfiniteHitsWithLoader = connectStateResults(memo(ListInner))

export const InfiniteHits = connectInfiniteHits(InfiniteHitsWithLoader)
