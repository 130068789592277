import { UrlParams } from '@client/helpers/qs-parser'
import { useGraphqlQuery } from '@client/hooks/use-admin-graphql'
import { useIsSuperAdmin, usePermission } from '@client/hooks/use-permissions'
import { useWholesaleStore } from '@client/stores/use-wholesale-store'
import { FORCE_NO_RESULTS_FILTER, intersectFiltersList } from '@client/types/filterset'
import {
  WholesaleDispensariesByOrdersFiltersResponse,
  WholesaleHoodieOrderStatus,
  WholesaleOrdersFilters,
  wholesaleOrderStatusMapping
} from '@client/types/wholesale'
import { Filters, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { graphql } from '@lib/clients/graphql'
import { addOrWhereClause, addWhereClause } from '@lib/helpers/graphql'
import { SortOrder } from '@lib/types/orderBy'
import { Permission } from '@lib/types/permission'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { ObjectParam, useQueryParam } from 'use-query-params'
import { useUpdateEffect } from 'usehooks-ts'
import { useWholesaleSubscription } from './use-wholesale-data'

const SHIPPED_KEY = 'Shipped'

export type WholesaleOptions<TSortField> = {
  pageSize: number
  from: number
  orderByField: TSortField
  orderByDirection: SortOrder
  searchTerm: string
  subscriptionId?: string
  dispensaryId?: string
  cmId?: string
}

const dispensariesByOrdersFiltersQuery = graphql(`
  query GetDispensariesByOrdersFilters($where: wholesale_customer_bool_exp!) {
    customers: wholesale_customer(distinct_on: dispensary_id, where: $where) {
      dispensaryId: dispensary_id
    }
  }
`)

export const useWholesaleDispensaryOrdersFilters = ({ orderStatus, startDate, endDate }: WholesaleOrdersFilters) => {
  const { hasPermission: hasWholesaleModuleAccess } = usePermission(Permission.MODULE_ACCESS_WHOLESALE)
  const { subscriptionId } = useWholesaleSubscription()
  const simulatedData = useWholesaleStore((state) => state.simulatedData)
  const { isSuperAdmin } = useIsSuperAdmin()

  const orderStatuses = useMemo(
    () =>
      orderStatus
        ?.map((status) => wholesaleOrderStatusMapping.get(status as WholesaleHoodieOrderStatus) ?? [])
        .flat() ?? [],
    [orderStatus]
  )

  const hasFilters = useMemo(() => {
    return !!orderStatus?.length || !!startDate || !!endDate
  }, [orderStatus, startDate, endDate])

  const where: Record<string, unknown> = {}
  // For regular users, only fetch dispensaries and order items for their subscription
  if (!isSuperAdmin) {
    addWhereClause(where, 'orders.subscription_id', subscriptionId || '')
  }
  if (orderStatuses.length) {
    if (orderStatus?.includes(SHIPPED_KEY)) {
      addOrWhereClause(where, 'orders', [
        addWhereClause({}, 'order_status', orderStatuses),
        addWhereClause({}, 'ship_date', false, '_is_null')
      ])
    } else {
      addWhereClause(where, 'orders.order_status', orderStatuses)
    }
  }
  if (startDate) {
    addWhereClause(where, 'orders.created_at', startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSS'), '_gte')
  }
  if (endDate) {
    addWhereClause(where, 'orders.created_at', endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSSSS'), '_lte')
  }

  const { data, ...response } = useGraphqlQuery<WholesaleDispensariesByOrdersFiltersResponse>(
    {
      query: dispensariesByOrdersFiltersQuery,
      variables: {
        where
      }
    },
    {
      enabled: !simulatedData && hasWholesaleModuleAccess && (!!subscriptionId || isSuperAdmin) && hasFilters,
      keepPreviousData: true
    }
  )

  const dispensaryIds = useMemo(() => {
    // If there are no filters, return undefined
    if (!hasFilters) {
      return undefined
    }
    // Simulated data
    if (simulatedData) {
      const ids = simulatedData.orders
        ?.filter((order) => {
          if (!order.customer.dispensaryId) {
            return false
          }
          if (orderStatuses.length && (!order.orderStatus || !orderStatuses.includes(order.orderStatus))) {
            return false
          }
          if (startDate && dayjs(order.createdAt).isBefore(startDate)) {
            return false
          }
          if (endDate && dayjs(order.createdAt).isAfter(endDate)) {
            return false
          }
          return true
        })
        .map(({ customer }) => customer.dispensaryId as string)
      return ids?.length ? ids : [FORCE_NO_RESULTS_FILTER]
    }
    // If no data, return no results if the user is not a super admin and there is no subscription id, otherwise return undefined
    if (!data) {
      return !isSuperAdmin && !subscriptionId ? [FORCE_NO_RESULTS_FILTER] : undefined
    }
    // If there is data, return the dispensary ids or [FORCE_NO_RESULTS_FILTER]
    const response = data.customers.map(({ dispensaryId }) => dispensaryId).filter(Boolean)
    return response.length ? response : [FORCE_NO_RESULTS_FILTER]
  }, [data, endDate, hasFilters, isSuperAdmin, orderStatuses, simulatedData, startDate, subscriptionId])

  return {
    ...response,
    data: dispensaryIds,
    hasFilters
  }
}

const statusSeparator = '~'
export const initWholesaleFilters = (optionsUrlParam: UrlParams | null | undefined): WholesaleOrdersFilters => ({
  startDate: optionsUrlParam?.from ? dayjs(optionsUrlParam.from) : undefined,
  endDate: optionsUrlParam?.to ? dayjs(optionsUrlParam.to) : undefined,
  orderStatus: optionsUrlParam?.orderStatus?.split(statusSeparator)
})

export const useWholesaleDispensaryOrdersFiltersUrlSync = (filters?: Filters) => {
  const [wholesaleUrlParam, setWholesaleUrlParam] = useQueryParam('wholesale', ObjectParam, { enableBatching: true })
  const [wholesaleFilters, setWholesaleFilters] = useState<WholesaleOrdersFilters>(
    initWholesaleFilters(wholesaleUrlParam)
  )

  const { isFetching, data, hasFilters } = useWholesaleDispensaryOrdersFilters(wholesaleFilters)

  useUpdateEffect(() => {
    const newUrlParams: UrlParams = {}
    if (wholesaleFilters.startDate) {
      newUrlParams.from = wholesaleFilters.startDate.format('YYYY-MM-DD')
    }
    if (wholesaleFilters.endDate) {
      newUrlParams.to = wholesaleFilters.endDate.format('YYYY-MM-DD')
    }
    if (wholesaleFilters.orderStatus?.length) {
      newUrlParams.orderStatus = wholesaleFilters.orderStatus.join(statusSeparator)
    }
    setWholesaleUrlParam(newUrlParams, 'replaceIn')
  }, [wholesaleFilters])

  const overwrittenFilters = useMemo(() => {
    const originalFilters = filters ?? emptyDraftFilterset().filters
    if (!data) {
      return originalFilters
    }
    return intersectFiltersList(originalFilters, data, 'dispensaryIds')
  }, [filters, data])

  return {
    hasFilters,
    isFetching,
    dispensariesIds: data,
    overwrittenFilters,
    wholesaleFilters,
    setWholesaleFilters
  }
}
