import { useAuth0 } from '@auth0/auth0-react'
import { TAG_ITEMS_QUERY_KEY } from '@client/hooks/use-item-tags-by-type'
import { TAG_ITEMS_KEY } from '@client/hooks/use-tag-items'
import {
  DraftTag,
  ItemTag,
  ItemTagKey,
  ItemTagWithTag,
  RequiredItemTag,
  TagType,
  TagWithCount,
  TagWithItems,
  getItemTagType,
  tagTypeKeys
} from '@client/types/tags'
import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query'
import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react'
import { SetRequired } from 'type-fest'
import { useHasura } from './hasura'

export const TAGS_QUERY_KEY = 'tags'

type GetByLabelOptions = {
  personalTagsOnly?: boolean
}

const sortTags = (tagA: Tag, tagB: Tag) => tagA.label.localeCompare(tagB.label)

const decreaseItemsCount = (tag: SetRequired<TagWithCount, 'itemsCount'>, type: TagType) => {
  return {
    ...tag,
    itemsCount: {
      ...tag.itemsCount,
      [type]: Math.max(0, tag.itemsCount[type] - 1)
    }
  }
}

export interface TagsContextInterface {
  globalTags: TagWithCount[]
  personalTags: TagWithCount[]
  sharedTags: TagWithCount[]
  isFetching: boolean
  getTagByLabel: (tagLabel: string, options?: GetByLabelOptions) => TagWithCount | undefined
  getTagById: (tagId: string) => TagWithCount | undefined
  upsertTag: (
    tag: SetRequired<DraftTag, 'items'>,
    itemTagsToRemove?: RequiredItemTag[]
  ) => Promise<TagWithItems | undefined>
  deleteTag: (tag: Tag) => Promise<boolean>
  removeTagFromItem: (itemTag: SetRequired<ItemTag, 'itemTagId'>, tagId: string) => Promise<boolean | undefined>
}

const TagsContext = createContext<TagsContextInterface>({
  globalTags: [],
  personalTags: [],
  sharedTags: [],
  isFetching: false,
  getTagByLabel: () => {
    throw new Error('getTagByLabel() not implemented')
  },
  getTagById: () => {
    throw new Error('getTagById() not implemented')
  },
  upsertTag: () => {
    throw new Error('addTagToItem() not implemented')
  },
  deleteTag: () => {
    throw new Error('addTagToItem() not implemented')
  },
  removeTagFromItem: () => {
    throw new Error('removeTagFromItem() not implemented')
  }
})

export const shouldUpdateTagItemsQuery = (queryKey: QueryKey, itemTags: ItemTag[]): boolean => {
  // If it's not a tag item query, just return false
  if (queryKey[0] !== TAG_ITEMS_QUERY_KEY) {
    return false
  }
  // Group items by tag type
  const groupedItems = itemTags.reduce((acc, item) => {
    const key = getItemTagType(item)
    acc[key] = [...(acc[key] ?? []), item]
    return acc
  }, {} as Record<TagType, ItemTag[]>)

  return Object.entries(groupedItems).some(([type, itemTags]) => {
    // If the query key type is different from the item tag type, return false
    if (queryKey[1] !== type) {
      return false
    }
    // If there are no ids in the query key, return true
    if (!queryKey[2]) {
      return true
    }
    // If there are ids in the query key, check if any of them are in the item tags
    return tagTypeKeys[type as TagType].some((key: ItemTagKey) =>
      itemTags.some((it) => it[key] && (queryKey[2] as string[]).includes(it[key] as string))
    )
  })
}

export const TagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { hasuraClient } = useHasura()
  const { user } = useAuth0()
  const queryClient = useQueryClient()

  const { data: tags, isFetching } = useQuery(
    [TAGS_QUERY_KEY],
    async () => {
      return await hasuraClient?.getTags()
    },
    {
      enabled: !!hasuraClient
    }
  )

  const { globalTags, personalTags, sharedTags } = useMemo(() => {
    const globalTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    const personalTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    const sharedTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    if (tags) {
      for (const tag of tags) {
        if (!tag.userId) {
          globalTags.push(tag)
        } else if (tag.userId === user?.sub) {
          personalTags.push(tag)
        } else {
          sharedTags.push(tag)
        }
      }
    }
    return {
      globalTags: globalTags.sort(sortTags),
      personalTags: personalTags.sort(sortTags),
      sharedTags: sharedTags.sort(sortTags)
    }
  }, [tags, user])

  const getTagByLabel = useCallback(
    (tagLabel: string, options?: GetByLabelOptions) => {
      return (options?.personalTagsOnly ? personalTags : tags)?.find(({ label }) => label === tagLabel)
    },
    [tags, personalTags]
  )

  const getTagById = useCallback(
    (tagId: string) => [...personalTags, ...sharedTags].find(({ id }) => id === tagId),
    [personalTags, sharedTags]
  )

  const upsertTag = useCallback(
    async (tag: SetRequired<DraftTag, 'items'>, itemTagsToRemove?: RequiredItemTag[]) => {
      if (hasuraClient) {
        const { items, ...updatedTag } = tag.id
          ? await hasuraClient.updateTag(tag as TagWithItems, itemTagsToRemove)
          : await hasuraClient.insertTag(tag)

        // Update the query cache with the new tag
        if (updatedTag) {
          if (!tag.id) {
            // Adding new tag to cache
            queryClient.setQueryData<Tag[]>([TAGS_QUERY_KEY], (prevState) => [...(prevState ?? []), updatedTag])
          } else {
            const removedIds = itemTagsToRemove?.map((it) => it.itemTagId) ?? []
            await Promise.all([
              // Update tag in cache
              queryClient.setQueryData<Tag[]>(
                [TAGS_QUERY_KEY],
                (prevState) => prevState?.map((t) => (t.id === updatedTag.id ? updatedTag : t)) ?? []
              ),
              // Update tag data in all ItemTagWithTag queries
              queryClient.setQueriesData(
                {
                  predicate: ({ queryKey }) => queryKey[0] === TAG_ITEMS_QUERY_KEY && queryKey.length > 0
                },
                (data?: ItemTagWithTag[]) => {
                  return data?.map((it) => {
                    return it.tag.id === updatedTag.id ? { ...it, tag: updatedTag } : it
                  })
                }
              ),
              // Update useTagItems cache
              queryClient.setQueriesData<RequiredItemTag[]>(
                { queryKey: [TAG_ITEMS_KEY, tag.id], exact: true },
                (data) => [
                  // Filter out the item tags that are being removed
                  ...(data?.filter((it) => !removedIds.includes(it.itemTagId)) || []),
                  // Add the new item tags
                  ...(items as RequiredItemTag[]).filter((it) => !data?.some((item) => item.itemTagId === it.itemTagId))
                ]
              ),
              // Should invalidate item tags check on the import validation
              queryClient.invalidateQueries({
                predicate: ({ queryKey }) =>
                  queryKey[0] === TAG_ITEMS_KEY && queryKey[1] === updatedTag.id && queryKey.length > 2,
                refetchType: 'none'
              }),
              // Remove the item from the item tag queries
              itemTagsToRemove?.length
                ? queryClient.setQueriesData(
                    {
                      predicate: ({ queryKey }) => shouldUpdateTagItemsQuery(queryKey, itemTagsToRemove)
                    },
                    (data?: ItemTagWithTag[]) =>
                      data?.filter((it) => !itemTagsToRemove.some((item) => it.itemTagId === item.itemTagId))
                  )
                : undefined
            ])
          }

          // Update the ItemTag to the relevant queries response
          await Promise.all(
            items.map((itemTag) => {
              return queryClient.setQueriesData(
                {
                  predicate: ({ queryKey }) => shouldUpdateTagItemsQuery(queryKey, [itemTag])
                },
                (data?: ItemTagWithTag[]) => {
                  const result = [...(data || [])]
                  const newItem: ItemTagWithTag = { ...itemTag, tag: updatedTag }
                  const index = result?.findIndex((it) => it.itemTagId === newItem.itemTagId) ?? -1

                  if (result?.length && index >= 0) {
                    result[index] = newItem
                    return result
                  }

                  return [...result, newItem]
                }
              )
            })
          )
        }
        return { ...updatedTag, items }
      }
    },
    [queryClient, hasuraClient]
  )

  const removeTagFromItem = useCallback(
    async (itemTag: SetRequired<ItemTag, 'itemTagId'>, tagId: string) => {
      const removed = await hasuraClient?.removeItemTag(itemTag.itemTagId)
      if (removed) {
        const tag = getTagById(tagId)
        const type = getItemTagType(itemTag)
        await Promise.all([
          // Decrement one item from the item count of the tag
          queryClient.setQueryData<SetRequired<TagWithCount, 'itemsCount'>[]>(
            [TAGS_QUERY_KEY],
            (prevState) => prevState?.map((t) => (t.id === tag?.id ? decreaseItemsCount(t, type) : t)) ?? []
          ),
          // Remove the item from the item tag queries
          queryClient.setQueriesData(
            {
              predicate: ({ queryKey }) => shouldUpdateTagItemsQuery(queryKey, [itemTag])
            },
            (data?: ItemTagWithTag[]) => data?.filter((it) => it.itemTagId !== itemTag.itemTagId)
          ),
          ...(tag?.id
            ? [
                // Update useTagItems cache
                queryClient.setQueriesData<ItemTag[]>({ queryKey: [TAG_ITEMS_KEY, tag.id], exact: true }, (items) => {
                  return items?.filter((item) => item.itemTagId !== itemTag.itemTagId)
                }),
                // Should invalidate item tags check on the import validation
                queryClient.invalidateQueries({
                  predicate: ({ queryKey }) =>
                    queryKey[0] === TAG_ITEMS_KEY && queryKey[1] === tag.id && queryKey.length > 2,
                  refetchType: 'none'
                })
              ]
            : [])
        ])
      }
      return !!removed
    },
    [hasuraClient, queryClient, getTagById]
  )

  const deleteTag = useCallback(
    async (tag: Tag) => {
      const items = await hasuraClient?.getTagItems(tag.id)
      try {
        const deleted = await hasuraClient?.deleteTag(tag.id)
        if (deleted) {
          await Promise.all([
            // Remove the tag from the query cache
            queryClient.setQueryData<Tag[]>([TAGS_QUERY_KEY], (prevState) => prevState?.filter((t) => t.id !== tag.id)),
            // Update useTagItems cache
            queryClient.setQueriesData({ queryKey: [TAG_ITEMS_KEY, tag.id], exact: true }, []),
            // Should invalidate item tags check on the import validation
            queryClient.invalidateQueries({
              predicate: ({ queryKey }) =>
                queryKey[0] === TAG_ITEMS_KEY && queryKey[1] === tag.id && queryKey.length > 2,
              refetchType: 'none'
            }),
            // Remove the related item tags from all query caches
            items?.length
              ? queryClient.setQueriesData(
                  {
                    predicate: ({ queryKey }) => shouldUpdateTagItemsQuery(queryKey, items)
                  },
                  (data?: ItemTagWithTag[]) =>
                    data?.filter((it) => !items.some((item) => it.itemTagId === item.itemTagId))
                )
              : undefined
          ])
        }
        return !!deleted
      } catch (e) {
        return false
      }
    },
    [queryClient, hasuraClient]
  )

  return (
    <TagsContext.Provider
      value={{
        isFetching,
        globalTags,
        personalTags,
        sharedTags,
        deleteTag,
        getTagById,
        getTagByLabel,
        upsertTag,
        removeTagFromItem
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}

export const useTags = () => {
  const context = useContext(TagsContext)

  if (!context) {
    throw new Error('useTags must be used within a TagsProvider.')
  }

  return context
}
