import { usersLocalStorage } from '@client/helpers/usersLocalStorage'
import { ViewOption } from '@client/types/dispensary-stats'
import { ProductsView } from '@client/types/products'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type AppState = {
  EXPANDED_PRODUCT_GRID?: boolean
  TARGET_TERRITORY_ID?: string
  COMPETITION_TERRITORY_ID?: string
  WIDER_CONTENT?: boolean
  HOME_DISPENSARIES_TAB_VIEW?: ViewOption
  HOME_PRODUCTS_TAB_VIEW?: ProductsView
}

export type AppStateKey = keyof AppState

export interface AppStateStore {
  allAppData: AppState
  getAppData: <T extends AppStateKey>(key: T) => AppState[T]
  setAppData: <T extends AppStateKey>(key: T, value: AppState[T]) => void
  clearAppData: <T extends AppStateKey>(key: T) => void
  clearAllAppData: () => void
}

export const useAppStateStore = create(
  devtools(
    persist<AppStateStore>(
      (set, get) => ({
        allAppData: {},
        getAppData: (key) => {
          return get().allAppData[key]
        },
        setAppData: (key, value) =>
          set((state) => {
            return { ...state, allAppData: { ...state.allAppData, [key]: value } }
          }),
        clearAppData: (key) =>
          set((state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [key]: _, ...allAppData } = state.allAppData
            return { allAppData }
          }),
        clearAllAppData: () =>
          set(() => {
            return { allAppData: {} }
          })
      }),
      {
        name: '@hoodie:app-state',
        getStorage: () => usersLocalStorage
      }
    ),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: `@hoodie:app-state-store`
    }
  )
)

export const useSetAppStateData = () => useAppStateStore((state) => state.setAppData)
export const useGetAppStateData = () => useAppStateStore((state) => state.getAppData)
