import { TagType, applyTagsOnFilters, tagTypeKeyToFilterKey, tagTypeKeys } from '@client/types/tags'
import { Filters, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import { useCallback, useMemo, useState } from 'react'
import { ArrayParam, useQueryParam } from 'use-query-params'
import { useUpdateEffect } from 'usehooks-ts'
import { useItemTagsByType } from './use-item-tags-by-type'

const emptyFilters = emptyDraftFilterset().filters

/**
 * Fetches item tags by type and filters them by tag
 * @param type The type of item tag to fetch
 * @param [filters] You can optionally pass filters to the hook to set the filtered tags to it and remove tags that are not in the filters
 */
export const useTagFilterByType = (type: TagType, filters?: Filters) => {
  const filtersIds = useMemo(() => {
    if (!filters) {
      return undefined
    }
    const ids = tagTypeKeys[type]
      .map((itemTagKey) => {
        const filterKey = tagTypeKeyToFilterKey[itemTagKey]
        if (filterKey) {
          const filterList = filters.filterBy[filterKey]
          if (filterList?.length) {
            return filterList
          }
        }
        return []
      })
      .flat()
    return ids.length ? ids : undefined
  }, [filters, type])

  const { isLoading, tags, itemTags } = useItemTagsByType(type, filtersIds)

  const [queryStringFilters, updateQueryStringFilters] = useQueryParam('tag', ArrayParam, { enableBatching: true })
  const [activeFilters, setActiveFilters] = useState<string[]>((queryStringFilters?.filter((f) => f) as string[]) || [])

  const filteredItems = useMemo(() => {
    return itemTags?.filter(({ tag }) => activeFilters.includes(tag.label)) ?? []
  }, [activeFilters, itemTags])

  const filteredTags = useMemo(() => {
    return tags.filter(({ label }) => activeFilters.includes(label))
  }, [activeFilters, tags])

  const setFilteredTags = useCallback(
    (newTags: Tag[]) => {
      updateQueryStringFilters(
        newTags.map(({ label }) => label),
        'replaceIn'
      )
    },
    [updateQueryStringFilters]
  )

  const filteredFilters = useMemo(
    () => applyTagsOnFilters(filters ?? emptyFilters, filteredItems, type),
    [filters, filteredItems, type]
  )

  useUpdateEffect(() => {
    setActiveFilters((queryStringFilters as string[]) ?? [])
  }, [queryStringFilters])

  return {
    isLoading,
    tags,
    filteredFilters,
    filteredItems,
    filteredTags,
    setFilteredTags
  }
}
