import type { HitItem } from '@client/types/hitItem'
import type { Hit } from 'react-instantsearch-core'

export const generateItems = (requiredNumItems: number): Hit<HitItem>[] => {
  return [...Array(requiredNumItems).keys()]
    .map(function (_, i) {
      return i
    })
    .map((index: number) => ({
      _highlightResult: {
        label: {
          matchLevel: 'none',
          matchedWords: [],
          value: `Item ${index}`
        },
        value: [
          {
            matchLevel: 'none',
            matchedWords: [],
            value: `Item ${index}`
          }
        ]
      },
      objectID: 'id',
      count: index,
      isRefined: false,
      label: `Item ${index}`,
      value: [`Item ${index}`]
    }))
}

export const sortFacets = (a: HitItem, b: HitItem) => {
  if (a.count > b.count) {
    return -1
  } else if (b.count > a.count) {
    return 1
  }
  return a.label.localeCompare(b.label)
}

export const generateAlgoliaResponse = ([request]: any, hits?: unknown[]) => {
  if (!request.params.facets?.length) {
    return Promise.resolve({
      results: [
        {
          hits: hits || generateItems(10),
          nbPages: 2,
          page: 1
        }
      ]
    })
  }
  return Promise.resolve({
    results: [
      {
        hits: [],
        facets: Object.fromEntries(
          request.params.facets?.map((facet: string) => [
            facet,
            Object.fromEntries([...Array(10).keys()].map((_, i) => [`${facet} ${i + 1}`, 10 - i]))
          ]) ?? []
        )
      }
    ]
  })
}

export function facetToValue(str: string) {
  return str.startsWith('-') ? str.substring(1) : str
}

export function valueToFacet(value: string, valueExcluded: boolean) {
  return value.indexOf('-') === 0 ? (valueExcluded ? value : value.substring(1)) : valueExcluded ? '-' + value : value
}
