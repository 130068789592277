import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

export const chartInstructionKey = '@hoodie:chartInstruction'

interface ChartInstructionsContextInterface {
  dontShowAgain: boolean
  setDontShowAgain: (dontShowAgain: boolean) => void
}

const ChartInstructionsContext = createContext<ChartInstructionsContextInterface>(
  {} as ChartInstructionsContextInterface
)

export const ChartInstructionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dontShowAgain, setDontShowAgain] = useState(!!localStorage.getItem(chartInstructionKey))

  useEffect(() => {
    if (dontShowAgain) {
      localStorage.setItem(chartInstructionKey, 'true')
    } else {
      localStorage.removeItem(chartInstructionKey)
    }
  }, [dontShowAgain])

  return (
    <ChartInstructionsContext.Provider value={{ dontShowAgain, setDontShowAgain }}>
      {children}
    </ChartInstructionsContext.Provider>
  )
}

export const useChartInstructions = () => {
  const context = useContext(ChartInstructionsContext)

  if (!context) {
    throw new Error('useChartInstructions must be used within a ChartInstructionsProvider.')
  }

  return context
}
