import { Onboarding, OnboardingStep } from '@client/components/onboarding'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { NewReleases } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { Props } from 'react-joyride'
import { useBoolean, useTimeout } from 'usehooks-ts'

const floaterProps: Props['floaterProps'] = {
  styles: {
    floater: {
      width: '350px'
    }
  }
}

export const ShowMoreOnboardingTour = memo(function ShowMoreOnboardingTour() {
  const { isMobile } = useResponsiveState()
  const { value: run, setTrue: startTour } = useBoolean(false)

  useTimeout(() => {
    startTour()
  }, 1000)

  const showMoreAttributes = useCallback(() => {
    const element = document.querySelector('[data-testid="show-more-attributes"]') as HTMLElement
    element.click()
  }, [])

  const steps: OnboardingStep[] = useMemo(
    () => [
      {
        target: '[data-testid="show-more-attributes"]',
        title: (
          <Stack gap={2} direction="row" alignItems="center">
            <NewReleases color="success" />
            <Typography variant="h5" fontWeight={600}>
              New: Additional filter options
            </Typography>
          </Stack>
        ),
        ctaProps: {
          label: 'Show me',
          onClick: showMoreAttributes
        },
        content: (
          <>
            <Typography variant="body1" gutterBottom>
              There are now more attributes to filter on, including <strong>Infused</strong> and{' '}
              <strong>Concentrate Type</strong>.
            </Typography>
            <Typography variant="body1" gutterBottom>
              {isMobile ? 'Tap' : 'Click'} &apos;Show me&apos; to see these new attributes...
            </Typography>
          </>
        ),
        placement: 'top',
        disableBeacon: true
      }
    ],
    [isMobile, showMoreAttributes]
  )

  return (
    <Onboarding
      run={run}
      expiry={new Date(2024, 6, 24)}
      tourId="show-more-attributes"
      steps={steps}
      floaterProps={floaterProps}
    />
  )
})
