import { ClearRefinements } from '@client/components/algolia/clear-refinements'
import { CurrentRefinement } from '@client/components/algolia/current-refinement'
import { VirtualWidgets } from '@client/components/algolia/virtual-widgets/VirtualWidgets'
import { ConfirmationDialog } from '@client/components/confirmation-dialog/ConfirmationDialog'
import { DesktopTooltip } from '@client/components/desktop-tooltip/DesktopTooltip'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { HelpLink } from '@client/components/help-link/HelpLink'
import {
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { SearchInput } from '@client/components/search-input/SearchInput'
import { ToggleCollapseAllButton } from '@client/components/toggle-collapse-all-button/ToggleCollapseAllButton'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useCustomTerritories } from '@client/hooks/use-custom-territories'
import { useElementSize } from '@client/hooks/use-element-size'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useFiltersDrawerAttributes } from '@client/hooks/use-filters-drawer-attributes'
import { useInstantSearchVariables } from '@client/hooks/use-instant-search-variables'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useSearchState } from '@client/hooks/use-search-state'
import { useAlgolia } from '@client/providers/algolia'
import { TempFilters } from '@client/providers/saved-views'
import { ALGOLIA_INDEX, VARIANT_INDEX } from '@client/services/algolia'
import { DrawerId, DrawerState, useDrawerState } from '@client/stores/use-drawer-state'
import { IconButton } from '@client/styles/theme/icon-button'
import { ALL_PRODUCT_FILTERS, normalizeFilterset, searchStateFromFilters } from '@client/types/filterset'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { DraftFilterset, View, defaultFilters } from '@hoodie/hoodie-filters/lib/filterset'
import { FilterList } from '@mui/icons-material'
import { Box, Button, Collapse, Paper, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useUpdateEffect } from 'usehooks-ts'
import { ResizableDrawer } from '../../component/ResizableDrawer'
import * as S from './FiltersDrawer.style'
import { ShowMoreOnboardingTour } from './components/ShowMoreOnboardingTour'
import { FilterActions } from './components/filter-actions/FilterActions'
import { FilterSection } from './components/filter-section/FilterSection'
import { IconButtonViewSelector } from './components/icon-button-view-selector'

const SHOW_MORE_INCREMENT = 5
export const MAX_ATTRIBUTES = 13

interface FiltersDrawerContentProps {
  isDirty: boolean
  searchState: SearchState
  disableViews?: boolean
  setSearchState: (searchState: SearchState) => void
  onClose: () => void
}

type FiltersDrawerState = {
  currentFilterset: DraftFilterset
  tempFilters?: TempFilters
  prevDrawer?: DrawerState
  disableViews?: boolean
  index?: ALGOLIA_INDEX
  maxAttributes?: number
  showMoreIncrement?: number
  onApply?: (filterset: DraftFilterset) => void
}

const allFilters = ALL_PRODUCT_FILTERS

const FiltersDrawerContent: React.FC<FiltersDrawerContentProps> = ({
  isDirty,
  onClose,
  setSearchState,
  searchState
}) => {
  const [contentRef, { width: contentWidth }] = useElementSize()
  const scrollTimeout = useRef<NodeJS.Timeout>()
  const { drawer } = useDrawerState()
  const { isTerritory } = useCustomTerritories()
  const { isMobile } = useResponsiveState()
  const { eventTracker } = useEventTracker()
  const drawerState = drawer?.state as FiltersDrawerState
  const { showMoreIncrement = SHOW_MORE_INCREMENT } = drawerState

  const [maxFiltersToShow, setMaxFiltersToShow] = useState(drawerState.maxAttributes ?? MAX_ATTRIBUTES)

  // Clear the scroll timeout when the component unmounts
  useEffect(() => {
    return () => clearTimeout(scrollTimeout.current)
  }, [])

  const [searchTerm, setSearchTerm] = useState('')
  const {
    value: showOnlyActive,
    toggleValue: toggleShowOnlyActive,
    setFalse: disableShowOnlyActive
  } = useBooleanState(false)

  const isCustomTerritory = useMemo(
    () => !!drawerState.currentFilterset.id && isTerritory(drawerState.currentFilterset as View),
    [drawerState.currentFilterset, isTerritory]
  )

  const { algoliaSearchClients } = useAlgolia()

  const visibleFilters = useMemo(
    () => drawerState?.tempFilters?.visibleFilters ?? allFilters,
    [drawerState?.tempFilters?.visibleFilters]
  )

  const disableExpandCollapse = useMemo(() => contentWidth >= S.minSectionWidth * 2, [contentWidth])

  const index = useMemo(() => drawerState?.index ?? VARIANT_INDEX, [drawerState?.index])

  const { filterAttributes, numActiveFilters, preFiltersString, filters } = useInstantSearchVariables({
    index,
    searchState,
    visibleFilters,
    preFilterset: drawerState?.tempFilters?.preFilterset
  })

  const {
    attributeCollapsed,
    attributeDisabling,
    attributeVisibility,
    availableHiddenFiltersCount,
    filtersToRender,
    handleAttributeCollapse,
    handleToggleCollapseAll,
    isAllCollapsed,
    isToggleCollapseAllEnabled,
    noMatches
  } = useFiltersDrawerAttributes(allFilters, {
    visibleFilters: drawerState?.tempFilters?.visibleFilters,
    searchTerm,
    filters,
    showOnlyActive,
    maxFiltersToShow
  })

  const handleAppliedViewChange = useCallback(
    // Set the search state (currently selected filters) from the
    // saved view selected to be applied.
    (view?: View) => {
      if (view) {
        setSearchState(searchStateFromFilters(view.filters, index))
        eventTracker({
          category: TrackableCategory.filters,
          action: TrackableAction.viewAppliedToFilters,
          dimension1: view.name
        })
      }
    },
    [index, setSearchState, eventTracker]
  )

  const handleShowMore = useCallback(() => {
    const previousNumFilters = maxFiltersToShow
    setMaxFiltersToShow((prev) => Math.min(prev + showMoreIncrement, allFilters.length))
    scrollTimeout.current = setTimeout(() => {
      // Scroll the first new filter to the top of the scroll area
      const firstNewFilter = document.querySelector(`[data-id="filter-section-index--${previousNumFilters}"]`)
      if (firstNewFilter) {
        firstNewFilter.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 500)
  }, [maxFiltersToShow, showMoreIncrement])

  const disableExpandCollapseAllButton = !isToggleCollapseAllEnabled || disableExpandCollapse

  useUpdateEffect(() => {
    if (!numActiveFilters && showOnlyActive) {
      disableShowOnlyActive()
    }
  }, [numActiveFilters])

  return (
    <InstantSearch
      searchClient={algoliaSearchClients[index]}
      searchState={searchState}
      onSearchStateChange={setSearchState}
      indexName={index}
    >
      <Configure hitsPerPage={0} filters={preFiltersString} />
      <VirtualWidgets invisibleFilters={drawerState?.tempFilters?.invisibleFilters} index={index} />
      <DrawerHeader sx={{ zIndex: 2 }}>
        <DrawerTitle>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <S.TrailingBadge
              badgeContent={numActiveFilters}
              color="success"
              invisible={numActiveFilters === 0}
              data-testid="filter-count-badge"
            >
              Filters
            </S.TrailingBadge>
            <Box display="flex" alignItems="center">
              <HelpLink helpLink="sales-enablement/filters-views#the-filters-drawer" tooltip="View help on filters" />
              <Button variant="text" onClick={onClose} data-testid="cancel-button">
                Cancel
              </Button>
              <FilterActions
                isDirty={isDirty}
                numActiveFilters={numActiveFilters}
                searchState={searchState}
                disableUpdateView={isCustomTerritory}
                onClose={onClose}
              />
            </Box>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <Paper
        sx={{ display: 'flex', p: 2, pl: 3, zIndex: 1 }}
        elevation={1}
        square
        data-testid="filters-drawer--filters-bar"
      >
        <SearchInput
          searchTerm={searchTerm}
          onSearch={setSearchTerm}
          margin="none"
          autoFocus={!isMobile}
          placeholder="Find an attribute..."
          sx={{ mr: 2 }}
        />
        {!drawerState?.disableViews && <IconButtonViewSelector onChange={handleAppliedViewChange} />}
        <DesktopTooltip
          title={
            numActiveFilters ? (showOnlyActive ? 'Show all sections' : 'Show only sections with active filters') : ''
          }
        >
          <IconButton
            size="small"
            onClick={toggleShowOnlyActive}
            data-testid="toggle-active-filters"
            color={showOnlyActive ? 'toggle' : undefined}
            variant={showOnlyActive ? 'contained' : undefined}
            disabled={numActiveFilters === 0}
          >
            <FilterList fontSize="small" />
          </IconButton>
        </DesktopTooltip>
        <ToggleCollapseAllButton
          isCollapsed={isAllCollapsed}
          onToggle={handleToggleCollapseAll}
          disabled={disableExpandCollapseAllButton}
        />
      </Paper>
      <S.DrawerContent ref={contentRef} data-testid="filters-drawer--content">
        {filtersToRender.map((filter, index) => (
          <FilterSection
            index={index}
            searchState={searchState}
            filter={filter}
            key={filter.filterKey.toString()}
            disabled={attributeDisabling[filter.label]}
            hidden={!attributeVisibility[filter.label]}
            collapsed={attributeCollapsed[filter.label]}
            onToggleCollapse={handleAttributeCollapse}
            disableExpandCollapse={disableExpandCollapse}
          />
        ))}
        {!!availableHiddenFiltersCount && (
          <Button onClick={handleShowMore} fullWidth sx={{ py: 3, gap: 1 }} data-testid="show-more-attributes">
            Show more attributes... <small>({availableHiddenFiltersCount})</small>
          </Button>
        )}
        <ShowMoreOnboardingTour />
        {noMatches && (
          <Typography
            component="div"
            fontStyle="italic"
            data-testid="filters-drawer--no-matches"
            textAlign="center"
            display="flex"
            flexDirection="column"
            gap={2}
            p={4}
          >
            {showOnlyActive && searchTerm ? (
              <p>
                No attributes with <strong>active</strong> filters found for &quot;<strong>{searchTerm}</strong>
                &quot;.
              </p>
            ) : showOnlyActive ? (
              <p>
                No attributes with <strong>active</strong> filters found.
              </p>
            ) : (
              <p>
                No attributes found for &quot;<strong>{searchTerm}</strong>&quot;.
              </p>
            )}
            {!!searchTerm && <p>Try searching for attributes like Brand, State, Cities, Location...</p>}
          </Typography>
        )}
      </S.DrawerContent>
      <Collapse in={numActiveFilters > 0} timeout="auto" unmountOnExit>
        <DrawerFooter>
          <CurrentRefinement
            attribute={filterAttributes}
            clearsQuery
            maxToDisplay={5}
            boxProps={{ flex: 1, maxWidth: '100%' }}
          >
            <ClearRefinements attribute={filterAttributes} clearsQuery />
          </CurrentRefinement>
        </DrawerFooter>
      </Collapse>
    </InstantSearch>
  )
}

export const FiltersDrawer: React.FC<any> = () => {
  const drawerStyles = useDrawerStyles()
  const { value: closeRequested, setTrue: requestClose, setFalse: clearCloseRequest } = useBooleanState(false)
  const { drawer, clearDrawer } = useDrawerState()
  const drawerState = drawer?.state as FiltersDrawerState

  const { filters: initialFilters } = useMemo(() => {
    return normalizeFilterset(drawerState?.currentFilterset)
  }, [drawerState?.currentFilterset])

  const { isDirty, searchState, setSearchState } = useSearchState(initialFilters)

  const handleClose = useCallback(() => {
    if (isDirty) {
      requestClose()
    } else {
      setSearchState(searchStateFromFilters(initialFilters || defaultFilters, VARIANT_INDEX))
      clearDrawer()
    }
  }, [clearDrawer, initialFilters, isDirty, requestClose, setSearchState])

  const handleConfirmClose = useCallback(() => {
    clearCloseRequest()
    setSearchState(searchStateFromFilters(initialFilters || defaultFilters, VARIANT_INDEX))
    clearDrawer()
  }, [clearCloseRequest, clearDrawer, initialFilters, setSearchState])

  const updateSearchState = useCallback(
    (newSearchState: SearchState) => {
      setSearchState(newSearchState)
    },
    [setSearchState]
  )

  return (
    <ResizableDrawer
      data-testid="filters-drawer"
      open={drawer?.id === DrawerId.filters}
      anchor="right"
      classes={drawerStyles}
      onClose={handleClose}
    >
      {drawer?.id === DrawerId.filters && (
        <ErrorBoundary>
          <FiltersDrawerContent
            searchState={searchState}
            setSearchState={updateSearchState}
            onClose={clearDrawer}
            isDirty={isDirty}
          />
        </ErrorBoundary>
      )}
      {closeRequested && (
        <ConfirmationDialog
          title="You have unsaved changed"
          content="Are you sure you want to discard these changes?"
          open={closeRequested}
          onCancel={clearCloseRequest}
          onConfirm={handleConfirmClose}
          confirmButtonText="Discard Changes"
        />
      )}
    </ResizableDrawer>
  )
}
