import { ALGOLIA_INDEX, BRAND_INDEX, DISPENSARY_INDEX, VARIANT_INDEX } from '@client/services/algolia'
import { ElasticFields } from '@hoodie/hoodie-filters/lib/elastic'
import {
  DraftFilterset,
  FilterBy,
  FilterKeyType,
  Filters,
  LOCATION_FILTER_KEYS,
  Should,
  View,
  defaultFilters,
  emptyDraftFilterset,
  getDefaultFilters
} from '@hoodie/hoodie-filters/lib/filterset'
import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import clone from 'fast-clone'
import { singular } from 'pluralize'
import { SearchState } from 'react-instantsearch-core'

// Deprecated - We should keep this here for not breaking the opened PRs
// Please import from @hoodie/hoodie-filters directly, and use View instead of Filterset
export * from '@hoodie/hoodie-filters/lib/filterset'
export type Filterset = View
// End Deprecated

export type FiltersWith<TSpecialFilter extends Record<string, string | string[] | boolean>> = Filters & {
  filterBy: FilterBy & TSpecialFilter
}
// Alternative filters that can be used direct in use-algolia-list using FiltersWith
export type LicenseNumbersFilterBy = { licenseNumbers?: string[] }
export type ProductNamesFilterBy = { products?: string[] }

export type FilterKey = Exclude<keyof FilterBy, 'geoBoundingBox'>

// This should only be used for mapping the filter keys to the database fields
export type FilterKeyWithAlternativeKey = FilterKey | keyof LicenseNumbersFilterBy | keyof ProductNamesFilterBy

export type FilterKeys = Array<FilterKey>
type FilterFlagKey = FilterKeyType<boolean>

interface FilterOptionBase {
  label: string
  tagLabel?: string
  filterKey: FilterKey | 'keywordSearch' | FilterKeys | FilterFlagKey[]
  /**
   * The keywords are used for filtering the attributes
   */
  keywords: string[]
}
export interface RefinementListFilterOption extends FilterOptionBase {
  type: 'refinement-list'
  attribute: string
  filterKey: FilterKey
}
export interface RefinementProductSkuFilterOption extends FilterOptionBase {
  label: string
  tagLabel?: string
  type: 'refinement-product-sku-list'
  attributes: string[]
  filterKey: FilterKeys
}

export interface HierarchicalMenuFilterOption extends FilterOptionBase {
  type: 'hierarchical-menu'
  attribute: string
  attributes: string[]
  filterKey: FilterKey
}

export interface SearchBoxFilterOption extends FilterOptionBase {
  type: 'searchbox'
  filterKey: 'keywordSearch'
}
export interface ToggleFilterOption extends FilterOptionBase {
  type: 'toggle'
  attributes: string[]
  attributeLabels: string[]
  filterKey: FilterFlagKey[]
}

export interface SwitchFilterOption extends FilterOptionBase {
  type: 'switch'
  attribute: string
  attributeFalseLabel?: string
  attributeTrueLabel?: string
  filterKey: FilterFlagKey
}

export type FilterOption =
  | RefinementListFilterOption
  | HierarchicalMenuFilterOption
  | SearchBoxFilterOption
  | ToggleFilterOption
  | SwitchFilterOption
  | RefinementProductSkuFilterOption

export const ProductFilters: Record<string, FilterOption> = {
  brand: {
    type: 'refinement-list',
    attribute: 'BRAND',
    label: 'Brands',
    filterKey: 'brands',
    keywords: []
  },
  categories: {
    type: 'refinement-list',
    attribute: 'CATEGORY_0',
    label: 'Categories',
    filterKey: 'categories',
    keywords: []
  },
  segments: {
    type: 'refinement-list',
    attribute: 'CATEGORY_1',
    label: 'Segments',
    filterKey: 'segments',
    keywords: []
  },
  subsegments: {
    type: 'refinement-list',
    attribute: 'CATEGORY_2',
    label: 'Sub-segments',
    filterKey: 'subsegments',
    keywords: []
  },
  state: {
    type: 'refinement-list',
    attribute: 'D_STATE',
    label: 'States',
    filterKey: 'states',
    keywords: ['location']
  },
  city: {
    type: 'refinement-list',
    attribute: 'D_CITY',
    label: 'Cities',
    filterKey: 'cities',
    keywords: ['location']
  },
  dispensary: {
    type: 'refinement-list',
    attribute: 'MASTER_D_NAME',
    label: 'Dispensaries',
    filterKey: 'dispensaries',
    keywords: ['location']
  },
  banner: {
    type: 'refinement-list',
    attribute: 'D_BANNER',
    label: 'Banners',
    filterKey: 'banners',
    keywords: []
  },
  productPresentation: {
    type: 'refinement-list',
    attribute: 'VARIANTS.PRODUCT_PRESENTATION',
    label: 'Unit of Measure',
    tagLabel: 'UOM',
    filterKey: 'productPresentations',
    keywords: []
  },
  strain: {
    type: 'refinement-list',
    attribute: 'STRAIN',
    label: 'Strains',
    filterKey: 'strains',
    keywords: []
  }
}

export const ProductSkuFilterOption: RefinementProductSkuFilterOption = {
  type: 'refinement-product-sku-list',
  attributes: ['CM_ID', 'objectID'],
  label: 'Product/SKU',
  filterKey: ['cmIds', 'menuIds'],
  keywords: []
}

export const DispensaryIdFilterOption: FilterOption = {
  type: 'refinement-list',
  attribute: 'MASTER_D_ID',
  label: 'Dispensary IDs',
  filterKey: 'dispensaryIds',
  keywords: []
}

export const CountryCodeFilterOption: FilterOption = {
  type: 'refinement-list',
  attribute: 'D_COUNTRY',
  label: 'Countries',
  filterKey: 'countryCodes',
  keywords: ['location']
}

export const MasterProductFilterOptions: FilterOption[] = [
  {
    type: 'refinement-list',
    attribute: 'CM_ID',
    label: 'Mastered Products',
    filterKey: 'cmIds',
    keywords: []
  },
  {
    type: 'refinement-list',
    attribute: 'objectID',
    label: 'Object Ids',
    filterKey: 'menuIds',
    keywords: []
  }
]

export const MED_REC_FILTER: ToggleFilterOption = {
  type: 'toggle',
  attributes: ['VARIANTS.IS_MEDICAL', 'VARIANTS.IS_RECREATIONAL'],
  attributeLabels: ['Med', 'Rec'],
  label: 'Type',
  filterKey: ['medical', 'recreational'],
  keywords: []
}

export const InfusedPreRollFilterOption: SwitchFilterOption = {
  type: 'switch',
  attribute: 'INFUSED',
  label: 'Infused',
  attributeFalseLabel: 'Not Infused',
  attributeTrueLabel: 'Infused',
  filterKey: 'infusedPreRoll',
  keywords: []
}

export const ConcentratetypeFilterOption: FilterOption = {
  type: 'refinement-list',
  attribute: 'CONCENTRATE_TYPE_AO',
  label: 'Concentrate Types',
  filterKey: 'concentrateTypes',
  keywords: []
}

export const FlavorFilterOption: FilterOption = {
  type: 'refinement-list',
  attribute: 'FLAVOR',
  label: 'Flavors',
  filterKey: 'flavors',
  keywords: []
}

export const PackSizeFilterOption: FilterOption = {
  type: 'refinement-list',
  attribute: 'PACK_SIZE',
  label: 'Pack Sizes',
  filterKey: 'packSizes',
  keywords: []
}

export const KEYWORD_SEARCH_FILTER: SearchBoxFilterOption = {
  type: 'searchbox',
  label: 'Search',
  filterKey: 'keywordSearch',
  keywords: []
}

export const ALL_PRODUCT_FILTERS = [
  ProductFilters.brand,
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.banner,
  ProductFilters.dispensary,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  ProductSkuFilterOption,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const ACCOUNT_FILTERS = [
  ProductFilters.brand,
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.banner,
  ProductFilters.dispensary,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  CountryCodeFilterOption
]

export const ALL_FILTERS = [...new Set([...ALL_PRODUCT_FILTERS, ...ACCOUNT_FILTERS])]

export const PRODUCT_DETAIL_FILTERS = [
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.banner,
  ProductFilters.dispensary,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const BRAND_DETAIL_FILTERS = [
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.banner,
  ProductFilters.dispensary,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  ProductSkuFilterOption,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const DISPENSARY_DETAIL_FILTERS = [
  ProductFilters.brand,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  ProductSkuFilterOption,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const ALL_PRODUCT_FILTERS_WITHOUT_DISPENSARIES = ALL_PRODUCT_FILTERS.filter(
  (filter) => filter.filterKey !== 'dispensaries'
)

export const VIRTUAL_PRODUCT_FILTERS = ALL_PRODUCT_FILTERS

export const INVISIBLE_PRODUCT_SKU_FILTERS = [
  ProductFilters.brand,
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.dispensary,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  ProductFilters.strain,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption,
  KEYWORD_SEARCH_FILTER
]

export const MASTERED_STATUS_ATTRIBUTE = 'MASTERED_STATUS'
export type MasteredStatusValue = 'mastered' | 'un-mastered'
export type MasteredStatusWithEmptyValue = MasteredStatusValue | ''

/**
 * A filterset defined in terms of it's GraphQL fields
 */
export type FiltersetGraphQL = {
  filterset_id: string
  filterset_name: string
  userId: string
  subscriptionId: string | null
  tags: Array<{ tag: Tag }>
  filters: Filters
}

/** Get a filterset that includes the dispensary ID and the product-related filters of the given filterset
 */
export const getFiltersetForDispensary = (dispensaryId: string, filterset: DraftFilterset): DraftFilterset => {
  const nonLocationFilterBy = Object.fromEntries(
    Object.entries(filterset.filters.filterBy).filter(([key]) => !LOCATION_FILTER_KEYS.includes(key as FilterKey))
  ) as FilterBy
  return {
    filters: {
      ...getDefaultFilters(),
      filterBy: {
        ...nonLocationFilterBy,
        dispensaryIds: [dispensaryId]
      },
      should: filterset.filters.should,
      keywordSearch: filterset.filters.keywordSearch
    }
  }
}

const flagLabels: Record<FilterFlagKey, string> = {
  medical: 'Medical',
  recreational: 'Recreational',
  infusedPreRoll: 'Infused'
}

// Pair of filter keys that can be used to filter by product/SKU
// When filtering by Product/SKU, these both keys can't coexist in the filterBy object
export const productSkuKeys: FilterKeys = ['cmIds', 'menuIds']

export type FilterMap = Record<ALGOLIA_INDEX | 'elastic', string>

export const DatabaseFilterMap: Record<FilterKeyWithAlternativeKey, FilterMap> = {
  brands: {
    elastic: ElasticFields.brands,
    [VARIANT_INDEX]: 'BRAND',
    [DISPENSARY_INDEX]: 'BRANDS',
    [BRAND_INDEX]: 'BRAND_NAME'
  },
  states: {
    elastic: ElasticFields.states,
    [VARIANT_INDEX]: 'D_STATE',
    [DISPENSARY_INDEX]: 'STATE',
    [BRAND_INDEX]: 'STATES'
  },
  countryCodes: {
    elastic: ElasticFields.countryCodes,
    [VARIANT_INDEX]: 'D_COUNTRY',
    [DISPENSARY_INDEX]: 'COUNTRY_CODE'
  },
  cities: {
    elastic: ElasticFields.cities,
    [VARIANT_INDEX]: 'D_CITY',
    [DISPENSARY_INDEX]: 'CITY'
  },
  dispensaries: {
    elastic: ElasticFields.dispensaries,
    [VARIANT_INDEX]: 'MASTER_D_NAME',
    [DISPENSARY_INDEX]: 'DISPENSARY_NAME'
  },
  banners: {
    elastic: ElasticFields.banners,
    [VARIANT_INDEX]: 'D_BANNER',
    [DISPENSARY_INDEX]: 'BANNER'
  },
  categories: {
    elastic: ElasticFields.categories,
    [VARIANT_INDEX]: 'CATEGORY_0',
    [DISPENSARY_INDEX]: 'CATEGORY_0'
  },
  segments: {
    elastic: ElasticFields.segments,
    [VARIANT_INDEX]: 'CATEGORY_1',
    [DISPENSARY_INDEX]: 'CATEGORY_1'
  },
  subsegments: {
    elastic: ElasticFields.subsegments,
    [VARIANT_INDEX]: 'CATEGORY_2',
    [DISPENSARY_INDEX]: 'CATEGORY_2'
  },
  dispensaryIds: {
    elastic: ElasticFields.dispensaryIds,
    [VARIANT_INDEX]: 'MASTER_D_ID',
    [DISPENSARY_INDEX]: 'DISPENSARY_ID'
  },
  unit: {
    elastic: ElasticFields.unit,
    [VARIANT_INDEX]: 'VARIANTS.UNIT'
  },
  quantity: {
    elastic: ElasticFields.quantity,
    [VARIANT_INDEX]: 'VARIANTS.QUANTITY'
  },
  cmIds: {
    elastic: ElasticFields.cmIds,
    [VARIANT_INDEX]: 'CM_ID',
    [DISPENSARY_INDEX]: ''
  },
  menuIds: {
    elastic: ElasticFields.menuIds,
    [VARIANT_INDEX]: 'objectID',
    [DISPENSARY_INDEX]: ''
  },
  medical: {
    elastic: ElasticFields.medical,
    [VARIANT_INDEX]: 'VARIANTS.IS_MEDICAL',
    [DISPENSARY_INDEX]: 'MEDICAL'
  },
  recreational: {
    elastic: ElasticFields.recreational,
    [VARIANT_INDEX]: 'VARIANTS.IS_RECREATIONAL',
    [DISPENSARY_INDEX]: 'RECREATIONAL'
  },
  infusedPreRoll: {
    elastic: ElasticFields.infusedPreRoll,
    [VARIANT_INDEX]: 'INFUSED'
  },
  concentrateTypes: {
    elastic: ElasticFields.concentrateTypes,
    [VARIANT_INDEX]: 'CONCENTRATE_TYPE_AO'
  },
  flavors: {
    elastic: ElasticFields.flavors,
    [VARIANT_INDEX]: 'FLAVOR'
  },
  packSizes: {
    elastic: ElasticFields.packSizes,
    [VARIANT_INDEX]: 'PACK_SIZE'
  },
  delivery: {
    elastic: ElasticFields.delivery,
    [VARIANT_INDEX]: 'D_DELIVERY_ENABLED'
  },
  licenseNumbers: {
    [DISPENSARY_INDEX]: 'LICENSE_NUMBER'
  },
  products: {
    elastic: 'NAME',
    [VARIANT_INDEX]: 'NAME'
  },
  productPresentations: {
    elastic: ElasticFields.productPresentations,
    [VARIANT_INDEX]: 'VARIANTS.PRODUCT_PRESENTATION'
  },
  strains: {
    elastic: ElasticFields.strains,
    [VARIANT_INDEX]: 'STRAIN'
  }
}

export const PRODUCT_SKUS_VARIANT_FIELDS = [
  DatabaseFilterMap.cmIds[VARIANT_INDEX],
  DatabaseFilterMap.menuIds[VARIANT_INDEX]
] as string[]

export enum JoinRule {
  OR = ' OR ',
  AND = ' AND '
}

export const defaultAlgoliaDispensaryFilters = ['NOT DELIVERY:"Delivery Only"']
export const elasticNotDeliveryOnly = {
  delivery: 'Delivery Only' as FilterBy['delivery']
}

export const normalizeFilters = (filters: Filters): Filters => {
  return keysFromShouldToFilters(filters, productSkuKeys)
}

export const normalizeFilterset = (filterset?: DraftFilterset): DraftFilterset => {
  // First move the product keys from should to the top level filters then merge with the default filterset to ensure all fields are presented
  const normalizedFilterset = filterset
    ? mergeFiltersets(emptyDraftFilterset(), {
        ...filterset,
        filters: normalizeFilters(filterset.filters)
      })
    : emptyDraftFilterset()
  return normalizedFilterset
}

// Wrapper for searchStateFromFilters
export const evaluateSearchState = (filterset?: DraftFilterset): SearchState => {
  return searchStateFromFilters(filterset?.filters || defaultFilters, VARIANT_INDEX)
}

// Converts the search state to a filterset and then applies just the visible filters before returning the result.
export const extractFilters = (searchState: SearchState, visibleFilters: FilterOption[]): Filters => {
  let filters = filtersFromSearchState(searchState, VARIANT_INDEX)
  filters = filterByFilterOptions(filters, visibleFilters)
  // Move the product keys from the top level filters to should
  filters = keysFromFiltersToShould(filters, productSkuKeys)
  return filters
}

export const searchStateFromFilters = (
  filtersToConvert: Filters,
  index: ALGOLIA_INDEX = VARIANT_INDEX
): SearchState => {
  const searchState: SearchState = {}
  // First normalize the filters:
  const filters = normalizeFilters(filtersToConvert)

  for (const [key, value] of Object.entries(DatabaseFilterMap)) {
    const filterId = key as FilterKey
    if (typeof filters.filterBy[filterId] === 'boolean') {
      const filter = findFilterByAttribute(value[index])
      if (filter?.type === 'switch') {
        if (!searchState.refinementList) {
          searchState.refinementList = {}
        }
        searchState.refinementList[value[index] as string] = [filters.filterBy[filterId]!.toString()]
      } else if (value[index]) {
        if (searchState.toggle === undefined) {
          searchState.toggle = {}
        }
        searchState.toggle[value[index] as string] = filters.filterBy[filterId] as boolean
      }
    } else if (
      filters.filterBy[filterId] &&
      (filters.filterBy[filterId] as string | string[])?.length &&
      typeof value[index] === 'string'
    ) {
      if (!searchState.refinementList) {
        searchState.refinementList = {}
      }
      searchState.refinementList[value[index] as string] = filters.filterBy[filterId] as string[]
    }
  }

  if (filters.keywordSearch) {
    searchState.query = filters.keywordSearch
  }
  return searchState
}

export const filtersFromSearchState = (searchState: SearchState, index: ALGOLIA_INDEX = VARIANT_INDEX): Filters => {
  const filters = getDefaultFilters()

  for (const [key, value] of Object.entries(DatabaseFilterMap)) {
    const filterId = key as FilterKey
    if (typeof value[index] === 'string') {
      const refinementListKey = value[index] as string
      if (searchState.refinementList?.[refinementListKey] && searchState.refinementList?.[refinementListKey].length) {
        const filter = findFilterByAttribute(refinementListKey)
        if (filter?.type === 'switch') {
          filters.filterBy[filterId] = (searchState.refinementList[refinementListKey][0] === 'true') as any
        } else {
          filters.filterBy[filterId] = searchState.refinementList[refinementListKey] as any
        }
      } else if (searchState.toggle?.[refinementListKey]) {
        filters.filterBy[filterId] = searchState.toggle[refinementListKey] as any
      }
    }
  }

  if (searchState.query) {
    filters.keywordSearch = searchState.query
  }
  return filters
}

/**
 * Move the filters specified in keysToMove to the should rule, if all are populated
 * @param filters The filters to verify
 * @param keysToMove The keys that must be moved
 * @returns A new handled filters object
 */
export const keysFromFiltersToShould = (filters: Filters, keysToMove: FilterKeys): Filters => {
  // If there are multiple keys to move
  if (keysToMove.length > 1) {
    const newFilters = clone(filters)
    // Check if filters are populated
    const populatedFilters = keysToMove.filter(
      (key) =>
        typeof newFilters.filterBy[key] === 'boolean' ||
        (newFilters.filterBy[key] && (newFilters.filterBy[key] as string | string[]).length)
    )
    if (populatedFilters.length === keysToMove.length) {
      const emptyFilters = getDefaultFilters()
      // If there are multiple populated filters, move them to the should
      const should: Should = {
        filterBy: Object.fromEntries(
          populatedFilters.map((key) => {
            const value = newFilters.filterBy[key]
            // Reset the filterBy to the default
            newFilters.filterBy[key] = emptyFilters.filterBy[key] as any
            return [key, value]
          })
        ) as FilterBy
      }
      return {
        ...newFilters,
        should: [...(newFilters.should ?? []), should]
      }
    }
  }
  return filters
}

/**
 * Move a should rule that contains exactly and only the keys specified in keysToMove, to the filterBy object
 * @param filters The filters to verify
 * @param keysToMove The keys that should be moved from the should rule
 * @returns A new handled filters object
 */
export const keysFromShouldToFilters = (filters: Filters, keysToMove: FilterKeys): Filters => {
  // If there are should rules with all keysToMove, move them to the filterBy
  if (filters.should?.length) {
    const shouldToMoveIndex = filters.should.findIndex((should) => {
      return (
        Object.keys(should.filterBy ?? {})
          .sort()
          .join(',') === keysToMove.sort().join(',')
      )
    })
    if (shouldToMoveIndex >= 0) {
      const newFilters = clone(filters)
      newFilters.filterBy = {
        ...newFilters.filterBy,
        ...filters.should[shouldToMoveIndex].filterBy
      }
      // Remove the should rule
      newFilters.should = filters.should.filter((should, index) => index !== shouldToMoveIndex)
      // If should is empty, remove it
      if (!newFilters.should.length) {
        delete newFilters.should
      }
      return newFilters
    }
  }
  return filters
}

export const filtersToTags = (filtersToConvert: Filters): string[] => {
  // First normalize the filters:
  const filters = normalizeFilters(filtersToConvert)
  const tags = []
  if (filters.keywordSearch) {
    tags.push(`keyword: "${filters.keywordSearch}"`)
  }
  // Separate the product fields from the rest of the filters
  const { cmIds, menuIds, ...filterBy } = filters.filterBy
  // Generate the non product tags
  for (const [key, value] of Object.entries(filterBy)) {
    const formattedKey = tagLabel(key)
    if (Array.isArray(value)) {
      value.forEach((val) => tags.push(`${singular(formattedKey)}: ${val}`))
    } else if (typeof value === 'string' && value) {
      tags.push(`${singular(formattedKey)}: ${value}`)
    } else if (typeof value === 'boolean') {
      tags.push(`${!value ? 'Not ' : ''}${flagLabels[key as FilterFlagKey] ?? key}`)
    }
  }
  // Count the amount of selected products
  const productsCount = (cmIds?.length ?? 0) + (menuIds?.length ?? 0)
  // Push the products tag with the total selected products
  if (productsCount) {
    tags.push(`Products: ${productsCount}`)
  }

  return tags
}

export const activeFilterCount = (filtersToCount: Filters, filterOptions: FilterOption[]): number => {
  // First normalize the filters:
  const filters = normalizeFilters(filtersToCount)
  let count = 0
  const filterKeys = filterOptions.map((fo) => fo.filterKey).flat()
  if (filters.keywordSearch && filterKeys.includes(KEYWORD_SEARCH_FILTER.filterKey)) {
    count++
  }

  const refinementFilterKeys = filterOptions
    .filter((fo) => ['refinement-list', 'refinement-product-sku-list'].includes(fo.type))
    .flatMap((fo) => fo.filterKey) as FilterKeys
  refinementFilterKeys.forEach((facet) => {
    if ((filters.filterBy[facet] as string | string[])?.length !== undefined) {
      count += (filters.filterBy[facet] as string | string[])?.length || 0
    }
  })

  const toggleFilterKeys: FilterFlagKey[] = filterOptions
    .filter((fo) => ['toggle', 'switch'].includes(fo.type))
    .flatMap((fo) => fo.filterKey) as FilterFlagKey[]
  count += toggleFilterKeys.filter(
    (a) => filterKeys.includes(a as FilterFlagKey) && Object.keys(filters.filterBy).includes(a)
  ).length

  return count
}

export const activeFilters = (filtersToCheck: Filters, filterOptions: FilterOption[]): FilterOption[] => {
  // First normalize the filters:
  const filters = normalizeFilters(filtersToCheck)
  const activeFilters: FilterOption[] = []
  for (const filterOption of filterOptions) {
    const filterKey = filterOption.filterKey
    if (filterKey === 'keywordSearch' && filters.keywordSearch) {
      activeFilters.push(filterOption)
    } else if (typeof filterKey === 'string') {
      const filterValue = filters.filterBy[filterKey as FilterKey]
      if (typeof filterValue === 'boolean' || (filterValue?.length !== undefined && filterValue.length > 0)) {
        activeFilters.push(filterOption)
      }
    } else if (Array.isArray(filterKey)) {
      const filterValues = filterKey.map((key) => filters.filterBy[key])
      if (
        filterValues.some((value) => typeof value === 'boolean' || (value?.length !== undefined && value.length > 0))
      ) {
        activeFilters.push(filterOption)
      }
    }
  }
  return activeFilters
}

//Adds a static filter e.g. (CM_ID, objectID, dispensaryId) and removes conflicting filters from filter options
export const filterByStaticFilter = (
  searchState: SearchState,
  filterName: string,
  filterValue: string,
  removeFacets: any,
  index: ALGOLIA_INDEX = VARIANT_INDEX
): SearchState => {
  const modifiedSearchState = clone(searchState)
  if (!modifiedSearchState.refinementList) {
    modifiedSearchState.refinementList = {}
  }
  const refinementListKey = DatabaseFilterMap[filterName as FilterKey][index] as string
  if (!modifiedSearchState.refinementList[refinementListKey]) {
    modifiedSearchState.refinementList[refinementListKey] = []
  }
  if (!modifiedSearchState.refinementList[refinementListKey].includes(filterValue)) {
    modifiedSearchState.refinementList[refinementListKey].push(filterValue)
  }

  // Remove all other existing dispensary-related filters as they could conflict with the
  // static filter
  const dispensaryFacets: Array<FilterKey> = removeFacets
  dispensaryFacets.forEach((facet) => {
    const refinementListKey = DatabaseFilterMap[facet][index] as string
    if (modifiedSearchState.refinementList?.[refinementListKey]) {
      delete modifiedSearchState.refinementList[refinementListKey]
    }
  })

  return modifiedSearchState
}

interface RefinementFilter {
  attribute: string
  values?: string[]
  index?: 'products' | 'dispensaries'
}

export const CATEGORY_SEPARATOR = ' > '

export const filtersToFiltersString = (
  filters: Filters,
  index: ALGOLIA_INDEX = VARIANT_INDEX,
  joinRule: JoinRule = JoinRule.AND
) => {
  const filterStrings = []
  const databaseKeys = Object.entries(DatabaseFilterMap).filter(
    ([, value]) => typeof value[index] === 'string' && value[index]
  )

  const refinementFilters: RefinementFilter[] = databaseKeys.map(([key, value]) => ({
    attribute: value[index] as string,
    values: filters.filterBy[key as FilterKey] as string[]
  }))

  filterStrings.push(...refinementFilters.map((f) => facetListToFilterString(f)).filter(Boolean))

  if (filters.should) {
    filterStrings.push(...shouldToFilterStrings(filters.should, index))
  }

  if (Object.keys(filters.mustNot || {}).length) {
    const mustNotFilters: RefinementFilter[] = databaseKeys.map(([key, value]) => ({
      attribute: value[index] as string,
      values: filters.mustNot?.[key as FilterKey] as string[]
    }))
    filterStrings.push(
      ...mustNotFilters
        .map((f) => facetListToFilterString(f, JoinRule.AND, (attribute, value) => `NOT ${attribute}:"${value}"`))
        .filter(Boolean)
    )
  }

  return filterStrings.join(joinRule)
}

export const shouldToFilterStrings = (shouldList: Should[], index: ALGOLIA_INDEX): string[] => {
  const filterStrings: string[] = []

  shouldList.forEach((shouldItem) => {
    if (shouldItem.filterBy) {
      if (!shouldItem.minimumShouldMatch || shouldItem.minimumShouldMatch < 2) {
        const filters: Filters = { ...defaultFilters, filterBy: shouldItem.filterBy }
        const shouldString = filtersToFiltersString(filters, index, JoinRule.OR)
        if (shouldString) {
          filterStrings.push(`(${shouldString})`)
        }
      } else {
        // Complex logic for grouping items accordingly with minimumShouldMatch:
        // Ex: filterBy: { unit: ['mg'], quantity: ['1','2'] } should return:
        // ((unit='mg' AND quantity='1') OR (unit='mg' AND quantity='2'))

        // Get the valid keys with non empty values for the given algolia index
        const databaseKeys = Object.entries(DatabaseFilterMap).filter(
          ([key, value]) =>
            typeof value[index] === 'string' &&
            value[index] &&
            (typeof shouldItem.filterBy?.[key as FilterKey] === 'boolean' ||
              (shouldItem.filterBy?.[key as FilterKey] as string[])?.length)
        )

        // Format values to [[unit='mg'], [quantity='1', quantity='2']]
        const filters: string[][] = databaseKeys.map(([key, value]) => {
          if (typeof shouldItem?.filterBy?.[key as FilterKey] === 'boolean') {
            return [`${value[index]}:"${shouldItem?.filterBy?.[key as FilterKey]}"`]
          } else {
            return (shouldItem?.filterBy?.[key as FilterKey] as string[]).map((item) => `${value[index]}:"${item}"`)
          }
        })
        // Iterate through the items creating all possible unique combination containing the minimumShouldMatch fields
        const uniqueCombinations: string[] = []
        for (let i = 0; i <= filters.length - shouldItem.minimumShouldMatch; i++) {
          // Creates a list that will hold all the combinations for the current field
          let fieldCombinations: string[][] = [...filters[i].map((item) => [item])]
          for (let j = 1; j < (shouldItem.minimumShouldMatch ?? 1); j++) {
            // Create a temporary list to not mutate fieldCombinations while iterating it
            const tempList: string[][] = []
            for (let k = i + j; k < filters.length; k++) {
              filters[k].forEach((item) => {
                fieldCombinations.forEach((leftItems) => {
                  // Prevent duplicated values from being inserted in the combination
                  if (!leftItems.includes(item)) {
                    tempList.push([...leftItems, item])
                  }
                })
              })
            }
            // Update the combinations list with the tempList
            fieldCombinations = tempList
          }
          // Join the inner fields with AND logic and push them to the combinations list
          uniqueCombinations.push(...fieldCombinations.map((rules) => `(${rules.join(JoinRule.AND)})`))
        }
        // Join all the combinations with OR in case there're valid combinations
        if (uniqueCombinations.length) {
          filterStrings.push(`(${uniqueCombinations.join(JoinRule.OR)})`)
        }
      }
    }
    // Recursively call should logic
    if (shouldItem.should?.length) {
      const joinLogic = !shouldItem.minimumShouldMatch || shouldItem.minimumShouldMatch < 2 ? JoinRule.OR : JoinRule.AND
      const shouldStringList = shouldToFilterStrings(shouldItem.should, index)
      if (shouldStringList.length) {
        filterStrings.push(`(${shouldStringList.join(joinLogic)})`)
      }
    }
  })

  return filterStrings
}

export const categoryToFilterStrings = (hierrarchicalCategory: string): string[] => {
  const [category, segment, subSegment] = hierrarchicalCategory.split(CATEGORY_SEPARATOR)
  const filterStrings = [`CATEGORY_0:"${category}"`]
  if (segment) {
    filterStrings.push(`CATEGORY_1:"${category}${CATEGORY_SEPARATOR}${segment}"`)
  }
  if (subSegment) {
    filterStrings.push(`CATEGORY_2:"${category}${CATEGORY_SEPARATOR}${segment}${CATEGORY_SEPARATOR}${subSegment}"`)
  }
  return filterStrings
}

const facetListToFilterString = (
  { attribute, values }: RefinementFilter,
  joinRule: JoinRule = JoinRule.OR,
  getFacetValue?: (attribute: string, value: string) => string
) => {
  return values && values.length
    ? `(${values
        .filter((v) => v)
        .map((facetValue) => getFacetValue?.(attribute, facetValue) || `${attribute}:"${facetValue}"`)
        .join(`${joinRule}`)})`
    : typeof values === 'boolean'
    ? `${attribute}:${values}`
    : ''
}

const mergeArrays = (a1?: string[], a2?: string[]): string[] => {
  return Array.from(new Set([...(a1 || []), ...(a2 || [])]))
}

export const mergeFiltersets = (filterset1?: DraftFilterset, filterset2?: DraftFilterset): DraftFilterset => {
  if (!filterset1) {
    return filterset2 || emptyDraftFilterset()
  }
  if (!filterset2) {
    return filterset1 || emptyDraftFilterset()
  }
  if (filterset1.id && filterset2.id) {
    throw new Error('Cannot merge two filtersets if both have IDs')
  }
  if (filterset1.filters.keywordSearch && filterset2.filters.keywordSearch) {
    throw new Error('Cannot merge two filtersets if both define a keyword search')
  }
  // Normalize the filters and check if at least one of the filtersets has a should
  const hasShould = !!filterset1.filters.should?.length || !!filterset2.filters.should?.length
  const normalizedFilterset1 = {
    ...filterset1,
    filters: normalizeFilters(filterset1.filters)
  }
  const normalizedFilterset2 = {
    ...filterset2,
    filters: normalizeFilters(filterset2.filters)
  }

  const mergedFilterset: DraftFilterset = clone(normalizedFilterset1)

  const flagKeys = Object.keys(flagLabels)
  for (const [key, value] of Object.entries(DatabaseFilterMap)) {
    const filterId = key as FilterKey
    if (typeof value[VARIANT_INDEX] === 'string') {
      const valueA = normalizedFilterset1.filters.filterBy[filterId]
      const valueB = normalizedFilterset2.filters.filterBy[filterId]
      if (valueA === undefined && valueB === undefined) {
        continue
      }
      if (!flagKeys.includes(filterId)) {
        mergedFilterset.filters.filterBy[filterId] = mergeArrays(valueA as string[], valueB as string[]) as any
      } else {
        const flagAIsSet = typeof valueA === 'boolean'
        const flagBIsSet = typeof valueB === 'boolean'
        if (flagAIsSet && flagBIsSet && valueA !== valueB) {
          throw new Error(
            `Cannot merge two filtersets if both define different ${flagLabels[filterId as FilterFlagKey]}`
          )
        }
        if (flagAIsSet || flagBIsSet) {
          mergedFilterset.filters.filterBy[filterId] = (valueA ?? valueB) as any
        }
      }
    }
  }

  mergedFilterset.filters.keywordSearch =
    normalizedFilterset1.filters.keywordSearch || normalizedFilterset2.filters.keywordSearch || ''

  // If at least one of the filtersets had a should, move the product keys to the should
  if (hasShould) {
    return {
      ...mergedFilterset,
      filters: keysFromFiltersToShould(mergedFilterset.filters, productSkuKeys)
    }
  }

  return mergedFilterset
}

export const getFlagFilters = (filters: FilterBy, byValue?: boolean) => {
  return Object.entries(filters)
    .filter(([, value]) => typeof value === 'boolean' && (byValue === undefined || value === byValue))
    .map(([key]) => key)
}

export const applyFilterOverrides = (
  filterset: DraftFilterset,
  overrides?: DraftFilterset,
  overrideUndefinedFlags = false
): DraftFilterset => {
  if (!overrides) {
    return filterset
  }
  const newFilterset = clone(filterset)
  for (const [key, value] of Object.entries(overrides.filters.filterBy)) {
    if (Array.isArray(value) || typeof value === 'boolean' || typeof value === 'string') {
      newFilterset.filters.filterBy[key as FilterKey] = value as any
    }
  }
  if (
    typeof overrides.filters.keywordSearch === 'string' &&
    (overrides.filters.keywordSearch.length || overrideUndefinedFlags)
  ) {
    newFilterset.filters.keywordSearch = overrides.filters.keywordSearch
  }
  // Make sure the "should logic" from override is presented on the new filterset, otherwise clear it
  newFilterset.filters.should = overrides.filters.should
  if (newFilterset.filters.should === undefined) {
    delete newFilterset.filters.should
  }
  // If true, make sure to remove flag filters that are undefined on overrides (med/rec)
  if (overrideUndefinedFlags) {
    const flagKeys = Object.keys(flagLabels)
    flagKeys.forEach((key) => {
      if (typeof overrides.filters.filterBy[key as FilterKey] !== 'boolean') {
        delete newFilterset.filters.filterBy[key as FilterKey]
      }
    })
  }
  return newFilterset
}

export const stringifyFilterArray = (filters: string[], maxItems = 2): string => {
  if (filters.length > maxItems) {
    const difference = filters.length - maxItems
    return `${filters.slice(0, maxItems).join(', ')} + ${difference} more`
  } else {
    return filters.slice(0, maxItems).join(', ')
  }
}

const tagLabel = (key: string) => {
  const filter = ALL_FILTERS.find((f) => f.filterKey === key)
  return filter?.tagLabel || filter?.label || key
}

export const findFilterByAttribute = (attribute: string | undefined) => {
  return ALL_FILTERS.find((f) => {
    if ('attributes' in f && typeof attribute === 'string') {
      return f.attributes.includes(attribute)
    }
    if ('attribute' in f) {
      return f.attribute === attribute
    }
    return false
  })
}

export const getAttributeValue = (
  value: string,
  attribute: string,
  currentRefinement?: string,
  filter?: FilterOption
) => {
  // Product/Sku filters use the current refinement value
  if (attribute === 'CM_ID' || attribute === 'objectID') {
    return currentRefinement as string
  }
  // Special case for keyword search filters
  if (value.startsWith('query: ')) {
    return value.replace('query: ', '')
  }
  // Hierarchical filters use the last part of the label
  if (['CATEGORY_0', 'CATEGORY_1', 'CATEGORY_2'].includes(attribute as string)) {
    return value.split(' > ').pop() as string
  }
  // This is a switch filter
  const filterData = filter || findFilterByAttribute(attribute)
  if (filterData?.type === 'switch') {
    return value === 'true' ? filterData.attributeTrueLabel : filterData.attributeFalseLabel
  }

  return value
}

export const getAttributeLabel = (attribute: string | undefined, options?: { plural: boolean }) => {
  const filter = findFilterByAttribute(attribute)
  const label = filter?.tagLabel || filter?.label || attribute?.toString() || 'Search'
  return options?.plural ? label : singular(label)
}

/* Get the filter summary for a filterset.
 *
 * Returns an array of strings, each string is a filter summary with key and value.
 * For each filter option, if the filter is set, we add a filter summary to the array.
 * If the filter is an array, we limit the number of items included in the filter summary to two.
 * If the filter is a string or boolean, we include the filter in the filter summary.
 * If the filters is a product, we include the amount of products/skus in the filter summary.
 */
export const getFilterSummary = (filterset: DraftFilterset, maxItems = 2): string[][] => {
  // First normalize the filters:
  const filters = normalizeFilters(filterset.filters)
  const summary: string[][] = []
  // Separate the product fields from the rest of the filters
  const { cmIds, menuIds, ...filterBy } = filters.filterBy
  // Generate the non product summary
  for (const [key, value] of Object.entries(filterBy)) {
    const formattedKey = tagLabel(key)
    if (Array.isArray(value) && value.length) {
      summary.push([formattedKey, stringifyFilterArray(value, maxItems)])
    } else if (typeof value === 'string' && value) {
      summary.push([formattedKey, value])
    } else if (typeof value === 'boolean') {
      summary.push([flagLabels[key as FilterFlagKey] ?? key, value ? 'Yes' : 'No'])
    }
  }
  // Count the amount of selected products
  const productsCount = (cmIds?.length ?? 0) + (menuIds?.length ?? 0)
  // Push the products tag with the total selected products
  if (productsCount) {
    summary.push(['Product/SKU', `${productsCount}`])
  }

  if (filters.keywordSearch) {
    summary.push(['Keyword search', filters.keywordSearch])
  }

  return summary
}

export const filterByFilterOptions = (filters: Filters, filterOptions: FilterOption[]): Filters => {
  const newFilters: Filters = {
    filterBy: {},

    keywordSearch: ''
  }
  for (const filterOption of filterOptions) {
    if (filterOption.filterKey === 'keywordSearch') {
      newFilters.keywordSearch = filters.keywordSearch
    } else {
      const filterKeys = Array.isArray(filterOption.filterKey) ? filterOption.filterKey : [filterOption.filterKey]
      filterKeys.forEach((filterKey) => {
        if (filterKey in filters.filterBy) {
          newFilters.filterBy[filterKey] = filters.filterBy[filterKey] as any
        }
      })
    }
  }
  return newFilters
}

export const truncateSegmentedFilterStr = (str: string, separator = CATEGORY_SEPARATOR, length = 7) => {
  const parts = str.split(separator)
  if (parts.length <= 2) {
    return str
  }
  for (let i = 1; i < parts.length - 1; i++) {
    parts[i] = parts[i].length > length ? `${parts[i].slice(0, length).trimEnd()}...` : parts[i]
  }
  return parts.join(separator)
}

/**
 * Convert the attribute and attributtes from the given FilterOption list according to the index fields
 * @param filterOptions A list of FilterOption
 * @param index The index to convert
 * @returns A list of FilterOption with the attributes converted
 */
export const getIndexFilterOptions = (filterOptions: FilterOption[], index: ALGOLIA_INDEX | 'elastic') => {
  // By default the attributes are based on the VARIANT_INDEX, so we don't need to convert it
  if (index === VARIANT_INDEX) {
    return filterOptions
  }
  return filterOptions.reduce((acc, option) => {
    if (option.filterKey === 'keywordSearch') {
      acc.push(option)
    } else {
      const keys = [option.filterKey].flat().map((key: FilterKey) => DatabaseFilterMap[key][index])
      if ((option as any).attribute && keys[0]) {
        acc.push({ ...option, attribute: keys[0] } as FilterOption)
      }
      if ((option as any).attributes && keys.every(Boolean)) {
        acc.push({ ...option, attributes: keys } as FilterOption)
      }
    }
    return acc
  }, [] as FilterOption[])
}

/**
 * Get the attributes from the given FilterOption list according to the index fields
 * @param filterOptions A list of FilterOption
 * @param [index] If provided will convert the attributes according to the index fields
 * @returns A list of attributes
 */
export const getFilterAttributes = (filterOptions: FilterOption[], index?: ALGOLIA_INDEX | 'elastic') => {
  const convertedFilters = index ? getIndexFilterOptions(filterOptions, index) : filterOptions
  return convertedFilters.reduce((acc, option) => {
    if ((option as any).attribute) {
      acc.push((option as any).attribute)
    } else if ((option as any).attributes) {
      acc.push(...(option as any).attributes)
    } else if ((option as any).filterKey === 'keywordSearch') {
      acc.push('query')
    }
    return acc
  }, [] as string[])
}

/**
 * Apply the default algolia dispensary filters to the given filters
 * @param filters A filter string or Filters object to apply the default algolia dispensary filters
 * @returns A string with the algolia dispensary filters applied
 */
export const applyDefaultAlgoliaDispensaryFilters = (filters?: string | Filters) => {
  if (filters !== undefined && typeof filters !== 'string') {
    filters = filtersToFiltersString(filters, DISPENSARY_INDEX)
  }
  const filterList = [...defaultAlgoliaDispensaryFilters]
  if (filters) {
    // Check if the filters are already wrapped in parenthesis
    filterList.push(filters.startsWith('(') ? filters : `(${filters})`)
  }
  return filterList.join(JoinRule.AND)
}

export const FORCE_NO_RESULTS_FILTER = 'force-no-results'

export const intersectFiltersList = (filters: Filters, list: string[], listKey: FilterKeyType<string[]>) => {
  if (!list.length) {
    return filters
  }
  // Create a new filters object to avoid mutating the original
  const newFilters = clone(filters)
  const originalList = newFilters.filterBy[listKey]
  // If there are no current filters, return all the new items for the given key
  if (!originalList?.length) {
    newFilters.filterBy[listKey] = list
  } else {
    // Otherwise, return the current filters that are also in the given list of items
    newFilters.filterBy[listKey] = originalList.filter((filter) => list.includes(filter))
    // If there are no filters overlapping, return a filter that will force no results
    if (!newFilters.filterBy[listKey]?.length) {
      newFilters.filterBy[listKey] = [FORCE_NO_RESULTS_FILTER]
    }
  }
  return newFilters
}
