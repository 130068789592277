import { Badge } from '@mui/material'
import styled from 'styled-components'
import { DrawerContent as DrawerContentBase } from '../../RightDrawer.style'

export const minSectionWidth = 280

export const TrailingBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: 11,
    right: -16
  }
}))

export const DrawerContent = styled(DrawerContentBase)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${minSectionWidth}px, 1fr));
  grid-auto-rows: min-content;
`
