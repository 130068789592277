import { DraftFilterset, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import {
  AddShoppingCart,
  AttachMoney,
  Category,
  CurrencyExchange,
  LocalOffer,
  RemoveShoppingCart,
  Star,
  Style
} from '@mui/icons-material'
import { SetOptional, SetRequired } from 'type-fest'
import { ALL_PRODUCT_FILTERS, FilterOption, FiltersetGraphQL, mergeFiltersets } from './filterset'
import { CommonKPI } from './kpi'

export const COMPARISON_NEW_URL_KEY = 'new'
export const COMPARISON_DRAFT_URL_KEY = 'draft'
export const COMPARISON_AVERAGE_TOLERANCE = 0.1 // Values between 0 and 1

export type ComparisonKpiType =
  | 'pricing-comparison'
  | 'new-skus'
  | 'price-change'
  | 'oos-skus'
  | 'sku-category-coverage'
  | 'brand-category-coverage'
  | 'top-brands'
  | 'new-promotions'
  | 'top-discounted'

export const comparisonKPIs = new Map<
  ComparisonKpiType,
  { label: string; Icon: React.ElementType; dashboardId: string; helpKey: string }
>([
  [
    'pricing-comparison',
    {
      Icon: AttachMoney,
      label: 'Pricing Comparison',
      dashboardId: '648b7212767b9c7df61d0870',
      helpKey: 'pricing-comparison'
    }
  ],
  [
    'new-skus',
    {
      Icon: AddShoppingCart,
      label: '# of New SKUs',
      dashboardId: '648fe372d333f07232215573',
      helpKey: 'number-of-new-skus'
    }
  ],
  [
    'price-change',
    {
      Icon: CurrencyExchange,
      label: 'Price Change Measurement',
      dashboardId: '648a94abd83ca76dfd1cffa5',
      helpKey: 'price-change-measurement'
    }
  ],
  [
    'oos-skus',
    {
      Icon: RemoveShoppingCart,
      label: 'OOS SKU Measurement',
      dashboardId: '648231247f39e337a3bcab00',
      helpKey: 'out-of-stock-sku-measurement'
    }
  ],
  [
    'sku-category-coverage',
    {
      Icon: Category,
      label: '# of SKUs by Category',
      dashboardId: '647e872ff82a4c62354df922',
      helpKey: 'number-of-skus-by-category'
    }
  ],
  [
    'brand-category-coverage',
    {
      Icon: Category,
      label: '# of Brands by Category',
      dashboardId: '663a35ae4122e114f7388556',
      helpKey: 'number-of-brands-by-category'
    }
  ],
  ['top-brands', { Icon: Star, label: 'Top Brands', dashboardId: '648a9e28767b9c7df61d083d', helpKey: 'top-brands' }],
  [
    'new-promotions',
    { Icon: LocalOffer, label: 'New Promotions', dashboardId: '64ad874b533695560029487a', helpKey: 'new-promotions' }
  ],
  [
    'top-discounted',
    {
      Icon: Style,
      label: 'Top Discounted Products',
      dashboardId: '6490c872d333f07232215594',
      helpKey: 'top-discounted-products'
    }
  ]
])

export type ComparisonContext = 'dispensaries' | 'brands' | 'banners' | 'custom'

export type ComparisonItem = SetRequired<DraftFilterset, 'name'> & { benchmark?: boolean }

export type Comparison = {
  context: ComparisonContext
  items: ComparisonItem[]
  filters?: DraftFilterset
  name: string
  id: string
  userId: string
  subscriptionId?: string
}

export type DraftComparison = SetOptional<Comparison, 'id' | 'name' | 'userId'>

export type ComparisonResponse = {
  comparison_id: string
  user_id: string
  subscription_id?: string
  name: string
  context: string
  items?: ComparisonItem[]
  filters: DraftFilterset
  comparison_filtersets: Array<{
    filterset: FiltersetGraphQL
  }>
}

export type ComparisonItemKPI = {
  itemKpi: CommonKPI
  againstCompetitorsKpi: CommonKPI
  againstAverageKpi: CommonKPI
  percentage?: number
  values?: number[]
}

export type OptionalComparisonItemKPI = SetOptional<ComparisonItemKPI, 'againstCompetitorsKpi' | 'againstAverageKpi'>

export type ComparisonItemsKPI = {
  label: string
  kpi?: OptionalComparisonItemKPI
  values?: number[]
}

export type ComparisonKPI = {
  total: number
  average: number
}

export type KpiSettingsItem = {
  kpi: ComparisonKpiType
  label: string
  isActive: boolean
  order: number
}

export type VisibleKpiSettingsItem = {
  kpi: ComparisonKpiType
  Icon: React.ElementType
  label: string
}

export type ComputedFilters = {
  preFilterset?: DraftFilterset
  visibleFilters: FilterOption[]
  invisibleFilters: FilterOption[]
}

export const getComputedFilters = (context?: ComparisonContext, items?: DraftFilterset[]): ComputedFilters => {
  const visibleFilters = [...ALL_PRODUCT_FILTERS]
  const invisibleFilters = new Set<FilterOption>()

  const preFilterset =
    items?.reduce(
      (acc, item) => mergeFiltersets({ filters: acc.filters }, { filters: item.filters }),
      emptyDraftFilterset()
    ) ?? undefined

  if (context === 'custom') {
    if (preFilterset) {
      ALL_PRODUCT_FILTERS.forEach((filter) => {
        if (filter.filterKey === 'keywordSearch') {
          return
        }
        const keys = Array.isArray(filter.filterKey) ? filter.filterKey : [filter.filterKey]
        keys.forEach((key) => {
          if (
            typeof preFilterset?.filters?.filterBy[key] === 'boolean' ||
            (preFilterset?.filters?.filterBy[key] && (preFilterset.filters.filterBy[key] as string | string[]).length)
          ) {
            invisibleFilters.add(filter)
            visibleFilters.splice(visibleFilters.indexOf(filter), 1)
          }
        })
      })
    }
  } else {
    const index = visibleFilters.findIndex((filter) => filter?.filterKey === context)
    if (index !== -1) {
      invisibleFilters.add(visibleFilters[index])
      visibleFilters.splice(index, 1)
    }
  }

  return { preFilterset, visibleFilters, invisibleFilters: [...invisibleFilters] }
}

export const calculateComparisonKPIs = <T extends string>(items: Record<T, (number | null)[]>[], key: T) => {
  const total = items.reduce((acc, item) => acc + (item[key][item[key].length - 1] ?? 0), 0)
  const average = total / items.length

  // Calculate the KPIs
  const computedData: ComparisonItemKPI[] = items.map((item) => {
    const prev = item[key][item[key].length - 2] ?? 0
    const now = item[key][item[key].length - 1] ?? 0
    const delta = now - prev
    // Item KPI
    const itemKpi = { now, delta }
    // Against Average
    const againstAverageDelta = now - average
    const againstAverageKpi = {
      now: average,
      delta: againstAverageDelta
    }
    // Against Competitors
    const competitorsAverage = (total - now) / (items.length - 1)
    const competitorsDelta = now - competitorsAverage
    const againstCompetitorsKpi = {
      now: competitorsAverage,
      delta: competitorsDelta
    }
    return { itemKpi, againstCompetitorsKpi, againstAverageKpi }
  })
  return { total, average, computedData }
}

export const removeBenchmark = (items: any[], benchmarkIndex = -1) =>
  benchmarkIndex === -1
    ? items
    : items.slice(0, benchmarkIndex).concat(items.slice((benchmarkIndex as unknown as number) + 1))

export const calculateBenchmarkData = (
  benchmarkIndex: number,
  data: any,
  total?: ComparisonItemsKPI[] | ComparisonKPI
) => {
  // If a benchmark is selected there are multiple steps that need to be taken to transform the data (the benchmark data will remain the same)
  // 1. Comparison Item "Data" Changes - used for colors
  //   a. Benchmark: The benchmark colors are compared against the competitorsKPI (no change needed in this function)
  //   b. Competitors: update { againstCompetitorsKpi: now: number delta: number }
  //     If a comparisonItem is not the benchmark then "againstCompetitorsKpi now" is set the the "benchmark now"
  //     and the delta value is set to the "comparisonItem now" - "benchmark now"
  //   c. This need to work for both single values (ComparisonItemKPI) and arrays (ComparisonItemsKPI[])
  // 2. "Total" value changes
  //   a. If the againstCompetitorsKpi is not present then we leave the total as is. (e.g. top-brands total is handled in the query and top-discounts does not have a total)
  //   b. If it is a ComparisonItem array then then we set the total to the benchmark "againstCompetitorsKpi" to be used in the Summary TopKpi component
  //   c. If it is a single ComparisonItem then we set the "average" and "total" to the "benchmark now" to be used as the horizontal line in the "chart total component"

  const benchmarkKpiData = data?.map((comparisonItem: ComparisonItemKPI | ComparisonItemsKPI[], index: number) => {
    const singleItem = data?.[benchmarkIndex]?.itemKpi
    let benchmarkKpi = singleItem
    // 1a. don't change benchmark item
    if (index === benchmarkIndex) {
      return comparisonItem
      // 1b. update the againstCompetitorsKpi for a single comparisonItem
    } else if (singleItem) {
      const itemNow = (comparisonItem as ComparisonItemKPI).itemKpi.now
      return {
        ...comparisonItem,
        againstCompetitorsKpi: {
          ...benchmarkKpi,
          delta: itemNow ? itemNow - benchmarkKpi.now : undefined
        }
      }
      // 1b. update the againstCompetitorsKpi for a comparisonItem array
    } else {
      return (comparisonItem as ComparisonItemsKPI[])?.map((comparisonItem: ComparisonItemsKPI, index: number) => {
        benchmarkKpi = data?.[benchmarkIndex]?.[index]?.kpi?.itemKpi
        return {
          ...comparisonItem,
          kpi: {
            ...comparisonItem.kpi,
            againstCompetitorsKpi: {
              ...benchmarkKpi,
              delta: (comparisonItem.kpi?.itemKpi?.now ?? 0) - (benchmarkKpi?.now ?? 0)
            }
          }
        }
      })
    }
  })

  // 2a. default to the current total (used in cases where total is not present, N/A and for top-brands (handled in query)
  let benchmarkTotal = total
  // 2b. update the total for a comparisonItem array to be the benchmark againstCompetitorsKpi
  if (data?.[benchmarkIndex]?.[0]?.kpi?.againstCompetitorsKpi) {
    benchmarkTotal = data[benchmarkIndex].map((item: ComparisonItemsKPI) => ({
      ...item,
      kpi: {
        itemKpi: item?.kpi?.againstCompetitorsKpi
      }
    }))
    // 2c. update the total for a single comparisonItem to be the benchmark "now"
  } else if (data?.[benchmarkIndex]?.itemKpi) {
    const benchmarkNow = data[benchmarkIndex].itemKpi?.now
    // chart won't display if total is 0 so set to -1 as dummy value
    benchmarkTotal = {
      average: benchmarkNow,
      total: benchmarkNow || -1
    }
  }
  return {
    data: benchmarkKpiData,
    total: benchmarkTotal
  }
}
