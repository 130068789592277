import { BrandIcon, ComparisonIcon, DispensaryIcon, MuiIcon, ProductsIcon, ReportIcon } from '@client/styles/global'
import { RequireExactlyOne, SetRequired } from 'type-fest'
import { TrackableCategory } from './tracking'

export type FavoriteColumns = RequireExactlyOne<{
  menuId?: string
  dispensaryId?: string
  cmId?: string
  brandId?: string
  reportId?: string
  comparisonId?: string
}>

export type FavoriteKey = keyof FavoriteColumns

type FavoriteTypeData = {
  label: string
  trackableCategory: TrackableCategory
  keys: FavoriteKey[]
  Icon: MuiIcon
}

export type FavoriteType = 'dispensaries' | 'brands' | 'products' | 'reports' | 'comparisons'

type BaseFavorite = {
  favoriteId: string
  name: string
  favoritedAt: string
  formattedFavoritedAt?: string
}

export type DispensaryFavorite = BaseFavorite &
  SetRequired<FavoriteColumns, 'dispensaryId'> & {
    type: 'dispensaries'
  }

export type BrandFavorite = BaseFavorite &
  SetRequired<FavoriteColumns, 'brandId'> & {
    type: 'brands'
  }

export type ComparisonFavorite = BaseFavorite &
  SetRequired<FavoriteColumns, 'comparisonId'> & {
    type: 'comparisons'
  }

export type ReportFavorite = BaseFavorite &
  SetRequired<FavoriteColumns, 'reportId'> & {
    type: 'reports'
  }

export type MaterProductFavorite = BaseFavorite &
  SetRequired<FavoriteColumns, 'cmId'> & {
    type: 'products'
  }

export type NonMasteredFavorite = BaseFavorite &
  SetRequired<FavoriteColumns, 'menuId'> & {
    type: 'products'
  }

export type Favorite =
  | DispensaryFavorite
  | BrandFavorite
  | ComparisonFavorite
  | ReportFavorite
  | MaterProductFavorite
  | NonMasteredFavorite

export type DraftFavorite = FavoriteColumns & { name: string }

export const favoritesTypes: Record<FavoriteType, FavoriteTypeData> = {
  dispensaries: {
    label: 'Dispensaries',
    keys: ['dispensaryId'],
    trackableCategory: TrackableCategory.dispensaries,
    Icon: DispensaryIcon
  },
  brands: {
    label: 'Brands',
    keys: ['brandId'],
    trackableCategory: TrackableCategory.brands,
    Icon: BrandIcon
  },
  products: {
    label: 'Products',
    keys: ['cmId', 'menuId'],
    trackableCategory: TrackableCategory.products,
    Icon: ProductsIcon
  },
  reports: {
    label: 'Reports',
    keys: ['reportId'],
    trackableCategory: TrackableCategory.reports,
    Icon: ReportIcon
  },
  comparisons: {
    label: 'Comparisons',
    keys: ['comparisonId'],
    trackableCategory: TrackableCategory.comparisons,
    Icon: ComparisonIcon
  }
}

export const favoriteKeys: FavoriteKey[] = Object.values(favoritesTypes)
  .map(({ keys }) => keys)
  .flat()

export enum FavoritesSortField {
  NAME = 'name',
  CREATION_DATE = 'favoritedAt'
}

export const favoriteKeyToName: Record<FavoriteKey, string> = {
  dispensaryId: 'Dispensary',
  brandId: 'Brand',
  cmId: 'Product',
  menuId: 'Product',
  reportId: 'Report',
  comparisonId: 'Comparison'
}
