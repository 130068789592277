import { palette } from '@client/styles/theme/palette'
import { GlobalStyleProps } from '@client/types/styles'
import {
  AccountBalance,
  Announcement,
  BarChart,
  Bookmark,
  BookmarkBorder,
  Dashboard,
  Description,
  Difference,
  FileUploadOutlined,
  FilterAlt,
  FilterAltOutlined,
  Group,
  Home,
  Inventory2,
  Leaderboard,
  LocalOffer,
  LocalShipping,
  LocationOn,
  MapsHomeWork,
  People,
  ProductionQuantityLimits,
  QuestionMark,
  Redeem,
  RemoveShoppingCart,
  SettingsApplications,
  Share,
  ShoppingCart,
  ShoppingCartOutlined,
  Store,
  Tune,
  ViewList,
  Warehouse,
  WorkspacePremium
} from '@mui/icons-material'
import { Badge, Chip, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { lighten } from '@mui/material/styles'
import { TrendingUp, Users } from 'react-feather'
import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { breakpoints } from './theme/breakpoints'
import { fontSizePx } from './theme/typography'

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    background: ${({ theme }) => theme.palette.light};
  }

  html,
  body,
  body > #root {
    height: 100%;
  }

  body {
    min-height: 100%;
    margin: 0;
    font-family: ${({ theme }) => theme.typography.type.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }

  .SnackbarContent-root.SnackbarItem-contentRoot {
    font-size: ${fontSizePx.s2}px;
    font-weight: 600;
    color: #fff;
  }

  /* Mui-X Data Grid fixes */
  .MuiFormControl-root.MuiDataGrid-filterFormDeleteIcon,
  .MuiFormControl-root.MuiDataGrid-filterFormColumnInput,
  .MuiFormControl-root.MuiDataGrid-filterFormLogicOperatorInput,
  .MuiFormControl-root.MuiDataGrid-filterFormOperatorInput {
    width: auto !important;
  }

  .MuiFormControl-root.MuiDataGrid-filterFormValueInput {
    width: auto !important;

    /* stylelint-disable-next-line media-query-no-invalid */
    @media (max-width: ${breakpoints.sm}px) {
      width: 100px !important;
    }
  }

  .MuiDataGrid-cell.border-left,
  .MuiDataGrid-columnHeader.border-left {
    border-left: 1px solid ${({ theme }) => theme.palette.borderGray};
  }
  .MuiDataGrid-cell.border-right,
  .MuiDataGrid-columnHeader.border-right {
    border-right: 1px solid ${({ theme }) => theme.palette.borderGray};
  }

  /* Logi Composer */
  #logi-modal-root,
  #logi-embed-theme-modern,
  .logi-embed,
  .logi-embed .mainWrapper,
  .logi-embed .zd-main-section,
  .logi-embed .dashboard-widgets,
  .logi-embed .zd-grid-layout,
  .logi-embed .zdView-DashboardHeader,
  .logi-embed .dashboard-header {
    background-color: #f3f3f3 !important;
  }
  .logi-embed .unsaved-indicator {
    display: none !important;
  }
  .logi-embed .filters-indicator-button {
    display: none !important;
  }
  .logi-embed .zd-grid-item {
    padding: 4px !important;
  }
  .logi-embed .zdView-Widget {
    padding: 4px !important;
    border: 0 !important;
    border-radius: 6px !important;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%) !important;
  }
  .logi-embed .dashboard-title {
    font-weight: 500 !important;
  }
  .logi-embed .zdView-Dashboard,
  .logi-embed .midPane {
    padding: 0 !important;
    padding-top: 0 !important;
  }
  .logi-embed .bottomPane {
    padding-top: 4px;
  }
  .logi-embed [data-testid='visual-action-list-container'] {
    display: none;
  }
  a[data-testid='more-menu-WIDGET_SETTINGS'],
  a[data-testid='dashboard-favorite-control'],
  button[class*='widget-comments-counter'] {
    display: none !important;
  }
`

export const Layout = styled.main<{ maxWidth?: number | string }>`
  width: 100%;
  max-width: ${({ theme, maxWidth }) =>
    maxWidth ? (typeof maxWidth === 'string' ? maxWidth : `${maxWidth}px`) : `${theme.breakpoints.md}px`};
  height: 100%;
  padding: 18px;
  margin: 0 auto;
`

export const CodeBlock = styled.div`
  font-family: monospace;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`

export const Ellipsis = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const NoWrap = styled.span`
  white-space: nowrap;
`

export const drawerWidth = 270

export const DashboardIcon = Dashboard
export const SalesIcon = AccountBalance
export const SalesEnablementIcon = Tune
export const BrandIcon = LocalOffer
export const IntegrationsIcon = SettingsApplications
export const DispensaryIcon = Store
export const DispensaryAnalyticsIcon = Store
export const WholesaleIcon = LocalShipping
export const PimIcon = Inventory2
export const MarketIntelligenceIcon = ShoppingCartOutlined
export const InventoryIcon = Warehouse
export const OrdersIcon = TrendingUp
export const CustomersIcon = People
export const TerritoryIcon = MapsHomeWork
export const FilterIcon = FilterAltOutlined
export const FilterActiveIcon = FilterAlt
export const ViewIcon = BookmarkBorder
export const ViewActiveIcon = Bookmark
export const WtbAnalyticsIcon = BarChart
export const RelationshipsIcon = Share

export const ComparisonIcon = Difference
export const ReportIcon = Description
export const ProductsIcon = ViewList
export const EventIcon = Announcement
export const BrandLeaderBoardIcon = Leaderboard
export const WhereToBuyIcon = LocationOn
export const UsersIcon = Users
export const AccountsIcons = Group
export const HelpIcon = QuestionMark
export const AccessControlIcon = WorkspacePremium
export const SalesProspectsIcon = Redeem
export const CurrentAccountIcon = Home
export const WinBacksIcon = ProductionQuantityLimits
export const LowStockIcon = ShoppingCart
export const PromotionsIcon = LocalOffer
export const CarriedIcon = Inventory2
export const OutOfStockIcon = RemoveShoppingCart
export const TagsIcon = LocalOffer
export const GlobalSettingsIcon = Tune
export const ImportIcon = FileUploadOutlined

// eslint-disable-next-line @typescript-eslint/ban-types
export type MuiIcon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
  muiName: string
}

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  60% {
    opacity: 0.3;
    transform: scale(1.8);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`

export const PulseBadge = styled(Badge).attrs({
  variant: 'dot'
})`
  & > .MuiBadge-badge {
    &::before {
      position: absolute;
      inset: 0;
      display: block;
      content: '';
      border: 4px double ${({ color, theme }) => lighten(theme.palette[color || 'primary'].main, 0.2)};
      border-radius: 50%;
      animation: ${pulseAnimation} 1s ease infinite;
    }
  }
`

export const IconChip = styled(Chip)`
  .MuiChip-label {
    padding-right: 0;
  }
`

export type ColorProp =
  | 'inherit'
  | 'success'
  | 'disabled'
  | 'action'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'warning'
  | undefined

export const mapColors = {
  carried: palette.carried,
  noMenu: palette.noMenu,
  other: palette.other,
  salesProspects: palette.salesProspects,
  winBacks: palette.winBacks,
  currentAccount: palette.currentAccount,
  lowStock: palette.lowStock,
  promotions: palette.promotions,
  outOfStock: palette.outOfStock
}

export default GlobalStyle
