import { SortAsc } from '@client/components/sort-by/SortBy'
import { useAuthorization } from '@client/hooks/use-authorization'
import { useTagFilterByType } from '@client/hooks/use-tag-filter-by-type'
import { useWholesaleDispensaryOrdersFiltersUrlSync } from '@client/hooks/wholesale/use-wholesale-filters'
import { useSavedViews } from '@client/providers/saved-views'
import {
  DispensariesOptions,
  DispensarySortField,
  KPI_IDS,
  dispensaryLimitForMap
} from '@client/types/dispensary-stats'
import { applyDefaultAlgoliaDispensaryFilters, elasticNotDeliveryOnly } from '@client/types/filterset'
import { TagType } from '@client/types/tags'
import { DraftFilterset, Filters, applySubscriptionFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { BoundingBox, WithKey } from '@lib/types/utils'
import clone from 'fast-clone'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'

export const addBoundingBoxtoFilters = (
  insideBoundingBox: BoundingBox,
  currentFilterset: DraftFilterset,
  dispensaryFilters: string
) => {
  const dispParams = {
    hitsPerPage: dispensaryLimitForMap,
    filters: dispensaryFilters,
    insideBoundingBox: [insideBoundingBox]
  }
  const productFilters: Filters = clone(currentFilterset.filters)
  productFilters.filterBy.geoBoundingBox = {
    top_right: {
      lat: insideBoundingBox[0],
      lon: insideBoundingBox[1]
    },
    bottom_left: {
      lat: insideBoundingBox[2],
      lon: insideBoundingBox[3]
    }
  }
  return { productFilters, dispParams }
}

export const dispensaryOnlyAlgoliaFilters = (
  currentFilterset: DraftFilterset,
  subscriptionFilters?: Filters | null
) => {
  //currently dispensary filters are mainly related to location filters
  const dispensaryFilter = clone(currentFilterset)
  const mergedFilters = applySubscriptionFilterset(dispensaryFilter.filters, subscriptionFilters || undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { brands, categories, segments, subsegments, medical, recreational, ...filtersToApply } = mergedFilters.filterBy
  dispensaryFilter.filters.filterBy = filtersToApply
  return applyDefaultAlgoliaDispensaryFilters(dispensaryFilter.filters)
}

export const applyFiltersWithoutBoundingBox = (currentFilterset: DraftFilterset, dispensaryFilters: string) => {
  const dispParams = {
    hitsPerPage: dispensaryLimitForMap,
    filters: dispensaryFilters
  }
  return dispParams
}

interface MapFiltersProps {
  params: { hitsPerPage: number; filters: string; insideBoundingBox?: BoundingBox[] } | undefined
  productFilter: Filters
  insideBoundingBox: BoundingBox | null
  onSetBoundingBox: (boundingBox: BoundingBox | null) => void
  dispensaryListOptions: DispensariesOptions
  handleSetDispensaryListOptions: (options: WithKey<DispensariesOptions>) => void
}

const MapFiltersContext = createContext<MapFiltersProps>({} as MapFiltersProps)

const MapFiltersProvider: FC<PropsWithChildren> = ({ children }) => {
  const { currentFilterset: originalFilterset } = useSavedViews()
  const { data: authData } = useAuthorization()

  // Get tags and wholesale filters and apply them to the filterset
  const { filteredFilters } = useTagFilterByType(TagType.dispensary, originalFilterset.filters)
  // Overwrite the filters with the wholesale filters if enabled and exists
  const { overwrittenFilters } = useWholesaleDispensaryOrdersFiltersUrlSync(filteredFilters)

  const currentFilterset = useMemo(
    () => ({
      ...originalFilterset,
      filters: {
        ...overwrittenFilters,
        mustNot: {
          ...originalFilterset.filters.mustNot,
          ...elasticNotDeliveryOnly
        }
      }
    }),
    [originalFilterset, overwrittenFilters]
  )

  const dispensaryFilters = useMemo(
    () => dispensaryOnlyAlgoliaFilters(currentFilterset, authData?.subscriptionFilters),
    [currentFilterset, authData?.subscriptionFilters]
  )

  const [dispensaryListOptions, setDispensaryListOptions] = useState<WithKey<DispensariesOptions>>({
    kpi: KPI_IDS.carried,
    sortField: DispensarySortField.DELTA,
    sortOrder: SortAsc,
    from: 0,
    pageSize: 15,
    key: KPI_IDS.carried
  })

  const handleSetDispensaryListOptions = (options: WithKey<DispensariesOptions>) => {
    // const handleSetDispensaryListOptions = (options) => {
    setDispensaryListOptions(options)
  }

  const { dispensaries, states, banners, cities } = currentFilterset.filters.filterBy
  const [params, setParams] = useState<
    { hitsPerPage: number; filters: string; insideBoundingBox?: BoundingBox[] } | undefined
  >()
  const [productFilter, setProductFilter] = useState<Filters>(currentFilterset.filters)
  const [insideBoundingBox, setInsideBoundingBox] = useState<BoundingBox | null>(null)
  const onSetBoundingBox = (boundingBox: BoundingBox | null) => {
    setInsideBoundingBox(boundingBox)
  }

  useEffect(() => {
    //ignores state, city, dispensary name filters when using map bounding box
    if (insideBoundingBox) {
      const { productFilters, dispParams } =
        addBoundingBoxtoFilters(insideBoundingBox, currentFilterset, dispensaryFilters) || {}
      setProductFilter(productFilters)
      setParams(dispParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insideBoundingBox, authData?.subscriptionFilters])

  useEffect(() => {
    // reset bounding box after city, state filter is applied
    if (insideBoundingBox) {
      setInsideBoundingBox(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities, banners, states, dispensaries])

  useEffect(() => {
    //filter params for product and state, city, disp (location based) for dispensary index.
    const dispParams = applyFiltersWithoutBoundingBox(currentFilterset, dispensaryFilters)
    setParams(dispParams)
    setProductFilter(clone(currentFilterset.filters))
  }, [currentFilterset, cities, banners, states, dispensaries, authData?.subscriptionFilters, dispensaryFilters])

  return (
    <MapFiltersContext.Provider
      value={{
        params,
        onSetBoundingBox,
        dispensaryListOptions,
        handleSetDispensaryListOptions,
        productFilter,
        insideBoundingBox
      }}
    >
      {children}
    </MapFiltersContext.Provider>
  )
}

function useMapFilters() {
  const context = useContext(MapFiltersContext)

  if (!context) {
    throw new Error('useMap must be used within an MapProvider.')
  }

  return context
}

export { MapFiltersContext, MapFiltersProvider, useMapFilters }
