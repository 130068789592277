import { User } from '@auth0/auth0-react'
import { DebugPopover, DebugPopoverProps } from '@client/components/debug-popover/DebugPopover'
import { EventArgs } from '@client/configs/tracking'
import { urls } from '@client/helpers/urls'
import { useFeatureToggle } from '@client/hooks/use-feature-toggle'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useUser } from '@client/providers/user'
import { UserData } from '@client/services/hasura'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { AccountSummary } from '@client/types/account'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { AccountBox } from '@mui/icons-material'
import { Divider, IconButton, IconButtonProps, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material'
import React, { memo, useCallback, useMemo } from 'react'
import { LogOut } from 'react-feather'
import { TrackablePopupMenuItem } from '../Trackables'
import * as S from './UserAvatar.style'

export interface UserAvatarProps extends Omit<IconButtonProps, 'onClick'> {
  user?: Partial<Pick<User, 'name' | 'picture' | 'title' | 'sub' | 'email'>>
  userData?: Partial<Pick<UserData, 'fname' | 'lname'>>
  account?: AccountSummary
}

export const UserAvatar: React.FC<UserAvatarProps> = memo(function UserAvatar({
  account,
  user,
  userData,
  size,
  ...rest
}) {
  const initials = `${userData?.fname?.[0] ?? ''}${userData?.lname?.[0] ?? ''}`.trim() || null
  const name = `${userData?.fname ?? ''} ${userData?.lname ?? ''}`.trim() || user?.name
  const avatarClasses = S.useAvatarClasses({ initials })
  const avatarStyles = S.useAvatarStyles()
  const { menuAnchor, handleClick: openMenu, handleClose: closeMenu } = useMenuAnchor()
  const { isEnabled, toggles } = useFeatureToggle()
  const navigate = useNavigateRef()

  const { logout, userToken } = useUser()

  const userProfileEvent: EventArgs = useMemo(
    () => ({
      category: TrackableCategory.navigation,
      action: TrackableAction.userProfileClicked,
      dimension1: user?.email
    }),
    [user?.email]
  )

  const debugPopoverProps: DebugPopoverProps = useMemo(() => {
    const summaryData = {
      userId: user?.sub,
      email: user?.email,
      jwt: userToken
    }
    return {
      title: 'User',
      summaryData,
      detailedData: {
        ...summaryData,
        ...user
      }
    }
  }, [user, userToken])

  const navigateToUserProfile = useCallback(() => {
    navigate.current(urls.userProfile())
    closeMenu()
  }, [closeMenu, navigate])

  return (
    <>
      <IconButton
        data-testid="user-menu--button"
        {...rest}
        onClick={menuAnchor ? () => closeMenu() : openMenu}
        size={size || 'medium'}
      >
        <S.Avatar
          src={user?.picture}
          alt={name}
          classes={avatarClasses}
          className={avatarStyles[size || 'medium']}
          data-testid="avatar"
        >
          {initials}
        </S.Avatar>
      </IconButton>
      <PopupMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={menuAnchor}
        onClose={() => closeMenu()}
        open={!!menuAnchor}
      >
        <ListItem data-testid="menu-item--user-details">
          <ListItemIcon sx={{ pr: 2, minWidth: 'auto' }}>
            <S.Avatar src={user?.picture} alt={name} classes={avatarClasses} className={avatarStyles['small']}>
              {initials}
            </S.Avatar>
          </ListItemIcon>
          <ListItemText
            primary={name || 'Unknown user'}
            secondary={account?.name}
            primaryTypographyProps={{ fontSize: 14, lineHeight: '16px' }}
            secondaryTypographyProps={{ fontSize: 10 }}
            sx={{ pr: 5 }}
          />
          <DebugPopover {...debugPopoverProps} sx={{ ml: 2 }} />
        </ListItem>

        <Divider />

        {isEnabled(toggles.user_profile) && (
          <TrackablePopupMenuItem
            gaEvent={userProfileEvent}
            data-testid="menu-item--user-profile"
            onClick={navigateToUserProfile}
          >
            <ActionIcon>
              <AccountBox />
            </ActionIcon>
            <ListItemText primary="Your Profile" />
          </TrackablePopupMenuItem>
        )}

        <PopupMenuItem data-testid="sign-out-avatar" onClick={logout}>
          <ActionIcon>
            <LogOut size={18} />
          </ActionIcon>
          <ListItemText primary="Sign out" />
        </PopupMenuItem>
      </PopupMenu>
    </>
  )
})

const ActionIcon = styled(ListItemIcon)`
  min-width: 32px !important;
  padding-right: 8px;

  & > * {
    margin: 0 auto;
  }
`
