import { VirtualFilterPillSwitchBoolean } from '@client/components/algolia/filter-pill/filter-pill-switch-boolean'
import { VirtualFilterPillToggleBoolean } from '@client/components/algolia/filter-pill/filter-pill-toggle-boolean'
import { VirtualHierarchicalMenu } from '@client/components/algolia/hierarchical-menu'
import { VirtualRefinementList } from '@client/components/algolia/refinement-list'
import { VirtualSearchBox } from '@client/components/algolia/search-box'
import { slugify } from '@client/helpers/strings'
import { ALGOLIA_INDEX } from '@client/services/algolia'
import { DatabaseFilterMap, FilterOption } from '@client/types/filterset'
import { FilterKeyType } from '@hoodie/hoodie-filters/lib/filterset'
import { Fragment, ReactNode, useCallback } from 'react'

export interface VirtualWidgetsProps {
  invisibleFilters?: FilterOption[]
  index: ALGOLIA_INDEX
}

export const VirtualWidgets: React.FC<VirtualWidgetsProps> = ({ invisibleFilters, index }) => {
  const getFilterComponent = useCallback(
    (filter: FilterOption): NonNullable<ReactNode> => {
      switch (filter?.type) {
        case 'refinement-list':
          return (
            <VirtualRefinementList
              key={`filter-${slugify(filter.attribute)}`}
              attribute={DatabaseFilterMap[filter.filterKey as FilterKeyType<string[]>][index] as string}
            />
          )
        case 'hierarchical-menu':
          return (
            <VirtualHierarchicalMenu
              key={`filter-${slugify(filter.attributes.join('-'))}`}
              attributes={filter.attributes}
            />
          )
        case 'refinement-product-sku-list':
          return (
            <Fragment key="filter-product-skus">
              <VirtualRefinementList key={`filter-cmid`} attribute="CM_ID" />
              <VirtualRefinementList key={`filter-menuid`} attribute="objectID" />
            </Fragment>
          )
        case 'toggle':
          return (
            <div key={`filter-${slugify(filter.type + '-' + filter.label)}`}>
              {filter.attributes.map((attribute, i) => (
                <VirtualFilterPillToggleBoolean
                  key={`filter-${slugify(attribute)}`}
                  attribute={attribute}
                  value={true}
                  label={filter.attributeLabels[i]}
                />
              ))}
            </div>
          )
        case 'switch':
          return (
            <div key={`filter-${slugify(filter.type + '-' + filter.label)}`}>
              <VirtualFilterPillSwitchBoolean attribute={filter.attribute} />
            </div>
          )
        case 'searchbox':
          return <VirtualSearchBox key="filter-searchbox" />
      }
    },
    [index]
  )

  if (!invisibleFilters) {
    return null
  }

  return <>{invisibleFilters.map(getFilterComponent)}</>
}
