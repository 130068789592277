import { FilterChip } from '@client/components/FilterChip/FilterChip'
import { facetToValue } from '@client/helpers/algolia/algolia-helpers'
import { omit } from '@client/helpers/objects'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import { BoxProps, Tooltip } from '@mui/material'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { CurrentRefinementsProvided, Refinement, RefinementValue, SearchState } from 'react-instantsearch-core'
import * as S from './CurrentRefinement.style'

export type CurrentRefinementProps = CurrentRefinementsProvided & {
  attribute?: string | string[]
  maxToDisplay?: number
  onClickMore?: () => void
  boxProps?: BoxProps
  children?: React.ReactNode
  hideSecondaryFilterLabels?: boolean
}

type RefinementItem = {
  attribute: string
  label: string
  value: RefinementValue
  currentRefinement?: string
}

type Refinements = {
  refinements: RefinementItem[]
  productSkus: {
    CM_ID: RefinementItem[]
    objectID: RefinementItem[]
  }
}

export const CurrentRefinement: React.FC<any> = React.memo<CurrentRefinementProps>(function CurrentRefinement({
  attribute,
  maxToDisplay,
  onClickMore,
  boxProps,
  items,
  refine,
  hideSecondaryFilterLabels,
  children
}: CurrentRefinementProps) {
  const { refinements, productSkus } = useMemo(() => {
    return items
      .filter((item: Refinement) => {
        const attrValue = item.id === 'query' ? 'query' : item.attribute
        return !attribute || attrValue === attribute || (Array.isArray(attribute) && attribute.includes(attrValue))
      })
      .reduce(
        (acc, ref) => {
          const refinement = ['CM_ID', 'objectID'].includes(ref.attribute)
            ? acc.productSkus[ref.attribute as keyof typeof acc.productSkus]
            : acc.refinements
          if (ref.items) {
            refinement.push(
              ...ref.items.map((item) => ({
                ...omit(ref, ['items', 'value']),
                attribute: ref.attribute,
                value: item.value || ref.value,
                label: facetToValue(item.label)
              }))
            )
          } else {
            refinement.push({ ...ref, label: facetToValue(ref.label) })
          }
          return acc
        },
        {
          refinements: [],
          productSkus: {
            CM_ID: [],
            objectID: []
          }
        } as Refinements
      )
  }, [attribute, items])

  const { filtered, productSkuCount } = useMemo(() => {
    const productSkuIds = [...productSkus.CM_ID, ...productSkus.objectID]
    if (!productSkuIds.length) {
      return { filtered: refinements, productSkuCount: 0 }
    }
    return {
      filtered: [
        ...refinements,
        {
          attribute: 'CM_ID', // Can be either CM_ID or objectID - just need to set one of them for the filter chip to work with
          label: 'Products',
          value: (searchState: SearchState) => {
            return {
              ...searchState,
              refinementList: {
                ...searchState.refinementList,
                CM_ID: [],
                objectID: []
              }
            }
          },
          currentRefinement: `${productSkuIds.length} ${pluralize('product', productSkuIds.length)}`
        }
      ],
      productSkuCount: productSkuIds.length
    }
  }, [productSkus, refinements])

  const { trimmed, hiddenProductCount } = useMemo(() => {
    if (maxToDisplay && filtered.length > maxToDisplay) {
      return {
        trimmed: filtered.slice(0, maxToDisplay),
        hiddenProductCount: productSkuCount > 0 ? productSkuCount - 1 : 0 // -1 because we're already counting the item as one
      }
    }
    return { trimmed: filtered, hiddenProductCount: 0 }
  }, [filtered, maxToDisplay, productSkuCount])

  const notDisplayedCount = useMemo(() => {
    return filtered.length - trimmed.length + hiddenProductCount
  }, [filtered, trimmed, hiddenProductCount])

  return trimmed.length ? (
    <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" py={1} gap={1} {...boxProps}>
      {trimmed.map(({ attribute, label, value, currentRefinement }) => (
        <FilterChip
          key={`${attribute}-${label}`}
          attribute={attribute}
          label={label}
          currentRefinement={currentRefinement}
          onDelete={() => refine(value)}
          hideSecondaryLabel={hideSecondaryFilterLabels}
        />
      ))}
      {!!notDisplayedCount &&
        (onClickMore ? (
          <Tooltip title="View all filters">
            <S.MoreLink ml={2} onClick={onClickMore} data-testid="current-refinement-more-button">
              <Typography variant="body2">...and {notDisplayedCount} more</Typography>
            </S.MoreLink>
          </Tooltip>
        ) : (
          <Typography variant="body2" color="textSecondary" ml={2} data-testid="current-refinement-more-text">
            {`...and ${notDisplayedCount} more`}
          </Typography>
        ))}
      {children}
    </Box>
  ) : null
})
