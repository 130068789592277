import { useAuth0 } from '@auth0/auth0-react'
import { Sentry } from '@client/configs/sentry'
import { tracking } from '@client/configs/tracking'
import { localStorageUserKey } from '@client/helpers/usersLocalStorage'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useHasura } from '@client/providers/hasura'
import { setToken } from '@client/services/api/api-client'
import type { UserData } from '@client/services/hasura'
import { AccountSummaryWithTier, SubscriptionTier } from '@client/types/account'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { HoodieJwtPayload, appClaimsKey } from '@lib/types/auth'
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SetOptional } from 'type-fest'

interface UserContextData {
  userData: UserData | null
  roles: Role[] | null
  userToken: string | null
  idToken: any | null
  account: SetOptional<AccountSummaryWithTier, 'subscriptionTier'> | null
  logout: () => void
}

const UserContext = createContext<UserContextData>({} as UserContextData)

export const UserRole = 'HoodieWholesaleUser'
export const AdminRole = 'HoodieWholesaleAdmin'
export type Role = 'HoodieWholesaleAdmin' | 'HoodieWholesaleUser'

const userMetaDataClaimsKey = 'https://hoodie.app.com/userMetadata'
const hasuraClaimsKey = 'https://hasura.io/jwt/claims'
const xHasuraRolesKey = 'x-hasura-allowed-roles'

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0()
  const { updateApolloClient } = useHasura()
  const { eventTracker } = useEventTracker({ category: TrackableCategory.account })
  const [userData, setUserData] = useState<UserData | null>(null)
  const [roles, setRoles] = useState<Role[] | null>(null)
  const [idToken, setIdToken] = useState<any | null>(null)

  const userToken: string | null = useMemo(() => {
    setToken(idToken?.__raw)
    return idToken?.__raw ?? null
  }, [idToken])

  const logoutUser = useCallback(() => {
    Sentry.setUser(null)
    eventTracker({ action: TrackableAction.loggedOut })
    tracking.setUser(undefined)
    localStorage.removeItem(localStorageUserKey)
    logout({ returnTo: window.location.origin })
  }, [eventTracker, logout])

  useEffect(() => {
    if (!idToken && isAuthenticated) {
      getIdTokenClaims().then((newIdToken) => {
        if (newIdToken) {
          tracking.setUser(newIdToken.sub, newIdToken.email)
          localStorage.setItem(localStorageUserKey, newIdToken.sub)
          setUserData({
            fname: newIdToken.given_name || '',
            lname: newIdToken.family_name || '',
            phone: user?.[userMetaDataClaimsKey]?.phone_number
          })
          setIdToken(newIdToken)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, getIdTokenClaims, isAuthenticated])

  useEffect(
    // Check for a new token every n seconds, renew it if expired and refresh the idToken.
    function keepAlive() {
      let id: ReturnType<typeof setInterval> | undefined = undefined

      if (isAuthenticated) {
        id = setInterval(async () => {
          try {
            await getAccessTokenSilently()
            const newIdToken = await getIdTokenClaims()
            if (newIdToken && newIdToken.__raw !== idToken.__raw) {
              setIdToken(newIdToken)
              updateApolloClient(newIdToken.__raw)
            }
          } catch (e: any) {
            if (e.error === 'login_required' || e.error === 'consent_required') {
              console.error('Authentication Error', e.error)
              logoutUser()
            }
          }
        }, 5000) // Check for a new token every 5 seconds
      } else if (id) {
        clearInterval(id)
      }

      return () => {
        id && clearInterval(id)
      }
    },
    [getAccessTokenSilently, getIdTokenClaims, isAuthenticated, logout, logoutUser, updateApolloClient, idToken]
  )

  useEffect(() => {
    if (user) {
      setRoles(user[hasuraClaimsKey][xHasuraRolesKey])
    }
  }, [user])

  const account = useMemo(() => {
    const userClaim = user?.[appClaimsKey] as HoodieJwtPayload[typeof appClaimsKey] | undefined
    const acc =
      userClaim?.accountId && userClaim?.accountName
        ? {
            id: userClaim.accountId,
            name: userClaim.accountName,
            subscriptionTier: userClaim.tierId
              ? ({
                  id: userClaim.tierId,
                  code: userClaim.tierCode,
                  name: userClaim.tierName
                } as SubscriptionTier)
              : undefined
          }
        : null
    if (acc) {
      tracking.set({ account: acc.name, tier: acc.subscriptionTier?.code })
    }
    return acc
  }, [user])

  return (
    <UserContext.Provider
      value={{
        userData,
        roles,
        idToken,
        userToken,
        account,
        logout: logoutUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within an UserProvider.')
  }

  return context
}

export { UserContext, UserProvider, useUser }
