import { env } from '@client/env'
import { slugify } from '@client/helpers/strings'
import { useCallback, useMemo } from 'react'
import { useUserFlags } from './use-user-flags'

export const onboardingTourKey = (tourId: string) => `has-viewed-onboarding-tour-${slugify(tourId)}`

export const useOnboardingTours = () => {
  const { isFlagSet, setFlag, isLoading } = useUserFlags()

  const hasTourBeenViewed = useCallback(
    (tourId: string) => !env.REACT_APP_DEBUG_ONBOARDING && isFlagSet(onboardingTourKey(tourId)),
    [isFlagSet]
  )

  const setTourViewed = useCallback(
    (tourId: string) => {
      if (!hasTourBeenViewed(tourId)) {
        setFlag(onboardingTourKey(tourId))
      }
    },
    [hasTourBeenViewed, setFlag]
  )

  return {
    isLoading,
    hasTourBeenViewed,
    setTourViewed
  }
}

export const useOnboardingTour = ({ tourId }: { tourId: string }) => {
  const { isLoading, hasTourBeenViewed, setTourViewed } = useOnboardingTours()

  const hasBeenViewed = useMemo(() => hasTourBeenViewed(tourId), [hasTourBeenViewed, tourId])

  const setViewed = useCallback(() => {
    setTourViewed(tourId)
  }, [setTourViewed, tourId])

  return {
    hasBeenViewed,
    isLoading,
    setViewed
  }
}
