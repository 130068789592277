import { Loader } from '@client/components/loader/Loader'
import { useIntersection } from '@client/hooks/use-intersection'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { BoxProps, Stack } from '@mui/material'
import React, { ReactElement, ReactNode, memo, useCallback, useEffect, useRef } from 'react'
import type { Hit } from 'react-instantsearch-core'
import { InfiniteHitsProvided, connectInfiniteHits } from 'react-instantsearch-core'
import styled from 'styled-components'

export interface ItemHitProps<TItem> {
  item: Hit<TItem>
  index: number
  onSelect?: (item: TItem) => void
}

export interface InfiniteHitsPropsExposed<TItem> {
  HitComponent: React.ComponentType<ItemHitProps<TItem>>
  BoxProps?: BoxProps
  LoadingButtonProps?: LoadingButtonProps
  manualLoadMore?: boolean
  emptyListPlaceholder?: ReactElement<any, any>
  FooterComponent?: ReactNode
  loaderComponent?: ReactElement<any, any>
  hitProps?: any
  hitPropsCallback?: (item: TItem) => Record<string, any>
  disabled?: boolean
}

export interface InfiniteHitsProps<TItem> extends InfiniteHitsPropsExposed<TItem>, InfiniteHitsProvided<Hit<TItem>> {
  searching?: boolean
}

const EndOfList = styled.div`
  /* IntersectionObserver won't work with a zero-height element */
  padding-top: 1px;
`

export const DefaultEmptyListPlaceholder = () => (
  <Box p={4} display="flex" alignItems="center" justifyContent="center">
    <Typography variant="body1" fontStyle="italic">
      No results
    </Typography>
  </Box>
)

export function InfiniteHitsList<TItem>({
  disabled,
  manualLoadMore,
  hasMore,
  refineNext,
  hits,
  hitProps,
  hitPropsCallback,
  searching,
  HitComponent,
  loaderComponent,
  emptyListPlaceholder,
  BoxProps,
  FooterComponent = null,
  LoadingButtonProps
}: InfiniteHitsProps<TItem>) {
  const endOfList = useRef<HTMLDivElement>(null)
  const sentinelEntry = useIntersection(endOfList)

  useEffect(() => {
    if (hasMore && sentinelEntry?.isIntersecting) {
      refineNext()
    }
  }, [hasMore, refineNext, sentinelEntry?.isIntersecting])

  const getHitProps = useCallback(
    (hit: Hit<TItem>) => {
      const p = hitPropsCallback?.(hit) || {}
      if (disabled) {
        p.disabled = true
      }
      return p
    },
    [disabled, hitPropsCallback]
  )

  return (
    <>
      <Box width="100%" data-testid="infinite-hits--list" {...BoxProps}>
        {hits.map((hit, index) => (
          <HitComponent key={hit.objectID} item={hit} index={index} {...hitProps} {...getHitProps(hit)} />
        ))}
        {searching && !hits.length && (loaderComponent || <Loader minHeight="135px" />)}
        {!searching && !hits.length && (emptyListPlaceholder || <DefaultEmptyListPlaceholder />)}
      </Box>

      <Stack direction="row" alignItems="center" flexGrow={1}>
        {manualLoadMore && (
          <LoadingButton
            loadingIndicator="Loading..."
            data-testid="infinite-hits--load-more-button"
            {...LoadingButtonProps}
            onClick={refineNext}
            disabled={!hasMore || disabled}
            loading={searching && !!hits.length}
          >
            Show more
          </LoadingButton>
        )}

        {FooterComponent}
      </Stack>

      {!manualLoadMore && <EndOfList ref={endOfList} aria-label="end-of-list" role="listitem" />}
    </>
  )
}

export const InfiniteHits = connectInfiniteHits(memo(InfiniteHitsList))
