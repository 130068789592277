import type { AppRouter } from '@api/_trpcRouter'
import { env } from '@client/env'
import { createTRPCProxyClient, httpLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'

let token: string
let subscriptionKey: string
let permissionsKey: string
let logiToken: string

export function setToken(newToken: string) {
  token = newToken
}

export function setSubscriptionKey(newSubscriptionKey: string) {
  subscriptionKey = newSubscriptionKey
}

export function setPermissionsKey(newPermissionsKey: string) {
  permissionsKey = newPermissionsKey
}

if (env.REACT_APP_API_DOMAIN !== '') {
  console.warn(`Using ${env.REACT_APP_API_DOMAIN} as the API domain`)
}

export function setLogiToken(newLogiToken: string) {
  logiToken = newLogiToken
}

export const getHeaders = () => {
  const headers: Record<string, string> = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  if (subscriptionKey) {
    headers['X-Subscription-Key'] = subscriptionKey
  }
  if (permissionsKey) {
    headers['X-Permissions-Key'] = permissionsKey
  }
  if (logiToken) {
    headers['X-Logi-Auth'] = logiToken
  }
  return headers
}

const createClientOptions = {
  links: [
    httpLink({
      url: `${env.REACT_APP_API_DOMAIN.replace(/\/$/, '')}/api`,
      headers: getHeaders
    })
  ]
}

export const apiClient = createTRPCProxyClient<AppRouter>(createClientOptions)

export const trpc = createTRPCReact<AppRouter>()
export const trpcClient = trpc.createClient(createClientOptions)
