import { COMPARISON_DRAFT_URL_KEY } from '@client/types/comparison'
import { NEW_URL_KEY as NEW_URL_KEY_RELATIONSHIPS } from '@client/types/relationships'
import { SalesEnablementTabs } from '@client/types/salesEnablement'
import { IMPORT_URL_KEY, NEW_URL_KEY } from '@client/types/tags'

type ProductDetailsInput = {
  CM_ID?: string | null
  objectID: string
}

export const urls = {
  userProfile: (prefix = '/') => {
    return `${prefix}user-profile`
  },
  userDetails: (userId: string, prefix = '/', urlParams = '') => {
    return `${prefix}settings/users/${userId}${urlParams ? `?${urlParams}` : ''}`
  },
  accountDetails: (accountId: string, prefix = '/', urlParams = '') => {
    return `${prefix}settings/accounts/${accountId}${urlParams ? `?${urlParams}` : ''}`
  },
  productDetails: (product: ProductDetailsInput, prefix = '/') => {
    return `${prefix}products/${product.CM_ID || product.objectID}${product.CM_ID ? '?mastered=true' : ''}`
  },
  comparisonPage: (comparisonId?: string, prefix = '/') => {
    return `${prefix}dispensary-analytics/comparison/${comparisonId ?? COMPARISON_DRAFT_URL_KEY}`
  },
  allReportsPage: (prefix = '/') => {
    return `${prefix}reports`
  },
  reportTabPage: (reportId: string, prefix = '/') => {
    return `${urls.allReportsPage(prefix)}?selectedTab=${reportId}&tabs=${reportId}`
  },
  brandPage: (brandId: string, openByName = false, prefix = '/') => {
    return `${prefix}brands/${brandId}${openByName ? `?searchAttr=brandName` : ''}`
  },
  dispensaryPage: (dispensaryId: string, prefix = '/') => {
    return `${prefix}dispensaries/${dispensaryId}`
  },
  relationshipManagerPage: (page?: string, prefix = '/') => {
    return `${prefix}settings/relationships${page ? `/${page}` : ''}`
  },
  relationshipManagerNewRelationshipPage: (prefix = '/') => {
    return urls.relationshipManagerPage(NEW_URL_KEY_RELATIONSHIPS, prefix)
  },
  tagManagerPage: (page?: string, prefix = '/') => {
    return `${prefix}settings/tag-manager${page ? `/${page}` : ''}`
  },
  tagManagerNewTagPage: (prefix = '/') => {
    return urls.tagManagerPage(NEW_URL_KEY, prefix)
  },
  tagManagerImportPage: (prefix = '/') => {
    return urls.tagManagerPage(IMPORT_URL_KEY, prefix)
  },
  salesEnablementTab: (tab?: SalesEnablementTabs, prefix = '/') => {
    return `${prefix}brand/sales-enablement${tab ? `?tab=${tab}` : ''}`
  },
  dispensariesGridPage: (prefix = '/') => {
    return `${urls.salesEnablementTab(SalesEnablementTabs.dispensaries, prefix)}&view=table`
  },

  isImage(url?: string) {
    const baseUrl = url?.split('?')[0]
    return (
      baseUrl?.endsWith('.png') || baseUrl?.endsWith('.jpg') || baseUrl?.endsWith('.jpeg') || baseUrl?.endsWith('.gif')
    )
  }
}
