import { QuickFilterOption } from '@client/components/quick-filters/QuickFilters'
import { SortAsc, SortDesc, SortOrder } from '@client/components/sort-by/SortBy'
import { DispensaryHit } from '@client/services/algolia'
import { PageParams } from '@client/services/open-search-api-service'
import {
  CarriedIcon,
  CurrentAccountIcon,
  LowStockIcon,
  OutOfStockIcon,
  PromotionsIcon,
  SalesProspectsIcon,
  WinBacksIcon,
  mapColors
} from '@client/styles/global'
import { WithKey } from '@lib/types/utils'
import HRN from 'human-readable-numbers'
import pluralize from 'pluralize'
import { WholesaleLastOrder } from './wholesale'

export const KPIs = [
  'salesProspects',
  'currentAccount',
  'lowStock',
  'carried',
  'winBacks',
  'promotions',
  'outOfStock'
] as const
export type KPI = (typeof KPIs)[number]
export const KPI_IDS = Object.fromEntries(KPIs.map((x) => [x, x])) as Record<KPI, KPI>

export enum DispensarySortField {
  KPI = 'KPI',
  MENU_ID = 'MENU_ID',
  VARIANT_ID = 'VARIANT_ID',
  DELTA = 'DELTA',
  DAYS_SINCE_OOS = 'DAYS_SINCE_OOS',
  D_AVG_DAILY_SALES = 'D_AVG_DAILY_SALES'
}

export type DispensariesOptions = {
  sortField: DispensarySortField
  sortOrder: SortOrder
  kpi?: KPI
  from: number
  pageSize: number
}

export type DispensaryListItem = {
  dispensaryId: string
  currentActive: number
  activeDelta: number
  sortField: number
  filterAgg: number
  daysSinceOOS?: number
}

export enum KpiFilterField {
  currentActive = 'currentActive',
  filterAgg = 'filterAgg',
  activeDelta = 'activeDelta'
}

export type KpiOption = {
  suffix: string
  defaultSortField: DispensarySortField
  defaultSortOrder: SortOrder
  filterField: KpiFilterField
  filterTitle: string
  //do not set kpiText on salesProspects and carried,
  //only set if we want to show the kpi/segment metric
  kpiText?: string
  kpi: KPI | undefined
  color: string
} & QuickFilterOption

export type StatText = {
  statValue: string
  text: string
}

export const KPI_METRICS: Record<KPI, WithKey<KpiOption>> = {
  salesProspects: {
    label: 'Sales Prospects',
    suffix: 'sales prospects',
    Icon: SalesProspectsIcon,
    defaultSortField: DispensarySortField.VARIANT_ID,
    defaultSortOrder: SortDesc,
    filterField: KpiFilterField.currentActive,
    filterTitle: 'Total Skus in Store',
    kpiText: '',
    kpi: 'salesProspects',
    key: 'salesProspects',
    color: mapColors.salesProspects
  },
  currentAccount: {
    label: 'Current Account',
    suffix: 'in current account',
    Icon: CurrentAccountIcon,
    defaultSortField: DispensarySortField.DELTA,
    defaultSortOrder: SortAsc,
    filterField: KpiFilterField.activeDelta,
    filterTitle: 'SKU Count Change',
    kpiText: 'SKUs carried in the last 30 days',
    kpi: 'currentAccount',
    key: 'currentAccount',
    color: mapColors.currentAccount
  },
  carried: {
    label: 'Carried',
    suffix: 'Carried SKUs',
    Icon: CarriedIcon,
    defaultSortField: DispensarySortField.VARIANT_ID,
    defaultSortOrder: SortDesc,
    filterField: KpiFilterField.currentActive,
    filterTitle: 'In Stock SKUs',
    kpiText: '',
    kpi: 'carried',
    key: 'carried',
    color: mapColors.carried
  },
  winBacks: {
    label: 'Win Backs',
    suffix: 'win backs',
    Icon: WinBacksIcon,
    defaultSortField: DispensarySortField.KPI,
    defaultSortOrder: SortDesc,
    filterField: KpiFilterField.filterAgg,
    filterTitle: 'Days Since OOS',
    kpiText: 'Days Since OOS',
    kpi: 'winBacks',
    key: 'winBacks',
    color: mapColors.winBacks
  },
  outOfStock: {
    label: 'Out of Stock',
    suffix: 'out of stock',
    Icon: OutOfStockIcon,
    defaultSortField: DispensarySortField.KPI,
    defaultSortOrder: SortDesc,
    filterField: KpiFilterField.filterAgg,
    filterTitle: 'Out of Stock SKUs',
    kpiText: 'Out of Stock SKUs',
    kpi: 'outOfStock',
    key: 'outOfStock',
    color: mapColors.outOfStock
  },
  lowStock: {
    label: 'Low Stock',
    suffix: 'low in stock',
    Icon: LowStockIcon,
    defaultSortField: DispensarySortField.KPI,
    defaultSortOrder: SortDesc,
    filterField: KpiFilterField.filterAgg,
    filterTitle: 'Low Stock SKUs',
    kpiText: 'Low Stock SKUs',
    kpi: 'lowStock',
    key: 'lowStock',
    color: mapColors.lowStock
  },
  promotions: {
    label: 'Promotions',
    suffix: 'on promotion',
    Icon: PromotionsIcon,
    defaultSortField: DispensarySortField.KPI,
    defaultSortOrder: SortDesc,
    filterField: KpiFilterField.filterAgg,
    filterTitle: 'SKUs on promotion',
    kpiText: 'Promoted SKUs',
    kpi: 'promotions',
    key: 'promotions',
    color: mapColors.promotions
  }
}

export const dispensarySortOptions: { label: string; value: DispensarySortField; disabled?: boolean }[] = [
  {
    label: 'SKU count change',
    value: DispensarySortField.DELTA
  },
  {
    label: 'Product count',
    value: DispensarySortField.MENU_ID
  },
  {
    label: 'SKU count',
    value: DispensarySortField.VARIANT_ID
  },
  {
    label: 'Segment metric',
    value: DispensarySortField.KPI
  },
  {
    label: 'Avg days OOS',
    value: DispensarySortField.DAYS_SINCE_OOS
  },
  {
    label: 'Avg sales',
    value: DispensarySortField.D_AVG_DAILY_SALES
  }
]

export const getKpiText = (kpi: KPI, data: DispensaryListItem): StatText | null => {
  if (KPI_METRICS[kpi].kpiText) {
    return {
      statValue: HRN.toHumanString(data.filterAgg.toString()),
      text: `${KPI_METRICS[kpi].kpiText}:`
    }
  } else {
    return null
  }
}

function getKPI(val?: string) {
  return KPIs.indexOf(val as KPI) > -1 ? (val as KPI) : undefined
}

export const initDispensaryOptions = (
  optionsUrlParam:
    | {
        [key: string]: string
      }
    | null
    | undefined,
  defaultPageSize: number
): WithKey<DispensariesOptions> => ({
  sortField: dispensarySortOptions
    .map((o) => o.value)
    .includes((optionsUrlParam?.sortField as DispensarySortField) || '')
    ? (optionsUrlParam?.sortField as DispensarySortField)
    : dispensarySortOptions[0].value,
  sortOrder: (optionsUrlParam?.sortOrder as SortOrder) || SortAsc,
  from: parseInt(optionsUrlParam?.from || '0'),
  pageSize: optionsUrlParam?.pageSize ? parseInt(optionsUrlParam.pageSize) : defaultPageSize,
  kpi: getKPI(optionsUrlParam?.kpi),
  key: optionsUrlParam?.key || optionsUrlParam?.kpi || ''
})

export const getSortText = (sortField: DispensarySortField, data: DispensaryListItem): StatText | null => {
  const sortValue = HRN.toHumanString(data.sortField.toString())
  switch (sortField) {
    case 'MENU_ID':
      return {
        statValue: sortValue,
        text: pluralize('Carried product', data.sortField)
      }
    default:
      return null
  }
}

export type DispensaryItemData = {
  id: string
  kpi?: KPI
  sortField?: DispensarySortField
  data: DispensaryListItem
  latestOrder?: WholesaleLastOrder
  dispensary: DispensaryHit
}

export type DispensaryListData = {
  pageParams: PageParams & { pageResultCount: number }
  page: Array<DispensaryItemData>
  filterField?: KpiFilterField
}

export type ViewOption = 'map' | 'list' | 'table'

export const VIEW: Record<string, ViewOption> = {
  MAP: 'map',
  LIST: 'list',
  TABLE: 'table'
}
export const DEFAULT_DISPENSARY_VIEW = VIEW.TABLE

//set for performance, can increase up to 1000
export const dispensaryLimitForMap = 1000
export const defaultPageSize = 15
export const defaultMobileTablePageSize = 10
export const tablePageSizeOptions = [10, 15, 25, 50]

export const enforcePageSize = (currentPageSize: number, view: ViewOption, isMobile?: boolean): number => {
  let pageSize = defaultPageSize
  if (view === VIEW.MAP) {
    pageSize = dispensaryLimitForMap
  } else if (view === VIEW.TABLE && isMobile) {
    pageSize = defaultMobileTablePageSize
  } else if (view === VIEW.TABLE) {
    pageSize = tablePageSizeOptions.includes(currentPageSize) ? currentPageSize : defaultPageSize
  }
  return pageSize
}
