import { urls } from '@client/helpers/urls'
import { toggles } from '@client/hooks/use-feature-toggle'
import RelationshipManagerPage from '@client/pages/settings/relationship-manager/RelationshipManagerPage'
import { lazy } from '@client/routes/lazy'
import {
  AccountsIcons,
  DispensaryAnalyticsIcon,
  HelpIcon,
  IntegrationsIcon,
  MarketIntelligenceIcon,
  PimIcon,
  RelationshipsIcon,
  ReportIcon,
  SalesEnablementIcon,
  TagsIcon,
  UsersIcon,
  WhereToBuyIcon,
  WholesaleIcon
} from '@client/styles/global'
import { editRelationshipsPermissions } from '@client/types/relationships'
import { RouteChild, Route as RouteType } from '@client/types/routes'
import { SalesEnablementTabs } from '@client/types/salesEnablement'
import { TrackableAction } from '@client/types/tracking'
import { Permission } from '@lib/types/permission'
import { FindInPage, Home, QueryStats } from '@mui/icons-material'

// Dashboards components
const HomePage = lazy(() => import('@client/pages/home/Home'))
const BrandCatalogPage = lazy(() => import('@client/pages/brand-catalog/BrandCatalogPage'))
const WholesalePage = lazy(() => import('@client/pages/wholesale/Wholesale'))
const ReportsPage = lazy(() => import('@client/pages/reports/ReportsPage'))
const AccountsPage = lazy(() => import('@client/pages/settings/accounts/Accounts'))
const DispensaryAnalyticsPage = lazy(
  () => import('@client/pages/dispensary-analytics/DispensaryAnalyticsPage/DispensaryAnalyticsPage')
)

const ActivityLogPage = lazy(() => import('@client/pages/settings/activity-log/ActivityLogPage'))
const IntegrationsPage = lazy(() => import('@client/pages/integrations/Integrations'))
const LocatorsPage = lazy(() => import('@client/pages/where-to-buy/Wtbs'))
const TagManagerPage = lazy(() => import('@client/pages/tag-manager/TagManagerPage'))
const TerritoryBuilderPage = lazy(() => import('@client/pages/territory-builder/TerritoryBuilderPage'))
const UsageMetricsPage = lazy(() => import('@client/pages/settings/usage-metrics/UsageMetricsPage'))
const UsersPage = lazy(() => import('@client/pages/settings/users/Users'))
const VolumeDriverAnalysisPage = lazy(() => import('@client/pages/market-intelligence/VolumeDriverAnalysisPage'))

//APP ROUTES

const overviewRoute: RouteChild = {
  name: 'Overview',
  gaAction: TrackableAction.sidebarSalesEnablementClicked,
  path: urls.salesEnablementTab(),
  component: HomePage
}

export const dispensariesRoute: RouteChild = {
  name: 'Dispensaries',
  gaAction: TrackableAction.sidebarDispensariesClicked,
  path: urls.salesEnablementTab(SalesEnablementTabs.dispensaries),
  component: HomePage
}

export const brandsRoute: RouteChild = {
  name: 'Brands',
  gaAction: TrackableAction.sidebarBrandsClicked,
  path: urls.salesEnablementTab(SalesEnablementTabs.brands),
  component: HomePage
}

const productsRoute: RouteChild = {
  name: 'Products',
  gaAction: TrackableAction.sidebarProductsClicked,
  path: urls.salesEnablementTab(SalesEnablementTabs.products),
  component: HomePage
}

const eventsRoute: RouteChild = {
  name: 'Events',
  gaAction: TrackableAction.sidebarProductsClicked,
  path: '/events',
  component: HomePage
}

const seReportsRoute: RouteChild = {
  name: 'Reports',
  gaAction: TrackableAction.sidebarReportsClicked,
  path: '/brand/reports',
  component: ReportsPage,
  permission: Permission.REPORTS_READ
}

const territoriesRoute: RouteChild = {
  name: 'Territory Builder',
  gaAction: TrackableAction.sidebarTerritoryBuilderClicked,
  path: '/territory-builder',
  component: TerritoryBuilderPage
}

export const salesEnablementChildRoutes = [
  overviewRoute,
  dispensariesRoute,
  brandsRoute,
  productsRoute,
  eventsRoute,
  seReportsRoute,
  territoriesRoute
]

const salesEnablementRoute: RouteType = {
  id: 'Sales Enablement',
  gaAction: TrackableAction.sidebarSalesEnablementClicked,
  path: urls.salesEnablementTab(SalesEnablementTabs.dashboard),
  header: 'Applications',
  icon: <SalesEnablementIcon />,
  component: HomePage,
  permission: Permission.MODULE_ACCESS_SALES_ENABLEMENT,
  children: salesEnablementChildRoutes
}

const brandCatalogRoute: RouteType = {
  id: 'Brand Catalog',
  gaAction: TrackableAction.sidebarBrandCatalogClicked,
  path: '/brand-catalog/catalog',
  permission: Permission.MODULE_ACCESS_PIM,
  featureFlag: toggles.brand_catalog,
  icon: <PimIcon />,
  component: BrandCatalogPage
}

const inventoryRoute: RouteChild = {
  name: 'Inventory',
  gaAction: TrackableAction.sidebarWholesaleInventoryClicked,
  path: '/wholesale?tab=inventory',
  component: WholesalePage
}

const ordersRoute: RouteChild = {
  name: 'Orders',
  gaAction: TrackableAction.sidebarWholesaleOrdersClicked,
  path: '/wholesale?tab=orders',
  component: WholesalePage
}

const customersRoute: RouteChild = {
  name: 'Customers',
  gaAction: TrackableAction.sidebarWholesaleCustomersClicked,
  path: '/wholesale?tab=customers',
  component: WholesalePage
}

const wholesaleReportsRoute: RouteChild = {
  name: 'Reports',
  gaAction: TrackableAction.sidebarWholesaleReportsClicked,
  path: '/wholesale/reports',
  component: ReportsPage,
  permission: Permission.REPORTS_READ
}

export const wholesaleChildRoutes = [inventoryRoute, ordersRoute, customersRoute, wholesaleReportsRoute]

const wholesaleRoute: RouteType = {
  id: 'Wholesale',
  gaAction: TrackableAction.sidebarWholesaleDashboardClicked,
  path: '/wholesale',
  header: 'Applications',
  icon: <WholesaleIcon />,
  component: WholesalePage,
  permission: Permission.MODULE_ACCESS_WHOLESALE,
  children: wholesaleChildRoutes
}

const comparisonsRoute: RouteChild = {
  name: 'Comparisons',
  gaAction: TrackableAction.sidebarDispensaryAnalyticsClicked,
  path: '/dispensary-analytics',
  component: DispensaryAnalyticsPage
}

const dispensaryAnalyticsReportsRoute: RouteChild = {
  name: 'Reports',
  gaAction: TrackableAction.sidebarDAReportsClicked,
  path: '/dispensary-analytics/reports',
  component: ReportsPage,
  permission: Permission.REPORTS_READ
}

const dispensaryAnalyticsChildRoutes = [comparisonsRoute, dispensaryAnalyticsReportsRoute]

const dispensaryAnalyticsRoute: RouteType = {
  id: 'Dispensary Analytics',
  gaAction: TrackableAction.sidebarDispensaryAnalyticsClicked,
  path: '/dispensary-analytics',
  header: 'Applications',
  icon: <DispensaryAnalyticsIcon />,
  component: DispensaryAnalyticsPage,
  permission: Permission.MODULE_ACCESS_DISPENSARY_ANALYTICS,
  children: dispensaryAnalyticsChildRoutes
}

const marketIntelligenceAcvRoute: RouteChild = {
  name: 'Volume Driver Analysis',
  gaAction: TrackableAction.sidebarMIAcvClicked,
  path: '/market-intelligence/volume-driver-analysis',
  component: VolumeDriverAnalysisPage
}

const marketIntelligenceReportsRoute: RouteChild = {
  name: 'Reports',
  gaAction: TrackableAction.sidebarMIReportsClicked,
  path: '/market-intelligence/reports',
  component: ReportsPage,
  permission: Permission.REPORTS_READ
}

const marketIntelligenceChildRoutes = [marketIntelligenceAcvRoute, marketIntelligenceReportsRoute]

const marketIntelligenceRoute: RouteType = {
  id: 'Market Intelligence',
  gaAction: TrackableAction.sidebarMarketIntelligenceClicked,
  path: '/market-intelligence',
  header: 'Applications',
  component: ReportsPage,
  icon: <MarketIntelligenceIcon />,
  permission: Permission.MODULE_ACCESS_MARKET_INTELLIGENCE,
  children: marketIntelligenceChildRoutes
}

const allReportsRoute: RouteType = {
  id: 'All Reports',
  gaAction: TrackableAction.sidebarAllReportsClicked,
  path: '/reports',
  icon: <ReportIcon />,
  component: ReportsPage
}

const wtbRoute: RouteType = {
  id: 'Where To Buy',
  gaAction: TrackableAction.sidebarWhereToBuyClicked,
  path: '/brand/where-to-buy',
  icon: <WhereToBuyIcon />,
  component: LocatorsPage
}

//SETTINGS ROUTES
const accountsRoutes: RouteType = {
  id: 'Accounts',
  gaAction: TrackableAction.sidebarAccountsClicked,
  path: '/settings/accounts',
  header: 'Settings',
  icon: <AccountsIcons />,
  component: AccountsPage,
  permission: Permission.HOODIE_SUPER_ADMIN,
  hideIfNoPermission: true,
  featureFlag: toggles.account_management
}

const teamMembersRoutes: RouteType = {
  id: 'Users',
  gaAction: TrackableAction.sidebarTeamMembersClicked,
  path: '/settings/users',
  icon: <UsersIcon />,
  component: UsersPage,
  permission: Permission.HOODIE_SUPER_ADMIN,
  hideIfNoPermission: true,
  featureFlag: toggles.account_management
}

const tagManagerRoute: RouteType = {
  id: 'Tag Manager',
  gaAction: TrackableAction.sidebarTagManagerClicked,
  path: '/settings/tag-manager',
  header: 'Settings',
  icon: <TagsIcon />,
  component: TagManagerPage,
  onboarding: {
    title: 'New!',
    content:
      'Manage all your tags in one place. You can easily bulk update and share "tags" of interest, such as brands, dispensaries, products and reports, as well as import them from a CSV file and export what\'s tagged.'
  }
}

const relationshipsManagerRoute: RouteType = {
  id: 'Relationships',
  gaAction: TrackableAction.sidebarRelationshipsClicked,
  path: '/settings/relationships',
  header: 'Settings',
  icon: <RelationshipsIcon />,
  component: RelationshipManagerPage,
  featureFlag: toggles.relationship_manager,
  permission: editRelationshipsPermissions,
  hideIfNoPermission: true
}

export const activityLogRoute: RouteType = {
  id: 'Activity Log',
  gaAction: TrackableAction.sidebarActivityLogClicked,
  path: '/settings/activity-log',
  icon: <FindInPage />,
  permission: [Permission.AUDIT_LOG_READ, Permission.HOODIE_SUPER_ADMIN],
  hideIfNoPermission: true,
  component: ActivityLogPage
}

export const integrationsRoute: RouteType = {
  id: 'Integrations',
  gaAction: TrackableAction.sidebarIntegrationsClicked,
  path: '/settings/integrations',
  icon: <IntegrationsIcon />,
  permission: Permission.MODULE_ACCESS_INTEGRATIONS,
  featureFlag: toggles.integrations,
  hideIfNoPermission: true,
  component: IntegrationsPage
}

export const usageMetricsRoute: RouteType = {
  id: 'Usage Metrics',
  gaAction: TrackableAction.sidebarUsageMetricsClicked,
  path: '/settings/usage',
  icon: <QueryStats />,
  permission: Permission.HOODIE_SUPER_ADMIN,
  hideIfNoPermission: true,
  component: UsageMetricsPage
}

//END ACCOUNT ROUTES
export const homeRoute: RouteType = {
  id: 'Home',
  gaAction: TrackableAction.sidebarHomeClicked,
  path: '/',
  icon: <Home />,
  component: null
}

// RESOURCES ROUTES
const userGuideRoute: RouteType = {
  id: 'Knowledge Center',
  gaAction: TrackableAction.sidebarUserGuideClicked,
  path: 'https://docs.hoodieanalytics.com/',
  header: 'Resources',
  icon: <HelpIcon />,
  component: null
}

// GROUP OF ROUTES
export const applicationsRoutes = [
  salesEnablementRoute,
  dispensaryAnalyticsRoute,
  marketIntelligenceRoute,
  brandCatalogRoute,
  wholesaleRoute,
  allReportsRoute,
  wtbRoute
]
export const settingsRoutes = [
  accountsRoutes,
  teamMembersRoutes,
  relationshipsManagerRoute,
  activityLogRoute,
  usageMetricsRoute,
  tagManagerRoute,
  integrationsRoute
]
export const wholesaleRoutes = []
export const resourcesRoutes = [userGuideRoute]
