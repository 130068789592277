import { DialogTitle } from '@client/components/dialog/Dialog'
import { tracking } from '@client/configs/tracking'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useMonitoring } from '@client/providers/monitoring-provider'
import { trpc } from '@client/services/api/api-client'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { FeedbackTopic } from '@lib/types/feedback'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as S from './FeedbackWidget.style'

export const MAX_SUBJECT_LENGTH = 100
export const MAX_MESSAGE_LENGTH = 500

type FeedbackFormText = {
  title: string
  mainText: React.ReactNode
}

type FeedbackTopicOption = {
  label: string
  value: FeedbackTopic
}

const FeedbackTopicOptions: FeedbackTopicOption[] = [
  {
    label: 'a feature request',
    value: FeedbackTopic.featureRequest
  },
  {
    label: 'a bug report/usability issue',
    value: FeedbackTopic.bugReport
  },
  {
    label: 'data quality',
    value: FeedbackTopic.dataIssue
  },
  {
    label: "something I don't understand/need help with",
    value: FeedbackTopic.helpRequest
  },
  {
    label: 'something else',
    value: FeedbackTopic.other
  }
]

const defaultFeedbackFormText: FeedbackFormText = {
  title: 'We Appreciate Your Contact',
  mainText: (
    <Typography variant="body1" mb={4}>
      We are always looking for ways to improve your experience on our platform. Please use this form below if you have
      any issues with the app or if you have any feature requests you would like to see implemented.
    </Typography>
  )
}

const submittedFeedbackFormText: FeedbackFormText = {
  title: 'Thank you for your contact!',
  mainText: (
    <Typography variant="body1" mb={4} component={'span'}>
      We&apos;ll be following up with you shortly. In the meantime, if you need anything else, please don&apos;t
      hesitate to reach out to us at <a href="mailto:insights@hoodieanalytics.com">insights@hoodieanalytics.com.</a>
    </Typography>
  )
}

export interface FeedbackWidgetProps {
  isOpen?: boolean
  onClose: () => void
}

export const FeedbackWidget: React.FC<FeedbackWidgetProps> = memo(function FeedbackWidget({ isOpen = false, onClose }) {
  const { value: isSubmitted, setTrue: setSubmitted, setFalse: resetSubmitted } = useBooleanState(false)
  const [feedbackFormText, setFeedbackFormText] = useState<FeedbackFormText>(defaultFeedbackFormText)
  const { isMobile } = useResponsiveState()
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch
  } = useForm()
  const { monitor } = useMonitoring()
  const mutation = trpc.feedback.submitFeedback.useMutation({
    onError: () => {
      toast.error('There was an error submitting your contact. Please try again.')
    },
    onSuccess: (data, variables) => {
      tracking.trackEvent({
        category: TrackableCategory.feedback,
        action: TrackableAction.submit,
        dimension1: variables.topic
      })
      setSubmitted()
      setFeedbackFormText(submittedFeedbackFormText)
    }
  })

  const { toast } = useToastNotification()

  useEffect(() => {
    if (isOpen) {
      tracking.trackEvent({
        category: TrackableCategory.feedback,
        action: TrackableAction.open
      })
    }
  }, [isOpen])

  const onSubmit = useCallback(
    async ({ topic, subject, message }: { topic: string; subject: string; message: string }) => {
      await mutation.mutate({
        topic: topic as FeedbackTopic,
        subject,
        message,
        sessionURL: monitor?.sessionURL ?? ''
      })
    },
    [monitor?.sessionURL, mutation]
  )

  const handleCancelAndReset = useCallback(() => {
    onClose()
    reset()
    setFeedbackFormText(defaultFeedbackFormText)
    resetSubmitted()
  }, [onClose, reset, resetSubmitted, setFeedbackFormText])

  const messageLength = watch('message')?.length ?? 0

  const messageHelperText = useMemo(() => {
    return (errors.message?.message || `${messageLength}/${MAX_MESSAGE_LENGTH}`) as string
  }, [errors.message, messageLength])

  const subjectHelperText = useMemo(() => {
    return errors.subject?.message as string | undefined
  }, [errors.subject])

  return (
    <Dialog
      open={isOpen}
      onClose={isSubmitted ? handleCancelAndReset : onClose}
      data-testid="feedback-window"
      transitionDuration={0}
    >
      <DialogTitle
        title={<S.FormTitle variant="h2">{feedbackFormText.title}</S.FormTitle>}
        onClose={isSubmitted ? handleCancelAndReset : onClose}
        image={
          <Box padding={2}>
            <img width={isMobile ? '68px' : '80px'} src="assets/img/logo192.png" />
          </Box>
        }
      />
      <DialogContent sx={{ pt: isMobile ? 2 : undefined }}>
        {isSubmitted ? (
          feedbackFormText.mainText
        ) : (
          <>
            <Typography variant="body1" mb={4} component={'span'}>
              {feedbackFormText.mainText}
            </Typography>
            <Controller
              name="topic"
              control={control}
              defaultValue={FeedbackTopic.featureRequest}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <FormControl variant="standard" data-testid="fb-dialog-topic">
                  <InputLabel>I am contacting Hoodie about...</InputLabel>
                  <Select {...field} label="I am contacting Hoodie about...">
                    {FeedbackTopicOptions.map((option: { label: string; value: FeedbackTopic }) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="subject"
              control={control}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: 'Subject is required'
                },
                maxLength: {
                  value: MAX_SUBJECT_LENGTH,
                  message: `Subject must be ${MAX_SUBJECT_LENGTH} characters or less`
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  inputProps={{
                    'data-testid': 'feedback-subject'
                  }}
                  margin="normal"
                  error={!!errors.subject}
                  helperText={subjectHelperText}
                  fullWidth
                  label="Subject"
                  variant="standard"
                />
              )}
            />
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Message is required'
                },
                maxLength: {
                  value: MAX_MESSAGE_LENGTH,
                  message: `Message must be ${MAX_MESSAGE_LENGTH} characters or less`
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  inputProps={{
                    'data-testid': 'feedback-message'
                  }}
                  placeholder="Your message here..."
                  rows={6}
                  multiline
                  error={!!errors.message}
                  helperText={messageHelperText}
                  label="Message"
                  variant="standard"
                />
              )}
            />
          </>
        )}
      </DialogContent>
      {!isSubmitted && (
        <DialogActions>
          <Button data-testid="fb-dialog-cancel-button" onClick={onClose} disabled={mutation.isLoading}>
            Cancel
          </Button>
          <LoadingButton
            data-testid="fb-dialog-submit-button"
            variant="contained"
            color="primary"
            loading={mutation.isLoading}
            onClick={handleSubmit(onSubmit as any)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  )
})

export const FeedbackButton: React.FC = memo(function FeedbackButton() {
  const { value: open, setTrue: openDialog, setFalse: closeDialog } = useBooleanState(false)

  return (
    <>
      <S.FeedbackButtonWrapper onClick={openDialog}>
        <Fade in={!open} unmountOnExit mountOnEnter timeout={300}>
          <S.FeedbackButton data-testid="feedback-button">Feedback</S.FeedbackButton>
        </Fade>
      </S.FeedbackButtonWrapper>
      <FeedbackWidget isOpen={open} onClose={closeDialog} />
    </>
  )
})
