import { useAuth0 } from '@auth0/auth0-react'
import { Monitoring } from '@client/configs/monitoring'
import { FC, PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { useUser } from './user'

interface MonitoringContextInterface {
  monitor?: Monitoring
}

const MonitoringContext = createContext<MonitoringContextInterface>({} as MonitoringContextInterface)

const monitor = new Monitoring()

export const MonitoringProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth0()
  const { roles } = useUser()

  useEffect(() => {
    if (user && roles && !monitor.isInitialized) {
      monitor.init(user, roles)
    }
  }, [user, roles])

  return <MonitoringContext.Provider value={{ monitor }}>{children}</MonitoringContext.Provider>
}

export const useMonitoring = () => {
  const context = useContext(MonitoringContext)

  if (!context) {
    throw new Error('useMonitoring must be used within a MonitoringProvider.')
  }

  return context
}
