import { useAuth0 } from '@auth0/auth0-react'
import { DelimitedTagList } from '@client/components/DelimitedTagList/DelimitedTagList'
import { EditableTitle } from '@client/components/EditableTitle/EditableTitle'
import { FavoriteButton } from '@client/components/FavoriteButton/FavoriteButton'
import { SharedIcon } from '@client/components/SharedIcon/SharedIcon'
import { TrackableListItemButton } from '@client/components/Trackables'
import { ConfirmationDialog } from '@client/components/confirmation-dialog/ConfirmationDialog'
import { MenuItemIcon, MenuItemText } from '@client/components/menu'
import { SummarizedDataText as FilterSummary } from '@client/components/summarized-data-text/SummarizedDataText'
import { EventArgs } from '@client/configs/tracking'
import { upperCaseFirst } from '@client/helpers/strings'
import { urls } from '@client/helpers/urls'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useComparison } from '@client/hooks/use-comparison'
import { useDrawers } from '@client/hooks/use-drawers'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useDrawerState } from '@client/stores/use-drawer-state'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { typography } from '@client/styles/theme/typography'
import { COMPARISON_DRAFT_URL_KEY, Comparison, DraftComparison } from '@client/types/comparison'
import { getFilterSummary } from '@client/types/filterset'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Delete, Edit, Home, MoreVert, People } from '@mui/icons-material'
import { Box, DialogContentText, IconButton, ListItemText, Typography } from '@mui/material'
import { singular } from 'pluralize'
import { MouseEventHandler, memo, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface ComparisonDrawerListItemProps {
  onClick?: () => void
  comparison: DraftComparison
  isActive?: boolean | ((item: DraftComparison) => boolean)
}

export const ComparisonListItem: React.FC<ComparisonDrawerListItemProps> = memo(function ComparisonListItem({
  comparison,
  isActive,
  onClick
}) {
  const active = useMemo(
    () => (typeof isActive === 'function' ? isActive(comparison) : !!isActive),
    [isActive, comparison]
  )

  const gaEvent: EventArgs = {
    category: TrackableCategory.comparisons,
    action: TrackableAction.comparisonSelected,
    dimension1: comparison.id ?? 'draft-comparison'
  }
  const { pathname, search } = useLocation()

  const { validateComparisonName, saveDraftComparison, deleteComparison } = useComparison(comparison.id)
  const { handleClose: closeMenu, handleClick: openMenu, menuAnchor } = useMenuAnchor()
  const { drawer } = useDrawerState()
  const { openShareComparisonDrawer } = useDrawers()
  const [busy, setBusy] = useState(false)
  const { user } = useAuth0()
  const { toast } = useToastNotification()
  const navigate = useNavigateRef()
  const {
    value: confirmingDeleteView,
    setTrue: confirmDelete,
    setFalse: closeDeleteConfirmation
  } = useBooleanState(false)

  const isMyComparison = useMemo(() => !comparison.id || comparison.userId === user?.sub, [user, comparison])
  const filterSummary = useMemo(() => (comparison.filters ? getFilterSummary(comparison.filters) : []), [comparison])

  const handleComparisonSelect = useCallback(
    (event: any) => {
      // Skip if we are just dismissing the context menu
      if (event.target.className.includes('MuiBackdrop-root')) {
        return
      }
      navigate.current(urls.comparisonPage(comparison.id ?? COMPARISON_DRAFT_URL_KEY))
      onClick?.()
    },
    [navigate, comparison.id, onClick]
  )

  const handleEditComparison = useCallback(() => {
    navigate.current(`/dispensary-analytics/${comparison.id ?? COMPARISON_DRAFT_URL_KEY}`)
    closeMenu()
    onClick?.()
  }, [navigate, comparison.id, closeMenu, onClick])

  const handleRenameComparison = useCallback(
    async (name: string) => {
      const newComparison = { ...comparison, name }
      const isValid = await validateComparisonName(newComparison.name, newComparison.id)
      if (isValid) {
        const parsedComparison = await saveDraftComparison(newComparison)
        if (parsedComparison) {
          navigate.current(`${(pathname + (search ?? '')).replace(COMPARISON_DRAFT_URL_KEY, parsedComparison.id)}`, {
            replace: true
          })
        }
      } else {
        toast.error('A comparison with that name already exists, please choose another name.')
      }
    },
    [comparison, validateComparisonName, saveDraftComparison, navigate, pathname, search, toast]
  )

  const handleShareComparison: MouseEventHandler<HTMLLIElement | HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      openShareComparisonDrawer(comparison as Comparison, drawer)
      closeMenu()
    },
    [closeMenu, drawer, openShareComparisonDrawer, comparison]
  )

  const handleDeleteComparison = useCallback(async () => {
    const comparisonToDelete = comparison
    setBusy(true)
    const deleted = await deleteComparison(comparisonToDelete)
    // Navigate to the comparison management page if we are deleting the current comparison
    if (deleted && active) {
      navigate.current('/dispensary-analytics', { replace: true })
    }
    if (!deleted) {
      setBusy(false)
    }
  }, [comparison, active, deleteComparison, navigate])

  const handleRenderBenchmarkIcon = useCallback(
    (id: string) => {
      if (comparison.items.find((item) => item[comparison.context === 'custom' ? 'id' : 'name'] === id)?.benchmark) {
        return <Home sx={{ fontSize: `${typography.size.s2} !important` }} />
      }
      return undefined
    },
    [comparison.context, comparison.items]
  )

  const tags = useMemo(() => comparison.items?.map(({ name }) => name) ?? [], [comparison.items])

  return (
    <>
      <TrackableListItemButton
        data-testid={`comparison-list-item-${comparison.id ?? COMPARISON_DRAFT_URL_KEY}`}
        selected={active}
        key={comparison.id}
        onClick={handleComparisonSelect}
        gaEvent={gaEvent}
        divider
      >
        <ListItemText
          primaryTypographyProps={{
            variant: 'h5',
            component: 'div',
            fontStyle: comparison.id ? 'normal' : 'italic'
          }}
          primary={
            comparison.id ? (
              <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Box data-testid="comparison-name">{comparison.name}</Box>
                  {comparison.subscriptionId && <SharedIcon isMine={isMyComparison} />}
                </Box>
                <FavoriteButton
                  favoriteKey="comparisonId"
                  id={comparison.id}
                  name={comparison.name as string}
                  iconOnly
                  isList
                  sx={{
                    mr: -1.5
                  }}
                />
              </Box>
            ) : (
              <EditableTitle
                title=""
                placeholder="Draft Comparison"
                variant="complete"
                allowEmpty={false}
                props={{
                  sx: {
                    fontSize: 17,
                    flexGrow: 1
                  }
                }}
                onChange={handleRenameComparison}
                BoxProps={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                buttonLabel="Save comparison"
              />
            )
          }
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography fontSize={typography.size.s1} fontWeight={700}>
                    {upperCaseFirst(singular(comparison.context))} comparison
                  </Typography>
                  <DelimitedTagList list={tags} py={1} maxWidth={110} renderIcon={handleRenderBenchmarkIcon} />
                  <FilterSummary summaryData={filterSummary} emptyResultText="" />
                </Box>
                <IconButton edge="end" onClick={openMenu} data-testid="comparison-menu" sx={{ mt: -5 }}>
                  <MoreVert />
                </IconButton>
              </Box>
            </>
          }
        />
      </TrackableListItemButton>
      <>
        <PopupMenu
          id={`saved-comparison-menu-${comparison.id}`}
          anchorEl={menuAnchor}
          onClose={() => closeMenu()}
          open={!!menuAnchor}
        >
          <PopupMenuItem
            onClick={handleEditComparison}
            disabled={!isMyComparison}
            data-testid="popup-menu-item-edit-comparison"
          >
            <MenuItemIcon>
              <Edit fontSize="small" />
            </MenuItemIcon>
            <MenuItemText>{comparison.id ? 'Edit/rename comparison' : 'Edit draft comparison'}</MenuItemText>
          </PopupMenuItem>
          {comparison.id && (
            <PopupMenuItem
              onClick={handleShareComparison}
              disabled={!isMyComparison}
              data-testid="popup-menu-item-share-comparison"
            >
              <MenuItemIcon>
                <People fontSize="small" />
              </MenuItemIcon>
              <MenuItemText>Share/transfer comparison</MenuItemText>
            </PopupMenuItem>
          )}
          <PopupMenuItem
            onClick={confirmDelete}
            disabled={!isMyComparison}
            data-testid="popup-menu-item-delete-comparison"
          >
            <MenuItemIcon>
              <Delete fontSize="small" />
            </MenuItemIcon>
            <MenuItemText>{comparison.id ? 'Delete' : ' Discard draft'} comparison</MenuItemText>
          </PopupMenuItem>
        </PopupMenu>
      </>
      <ConfirmationDialog
        open={confirmingDeleteView}
        title={`${comparison.id ? 'Delete' : 'Discard'} comparison`}
        confirmButtonText={comparison.id ? 'Delete' : 'Discard'}
        loading={busy}
        loadingIndicator={comparison.id ? 'Deleting...' : 'Discarding...'}
        content={
          <DialogContentText>
            Are you sure you want to {comparison.id ? 'delete' : 'discard'}{' '}
            <strong>{comparison.name || 'this draft comparison'}</strong>?
          </DialogContentText>
        }
        onCancel={closeDeleteConfirmation}
        onConfirm={handleDeleteComparison}
      />
    </>
  )
})
