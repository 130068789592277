import { SearchInput } from '@client/components/search-input/SearchInput'
import { facetToValue, valueToFacet } from '@client/helpers/algolia/algolia-helpers'
import { slugify } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { Typography, typography } from '@client/styles/theme/typography'
import { CATEGORY_SEPARATOR } from '@client/types/filterset'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import HRN from 'human-readable-numbers'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import type { RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import { useUpdateEffect } from 'usehooks-ts'
import * as S from './RefinementList.style'

const RefinementListItem: React.FC<{
  isRefined: boolean
  label: string
  count: number
  disableSelection?: boolean
  onClickFacet: (label: string, value: boolean) => void
  isMobile: boolean
}> = memo(function RefinementListItem({ isRefined, label, count, disableSelection, onClickFacet, isMobile }) {
  const formControlLabelClasses = S.useFormControlLabelStyles()
  const [checked, setChecked] = useState(isRefined)

  const parts = useMemo(() => label.split(CATEGORY_SEPARATOR), [label])

  useUpdateEffect(() => {
    setChecked(isRefined)
  }, [isRefined])

  return (
    <FormControlLabel
      classes={formControlLabelClasses}
      sx={{ py: isMobile ? 1 : 0 }}
      control={
        <Checkbox
          sx={{ p: '0 9px' }}
          data-testid={`facet-checkbox-${label.toLowerCase()}`}
          checked={checked}
          disabled={!!disableSelection && !checked}
          color="secondary"
          name={label}
          onClick={() => {
            setChecked(!checked)
            onClickFacet(label, !checked)
          }}
        />
      }
      label={
        <Box flexDirection="column" display="flex">
          <Box alignItems="center" flexDirection="row" display="flex">
            <S.CheckboxLabelText data-testid={`facet-checkbox-label-${slugify(label)}`}>
              {parts[parts.length - 1]}
            </S.CheckboxLabelText>
            <Typography ml={2} variant="caption" color="primaryGreen">
              {HRN.toHumanString(count.toString())}
            </Typography>
          </Box>
          {parts.length > 1 && (
            <Typography
              fontSize={typography.size.xs3}
              noWrap
              color={palette.mediumdark}
              mt={-0.75}
              mb={isMobile ? 0 : 0.25}
            >
              {parts.slice(0, -1).join(CATEGORY_SEPARATOR)}
            </Typography>
          )}
        </Box>
      }
    />
  )
})

export type RefinementListProps = RefinementListExposed & {
  disableSelection?: boolean
  autoFocusSearch?: boolean
  searchDebounceMs?: number
  // This is useful for when the refinement list is inside a popover that needs to recalculate its size when the list changes
  triggerResizeOnChange?: boolean
}

type RefinementListCompleteProps = RefinementListProvided & RefinementListProps

export const RefinementList: React.FC<any> = memo<any>(function RefinementList({
  limit,
  items: inputItems,
  showMore,
  searchable,
  autoFocusSearch,
  searchDebounceMs = 500,
  disableSelection,
  refine,
  searchForItems,
  currentRefinement,
  triggerResizeOnChange = false
}: RefinementListCompleteProps) {
  const items = useMemo(() => {
    const selected = currentRefinement.map((item: string) => facetToValue(item))
    return inputItems.map((item) => {
      return { ...item, isRefined: item.isRefined || selected.includes(item.label) }
    })
  }, [inputItems, currentRefinement])

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { isMobile } = useResponsiveState()
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedItem, setSelectedItem] = useState<[string, boolean]>()
  const { value: showingMore, toggleValue: toggleShowMoreItems } = useBooleanState(false)
  const maxItems = useMemo(() => limit || 10, [limit])
  const itemsToDisplay = useMemo(() => (showingMore ? items : items.slice(0, maxItems)), [showingMore, items, maxItems])

  const excludeSelected = useMemo(() => {
    return !!currentRefinement[0]?.startsWith('-')
  }, [currentRefinement])

  useUpdateEffect(() => {
    if (selectedItem) {
      const newValue = selectedItem[1]
        ? [...currentRefinement, valueToFacet(selectedItem[0], excludeSelected)]
        : currentRefinement.filter((item: string) => facetToValue(item) !== facetToValue(selectedItem[0]))
      refine(newValue)
      setSelectedItem(undefined)
      if (searchable && autoFocusSearch) {
        inputRef.current?.focus()
      }
    }
  }, [selectedItem, excludeSelected])

  useUpdateEffect(() => {
    if (triggerResizeOnChange) {
      window.dispatchEvent(new CustomEvent('resize'))
    }
  }, [itemsToDisplay])

  const onSearch = useCallback(
    (newSearchTerm: string) => {
      setSearchTerm(newSearchTerm)
      searchForItems(newSearchTerm)
    },
    [searchForItems]
  )

  const onClickFacet = useCallback((value: string, checked: boolean) => {
    setSelectedItem([value, checked])
    setSearchTerm('')
  }, [])

  return (
    <div>
      {searchable && (
        <SearchInput
          searchTerm={searchTerm}
          onSearch={onSearch}
          debounceMs={searchDebounceMs}
          margin="dense"
          inputRef={inputRef}
          autoFocus={autoFocusSearch}
        />
      )}
      <FormGroup>
        {itemsToDisplay.length ? (
          itemsToDisplay.map((item) => (
            <RefinementListItem
              isRefined={item.isRefined}
              label={item.label}
              count={item.count}
              onClickFacet={onClickFacet}
              disableSelection={disableSelection}
              isMobile={isMobile}
              key={item.label}
            />
          ))
        ) : (
          <S.NoResults color="textSecondary" p={2}>
            (No options)
          </S.NoResults>
        )}
      </FormGroup>
      {showMore && items.length > maxItems && (
        <Box mt={2}>
          <Button variant="outlined" size="small" aria-label="show-more-facets" onClick={toggleShowMoreItems}>
            Show {showingMore ? 'less' : 'more'}
          </Button>
        </Box>
      )}
    </div>
  )
})
