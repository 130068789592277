import { MultipleQueriesQuery } from '@algolia/client-search'
import { KPI } from '@client/types/dispensary-stats'
import { BoundingBox } from '@lib/types/utils'
import algoliasearch, { SearchClient } from 'algoliasearch'
import { Hit } from 'react-instantsearch-core'

const algoliaApp: any = process.env.REACT_APP_ALGOLIA_APP_ID
const algoliaSearchKey: any = process.env.REACT_APP_ALGOLIA_SEARCH_KEY

export const getSearchClient = (secureApiKey?: string, facetsToGroup?: string[], suppressWarning = false) => {
  // TEMP: if no secure API key is provided, default to using the base search key
  // Once all users have a suscription filterset set, we should require that
  // secureApiKey is set.
  if (!suppressWarning && !secureApiKey && process.env.NODE_ENV !== 'test') {
    console.warn('Algolia: no secure API key for this user. Using base search key instead.')
  }
  const searchClient = algoliasearch(algoliaApp, secureApiKey || algoliaSearchKey)
  if (facetsToGroup) {
    return groupFacetsSearchClient(searchClient, facetsToGroup)
  }
  return searchClient
}

/**
 * Overwrite algolia search client to group facetFilters of the given facetsToGroup, so algolia use OR instead AND
 * Example: Given a facetsToGroup: [CM_ID, objectID], the following facetFilters: [[BRAND:1906], [CM_ID:XX], [objectID:XX]] will be converted into [[BRAND:1906], [CM_ID:XX, objectID:XX]]
 * @param algoliaSearchClient The algolia search client
 * @param facetsToGroup a list of facets that should be grouped
 * @returns The algolia search client that groups the given facetsToGroup
 */
export const groupFacetsSearchClient = (algoliaSearchClient: SearchClient, facetsToGroup: string[]): SearchClient => ({
  ...algoliaSearchClient,
  search: (queries: Readonly<MultipleQueriesQuery[]>, requestOptions?: any) => {
    // There's no point to handle te queries if it does not have enough fields to group
    if (facetsToGroup.length < 2) {
      return algoliaSearchClient.search(queries, requestOptions)
    }
    // Iterate through all algolia queries
    const newQueries: Readonly<MultipleQueriesQuery[]> = queries.map((query: MultipleQueriesQuery) => {
      // In case we don't have an array of facetFilters we can skip it by returning it
      if (!query?.params?.facetFilters?.length || !Array.isArray(query.params.facetFilters[0])) {
        return query
      }
      let groupIndex: number
      // Group all the facetFilters that are presented on facetsToGroup
      const facetFilters =
        typeof query.params.facetFilters === 'string'
          ? query.params.facetFilters
          : ((query.params.facetFilters as Array<string | string[]>).reduce((acc, facet) => {
              const isString = typeof facet === 'string'
              // Handle the specific filters
              if (facetsToGroup.some((field) => (isString ? facet : facet[0]).startsWith(field))) {
                if (groupIndex !== undefined) {
                  const group = acc[groupIndex] as string[]
                  if (isString) {
                    group.push(facet)
                  } else {
                    group.push(...facet)
                  }
                } else {
                  groupIndex = acc.length
                  acc.push(facet)
                }
                // Otherwise just push it
              } else {
                acc.push(facet)
              }
              return acc
            }, [] as Array<string | string[]>) as Readonly<string[]> | Readonly<Readonly<string[]>[]>)
      return { ...query, params: { ...query.params, facetFilters } }
    })
    // Perform the algolia regular search with the new query
    return algoliaSearchClient.search(newQueries, requestOptions)
  }
})

///////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY THESE VALUES! (Unless Algolia changes their limits)
// Algolia does not let us filter on more than 1000 values at a time.
export const MAX_FILTERS = 1000
// Algolia does not let us send more than 50 queries at a time.
export const MAX_QUERIES_PER_REQUEST = 50
///////////////////////////////////////////////////////////////////////////////

export const VARIANT_INDEX = process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX as string
export const DISPENSARY_INDEX = process.env.REACT_APP_ALGOLIA_DISPENSARIES_INDEX as string
export const LOCATIONS_INDEX = process.env.REACT_APP_ALGOLIA_LOCATIONS_INDEX as string
export const BRAND_INDEX = process.env.REACT_APP_ALGOLIA_BRANDS_INDEX as string

export type ALGOLIA_INDEX = typeof VARIANT_INDEX | typeof DISPENSARY_INDEX

export interface SearchOptions {
  filters?: any
  distinct?: any
  clickAnalytics?: boolean
  attributesToRetrieve?: any
  hitsPerPage?: number
  aroundLatLng?: any
  aroundRadius?: number
  insideBoundingBox?: [BoundingBox]
}

export type DispensarySummary = {
  DISPENSARY_CITY: string
  DISPENSARY_ID: string | null
  DISPENSARY_NAME: string
  DISPENSARY_STATE: string
}

export type VariantSummary = {
  QUANTITY: number | null
  UNIT: string
}

export enum StockStatus {
  IN_STOCK = 'In Stock',
  OUT_OF_STOCK = 'Out of Stock',
  LOW_STOCK = 'Low Stock'
}

export type Variant = VariantSummary & {
  ACTUAL_PRICE: number | null
  DAYS_SINCE_OOS: number
  DISCOUNTED_PRICE: number | null
  IS_ACTIVE: boolean
  IS_MEDICAL: boolean
  IS_RECREATIONAL: boolean
  LAST_SEEN_AT: string
  ORIGINAL_PRICE: number | null
  STOCK_STATUS: StockStatus
  VARIANT_ID: string
}

export type Cannabinoids = {
  PERCENT_CBD?: number
  PERCENT_CBG?: number
  PERCENT_CBN?: number
  PERCENT_THC?: number
}

type ProductCore = {
  ACTIVE_VARIANTS: number
  BRAND: string | null
  CANNABINOIDS: Cannabinoids
  CANNABIS_TYPE: string | null
  CATEGORY_0: string
  CATEGORY_1: string
  CATEGORY_2: string
  CM_ID: string | null
  DESCRIPTION: string | null
  IMG: string
  NAME: string
  STRAIN: string | null
  MENU_SLUG: string
  RATING: number
  REVIEWS_COUNT: number | null
  UPDATED_AT: string
  URL: string
  MASTERED_STATUS: 'un-mastered' | 'mastered'
}

export const MasterProductPageProps = [
  'BRAND',
  'CANNABINOIDS',
  'CATEGORY_0',
  'CATEGORY_1',
  'CATEGORY_2',
  'CANNABIS_TYPE',
  'CM_ID',
  'DESCRIPTION',
  'IMG',
  'MENU_SLUG',
  'NAME',
  'RATING',
  'REVIEWS_COUNT',
  'STRAIN',
  'URL',
  'ACTIVE_VARIANTS'
]

export type Product = ProductCore & {
  VARIANTS: Array<Variant>
  ACTIVE_MASTER_VARIANTS: number
  DISPENSARY_COUNT: number
  LAST_SEEN_AT: string
  IN_STOCK: boolean
  ORIGINAL_IMAGE: string
  D_DELIVERY_ENABLED: boolean
  D_HEMP_CBD: string
  D_MEDICAL: boolean
  D_RECREATIONAL: boolean
  D_TAX_INCLUDED: any
  D_COUNTRY: string
  D_STATE: string
  D_CITY: string
  MASTER_D_ID: string
  MASTER_D_NAME: string
  MASTERED_STATUS: string
  __position?: number
  __queryID?: string
  _geoloc: { lat: number; lng: number }
}

export const ProductPageProps = [
  'ACTIVE_VARIANTS',
  'BRAND',
  'CANNABINOIDS',
  'CANNABIS_TYPE',
  'CATEGORY_0',
  'CATEGORY_1',
  'CATEGORY_2',
  'CM_ID',
  'DESCRIPTION',
  'IMG',
  'NAME',
  'STRAIN',
  'MENU_SLUG',
  'RATING',
  'REVIEWS_COUNT',
  'UPDATED_AT',
  'URL',
  'VARIANTS',
  'LAST_SEEN_AT',
  'IN_STOCK',
  'D_DELIVERY_ENABLED',
  'D_HEMP_CBD',
  'D_MEDICAL',
  'D_RECREATIONAL',
  'D_TAX_INCLUDED',
  'D_COUNTRY',
  'D_STATE',
  'D_CITY',
  'MASTER_D_ID',
  'MASTER_D_NAME',
  'MASTERED_STATUS'
]

export type ProductHit = Hit<Product>

export const DispensaryPageProps = [
  'STREET_ADDRESS',
  'FULL_ADDRESS',
  'RATING',
  'COUNTRY_CODE',
  'HEMP_CBD',
  'IS_CLOSED',
  'ATM_PRESENCE',
  'BANNER',
  'CITY',
  'COVER',
  'DELIVERY',
  'DELIVERY_ENABLED',
  'DISPENSARY_NAME',
  'DISPENSARY_ID',
  'EMAIL',
  'AVG_DAILY_SALES',
  'GOOGLEMAPURL',
  'ID_REQUIRED',
  'INSTORE_PICKUP',
  'LAST_UPDATED_AT',
  'LICENSE_NUMBER',
  'LICENSE_TYPE',
  'LOGO',
  'MASTER_D_ID',
  'MEDICAL',
  'MENUS_COUNT',
  'MENUS_COUNT_MED',
  'MENUS_COUNT_REC',
  'PARKING_SQUARE_FOOTAGE',
  'PHONE',
  'POSTAL_CODE',
  'RECREATIONAL',
  'STATE',
  'STATE_SALES_BUCKET',
  'STORE_OPTIONS',
  'STORE_SQUARE_FOOTAGE',
  'TIMEZONE',
  'TYPE',
  'URL',
  'WEBSITE',
  'DISPENSARY_HOURS',
  '_geoloc'
]

export type Dispensary = {
  ADDRESS: string
  ATM_PRESENCE: boolean
  AVG_CUSTOMER_DWELL_TIME?: any
  AVG_WAIT_MESSAGE: string
  BANNER?: string
  CITY: string
  COUNTRY_CODE?: string
  COVER: string
  CURBSIDE_PICKUP: boolean
  DELIVERY: string
  DELIVERY_ENABLED: boolean
  DELIVERY_FEE_AMOUNT: any
  DELIVERY_MINIMUM: any
  DELIVERY_PAYMENT_OPTIONS: string
  DELIVERY_ZIPCODES: any
  DESCRIPTION: string
  DISPENSARY_NAME: string
  DISPENSARY_ID: string
  EMAIL: string
  GOOGLEMAPURL?: string
  GOOGLE_GEOMETRY?: any
  GOOGLE_PLACE_ID?: any
  GOOGLE_RATING?: any
  GOOGLE_RATING_COUNT?: any
  GOOGLE_WEBSITE?: any
  HEMP_CBD?: boolean
  ID_REQUIRED: any
  INSTORE_PICKUP: boolean
  IS_CLOSED: any
  LAT_LONG?: string
  LICENSE_NUMBER: string | null
  LICENSE_TYPE?: string
  LOGO: string
  MASTER_D_ID?: any
  MEDICAL: boolean
  MENUS_COUNT: number
  MENUS_COUNT_MED: number
  MENUS_COUNT_REC: number
  METRO_AREA?: any
  PARKING_SQUARE_FOOTAGE?: any
  PHONE: string
  SLUG: string
  STREET_ADDRESS?: string
  POSTAL_CODE: string
  PREFERRED_SOURCE?: any
  RATING: number
  RECREATIONAL: boolean
  REGION_ID?: any
  RETAIL_TYPE?: string
  REVIEWS_COUNT: any
  SOURCE?: string
  SPRINGBIG_INTEGRATION?: any
  STATE: string
  STORE_OPTIONS: any
  STORE_SQUARE_FOOTAGE?: any
  TAX_INCLUDED: any
  TIMEZONE: string
  TYPE: any
  URL: any
  WEBSITE: string
  WHITE_LABEL_ENABLED?: any
  FULL_ADDRESS: string
  GOOGLE_PHONE_NUMBER?: string
  geometry?: string
  DISPENSARY_HOURS: any
  LAST_UPDATED_AT?: number
  rating?: string
  website?: string
  AVG_DAILY_SALES?: number
  STATE_SALES_BUCKET?: number | null
  __position?: number
  __queryID?: string
  _geoloc?: { lat: number; lng: number }
  _rankingInfo?: {
    nbTypos: number
    firstMatchedWord: number
    proximityDistance: number
    userScore: number
    geoDistance: number
    geoPrecision: number
    filters: number
    matchedGeoLocation: {
      distance: number
      lat: number | null
      lng: number | null
    }
    nbExactWords: number
    words: number
  }
  //TODO remove these fields - refactor elsewhere
  cName?: KPI | 'carried' | 'noMenu' | 'other'
  type?: 'kpi' | 'noMenu' | 'other'
  filteredProductCount?: number
}

export type DispensaryHit = Hit<Dispensary>

export type Brand = {
  BRAND_NAME: string
  BRAND_SLUG: string
  BRAND_URL: string
  PARENT_BRAND: string
  LINKEDIN_URL: string
  PARENT_COMPANY: string
  BRAND_LOGO_URL: string
  BRAND_DESCRIPTION: string
  ACTIVE_VARIANTS: number
}

export type BrandHit = Hit<Brand>

export const BrandPageProps = [
  'BRAND_NAME',
  'BRAND_SLUG',
  'BRAND_URL',
  'ACTIVE_VARIANTS',
  'LINKEDIN_URL',
  'PARENT_COMPANY',
  'PARENT_BRAND',
  'BRAND_LOGO_URL',
  'BRAND_DESCRIPTION'
]
