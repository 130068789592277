import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import React, { ReactElement } from 'react'
import type { Hit } from 'react-instantsearch-core'
import { HitsProvided } from 'react-instantsearch-core'

export interface ItemHitProps<TItem> {
  item: Hit<TItem>
  index: number
}

export interface HitsPropsExposed<TItem> {
  HitComponent: React.ComponentType<ItemHitProps<TItem>>
  emptyListPlaceholder?: ReactElement<any, any>
  hitProps?: any
}

export interface HitsProps<TItem> extends HitsPropsExposed<TItem>, HitsProvided<Hit<TItem>> {}

export const DefaultEmptyListPlaceholder = () => (
  <Box p={4} display="flex" alignItems="center" justifyContent="center">
    <Typography variant="body1" fontStyle="italic">
      No results
    </Typography>
  </Box>
)

export function HitsList<TItem>({ hits, hitProps, HitComponent, emptyListPlaceholder }: HitsProps<TItem>) {
  return (
    <>
      {hits.map((hit, index) => (
        <HitComponent key={hit.objectID} item={hit} index={index} {...hitProps} />
      ))}
      {!hits.length && (emptyListPlaceholder || <DefaultEmptyListPlaceholder />)}
    </>
  )
}
