import { useAuth0 } from '@auth0/auth0-react'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { NotificationAlerts } from '@client/components/notification-alerts'
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { ShareCheckbox } from '@client/components/share-checkbox/ShareCheckbox'
import { SummarizedDataText as FilterSummary } from '@client/components/summarized-data-text/SummarizedDataText'
import { useCustomTerritories } from '@client/hooks/use-custom-territories'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { useSavedViews } from '@client/providers/saved-views'
import { useUser } from '@client/providers/user'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { Typography } from '@client/styles/theme/typography'
import { getFilterSummary } from '@client/types/filterset'
import { DraftFilterset, DraftView, View, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Drawer, IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface SaveViewDrawerContentProps {
  onClose: () => void
}

const SaveViewDrawerContent: React.FC<SaveViewDrawerContentProps> = ({ onClose }) => {
  const { toast } = useToastNotification()
  const { user } = useAuth0()
  const { account } = useUser()
  const { drawer, setDrawer } = useDrawerState()
  const { isTerritory } = useCustomTerritories()
  const filterset = useMemo(() => {
    return (drawer?.state?.filterset || emptyDraftFilterset()) as DraftFilterset
  }, [drawer?.state?.filterset])
  const isCustomTerritory = useMemo(() => isTerritory(filterset as View), [isTerritory, filterset])

  const filterSummary = useMemo(() => filterset && getFilterSummary(filterset), [filterset])

  // If the view is owned by someone else or if it's a territory, disable edits
  const disableEdits = useMemo(
    () => (!!filterset.userId && filterset.userId !== user?.sub) || isCustomTerritory,
    [filterset, user?.sub, isCustomTerritory]
  )

  const [busy, setBusy] = useState(false)
  const { validateDirty, saveDirty } = useNotificationPreferences()
  const { insertSavedView, updateSavedView, setGlobalFilterset, savedViews } = useSavedViews()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: filterset.name || '',
      subscriptionId: filterset.subscriptionId || undefined
    }
  })

  const handleCancel = useCallback(() => {
    if (drawer?.state?.prevDrawer) {
      setDrawer(drawer.state.prevDrawer)
    } else {
      onClose()
    }
  }, [onClose, drawer?.state?.prevDrawer, setDrawer])

  const validateName = (name: string) => {
    return !savedViews.some((v) => v.name === name && v.id !== filterset.id)
  }

  const onSubmit = useCallback(
    async (data: { name: string; subscriptionId?: string }) => {
      if (!filterset) {
        return
      }
      setBusy(true)
      const result = validateDirty(filterset.id)

      if (result) {
        const filtersetToSave: DraftView & { subscriptionId: View['subscriptionId'] } = {
          ...filterset,
          name: data.name,
          subscriptionId: data.subscriptionId
        }
        let savedFilterset = disableEdits ? filterset : null
        if (!disableEdits) {
          if (filterset.id) {
            savedFilterset = await updateSavedView(filtersetToSave as unknown as View)
          } else {
            savedFilterset = await insertSavedView(filtersetToSave)
          }
        }

        if (savedFilterset) {
          setGlobalFilterset(savedFilterset)
          // try saving all dirty alert settings
          await saveDirty((savedFilterset as View).id, !filterset.id)
          toast.success(
            disableEdits ? 'Alert preferences saved' : `${isCustomTerritory ? 'Territory' : 'View'} saved!`,
            { autoHideDuration: 2000 }
          )
          onClose()
        } else {
          toast.error(`Could not save ${isCustomTerritory ? 'territory' : 'view'}`)
        }
      }
      setBusy(false)
    },
    [
      isCustomTerritory,
      filterset,
      validateDirty,
      disableEdits,
      updateSavedView,
      insertSavedView,
      setGlobalFilterset,
      saveDirty,
      toast,
      onClose
    ]
  )

  return (
    <>
      <DrawerHeader>
        <DrawerTitle data-testid="drawer-title">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            {filterset.id ? (disableEdits ? 'Edit Alert Preferences' : 'Edit View') : 'Save View'}
            <IconButton edge="end" onClick={handleCancel} data-testid="close-drawer-button">
              <Close />
            </IconButton>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <Box p={4}>
          <Typography fontWeight="600" variant="h5" gutterBottom>
            Filters
          </Typography>
          <FilterSummary summaryData={filterSummary} />
          <Box mt={4}>
            <Typography fontWeight="600" variant="h5" gutterBottom>
              {isCustomTerritory ? 'Territory' : 'View'} name
            </Typography>
            {!disableEdits && (
              <Typography variant="body2" gutterBottom>
                Give your view a memorable name so you can easily identify it again later.
              </Typography>
            )}
            <Controller
              name="name"
              control={control}
              defaultValue={filterset.name || ''}
              rules={{
                required: true,
                validate: validateName
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  data-testid="view-name-text-field"
                  inputProps={{
                    'data-testid': 'view-name'
                  }}
                  FormHelperTextProps={{
                    id: 'view-name-helper-text'
                  }}
                  margin="normal"
                  disabled={disableEdits || busy}
                  error={!!errors.name}
                  helperText={
                    errors?.name?.type === 'required'
                      ? 'Name is required'
                      : errors?.name
                      ? 'A view with this name already exists'
                      : isCustomTerritory
                      ? 'You can only edit a territory in the Territory Builder page'
                      : disableEdits
                      ? 'You cannot edit this shared view'
                      : null
                  }
                  fullWidth
                  label="Name"
                  variant="outlined"
                />
              )}
            />
            {!!account?.id && !filterset.id && (
              <Controller
                name="subscriptionId"
                control={control}
                render={({ field }) => (
                  <ShareCheckbox
                    isShared={!!field.value}
                    onChange={(_, isChecked) => field.onChange(isChecked ? account.id : null)}
                    accountName={account?.name}
                    label="view"
                  />
                )}
              />
            )}
          </Box>
          <NotificationAlerts filtersetId={filterset.id} busy={busy} compact />
        </Box>
      </DrawerContent>
      <DrawerFooter>
        <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
          <Button variant="text" color="primary" onClick={handleCancel} sx={{ mr: 2 }} data-testid="cancel-button">
            Cancel
          </Button>
          <LoadingButton
            data-testid="save-button"
            variant="contained"
            loading={busy}
            loadingIndicator="Saving..."
            color="primary"
            onClick={handleSubmit(onSubmit as any)}
          >
            Save
          </LoadingButton>
        </Box>
      </DrawerFooter>
    </>
  )
}

export const SaveViewDrawer: React.FC<any> = () => {
  const { drawer, clearDrawer } = useDrawerState()
  const drawerStyles = useDrawerStyles()
  return (
    <Drawer
      open={drawer?.id === DrawerId.saveView}
      anchor="right"
      classes={drawerStyles}
      onClose={clearDrawer}
      data-testid="save-view-drawer"
    >
      <ErrorBoundary>
        <SaveViewDrawerContent onClose={clearDrawer} />
      </ErrorBoundary>
    </Drawer>
  )
}
