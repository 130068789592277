import { slugify } from '@client/helpers/strings'
import { getAttributeLabel, getAttributeValue } from '@client/types/filterset'
import { memo, useMemo } from 'react'
import * as S from './FilterChip.style'

type RefinementDisplay = {
  attribute: string
  label: string
  currentRefinement: string | undefined
  hideSecondaryLabel?: boolean
}

const FilterChipLabel = memo(function FilterChipLabel({
  attribute,
  label,
  currentRefinement,
  hideSecondaryLabel
}: RefinementDisplay) {
  // Convert the attribute to a human readable label
  const primary = useMemo(
    () => getAttributeValue(label, attribute, currentRefinement),
    [attribute, currentRefinement, label]
  )
  const secondary = useMemo(() => getAttributeLabel(attribute), [attribute])

  return (
    <>
      <span data-testid="filter-chip--primary-label">{primary}</span>
      {!hideSecondaryLabel && (
        <S.LabelSecondary data-testid="filter-chip--secondary-label">{secondary}</S.LabelSecondary>
      )}
    </>
  )
})

export interface FilterChipProps {
  attribute: string
  label: string
  currentRefinement?: string
  onDelete: () => void
  hideSecondaryLabel?: boolean
}

export const FilterChip: React.FC<FilterChipProps> = memo<FilterChipProps>(function FilterChip({
  attribute,
  label,
  currentRefinement,
  onDelete,
  hideSecondaryLabel
}) {
  return (
    <S.Chip
      key={`${attribute}-${label}`}
      $hideSecondaryLabel={hideSecondaryLabel}
      size="small"
      color="success"
      label={
        <FilterChipLabel
          attribute={attribute}
          label={label}
          currentRefinement={currentRefinement}
          hideSecondaryLabel={hideSecondaryLabel}
        />
      }
      onDelete={onDelete}
      aria-label={`current-refinement--${slugify(label.toLowerCase())}`}
    />
  )
})
