import { TrackableNavLink } from '@client/components/Trackables'
import { EventArgs } from '@client/configs/tracking'
import { urls } from '@client/helpers/urls'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { ProductHit, VARIANT_INDEX } from '@client/services/algolia'
import { Box } from '@client/styles/theme/box'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Button, Tooltip, Typography } from '@mui/material'
import { memo, useMemo } from 'react'
import { SelectorHitItem } from '../selector-hit-item/SelectorHitItem'
import * as S from './ProductSkuFacet.style'

interface ProductSkuFacetProps {
  disabled?: boolean
  item: ProductHit
  onSelect?: (product: ProductHit) => void
  onRemove?: (product: ProductHit) => void
  selected?: boolean
  isSelectable?: (product: ProductHit) => boolean
}

export const ProductSkuFacet: React.FC<ProductSkuFacetProps> = memo(function ProductSkuFacet({
  disabled,
  item,
  selected,
  onSelect,
  onRemove,
  isSelectable = () => true
}) {
  const { isMobile } = useResponsiveState('sm')

  const gaEvent: EventArgs = useMemo(
    () => ({
      action: TrackableAction.productMasterListItemClicked,
      category: TrackableCategory.navigation,
      dimension1: item.objectID
    }),
    [item.objectID]
  )

  const canBeSelected = !!onSelect && isSelectable(item)

  const itemTitle = useMemo(
    () => (
      <>
        <Typography data-testid="product-sku-item">{item.NAME}</Typography>
        <Box flexShrink={0} display="flex" flexDirection="column" alignItems="flex-end" pr={3}>
          <Box display="flex" gap={2}>
            <Tooltip title={item.CM_ID ? 'Mastered' : 'Not mastered'} placement="top" arrow>
              <S.MasteredIcon mastered={!!item.CM_ID} />
            </Tooltip>
            <TrackableNavLink
              gaEvent={gaEvent}
              to={urls.productDetails(item)}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              <Tooltip title="Open in new tab" placement="top" arrow>
                <S.OpenInNew />
              </Tooltip>
            </TrackableNavLink>
          </Box>
          {!!onRemove && (
            <Button
              onClick={() => onRemove(item)}
              color="error"
              sx={{ p: 1, minWidth: 0 }}
              data-testid="product-sku-remove-button"
            >
              <Typography fontSize={10} fontWeight="bold" color="error">
                Remove
              </Typography>
            </Button>
          )}
        </Box>
      </>
    ),
    [gaEvent, item, onRemove]
  )

  const itemSubtitle = useMemo(
    () => (
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 0 : 2}>
        <Typography fontSize={11} color="mediumdark">
          {item.BRAND}
        </Typography>
        {/* <Typography fontSize={11}>Active SKUs: {item.ACTIVE_VARIANTS}</Typography> */}
      </Box>
    ),
    [isMobile, item.BRAND]
  )

  return (
    <SelectorHitItem
      imageCategory={item.CATEGORY_2}
      imageUrl={item.IMG}
      searchIndex={VARIANT_INDEX}
      onClick={canBeSelected ? () => onSelect?.(item) : undefined}
      itemName={item.NAME}
      disabled={disabled}
      selected={selected}
      title={itemTitle}
      subtitle={itemSubtitle}
    />
  )
})
