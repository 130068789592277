import { ProductChip } from '@client/components/ProductChip/ProductChip'
import { SimpleProductHits } from '@client/components/algolia/hits/ProductHits'
import { useAlgoliaList } from '@client/hooks/use-algolia-list'
import { ProductHit, VARIANT_INDEX } from '@client/services/algolia'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { JoinRule, filtersFromSearchState } from '@client/types/filterset'
import { ProductSkuKey } from '@client/types/products'
import { ArrowForwardIos, Cancel } from '@mui/icons-material'
import { Alert, Chip, CircularProgress, Collapse, Skeleton, Typography } from '@mui/material'
import pluralize from 'pluralize'
import { useCallback, useEffect, useState } from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import * as S from './../../FilterProductSku.style'

export type InvalidProduct = { id: string; key: 'CM_ID' | 'objectID' }

type CurrentProductsRefinementProps = {
  maxToDisplay: number
  total: number
  hideArrow?: boolean
  onRemove?: (value: string, key: ProductSkuKey) => void
  onRemoveInvalid?: (invalidProducts: InvalidProduct[]) => void
  onClickMore: () => void
} & StateResultsProvided

export const CurrentProductsRefinement: React.FC<CurrentProductsRefinementProps> = ({
  isSearchStalled,
  maxToDisplay,
  hideArrow,
  total,
  searchResults,
  searchState,
  onRemove,
  onRemoveInvalid,
  onClickMore
}) => {
  const [invalidProducts, setInvalidProducts] = useState<InvalidProduct[]>([])

  // Fetch all initial selected products from algolia to check which ones are not found
  // This should be fetched only once, and only if the number of found hits are different from the total saved ids
  const { data, isFetching, isInitialLoading } = useAlgoliaList<ProductHit>(
    filtersFromSearchState(searchState),
    VARIANT_INDEX,
    {
      enabled: !isSearchStalled && searchResults?.nbHits !== undefined && searchResults.nbHits !== total && total > 0,
      pageParams: {
        hitsPerPage: total
      },
      attributesToRetrieve: ['CM_ID', 'objectId'],
      distinct: 1
    },
    JoinRule.OR
  )

  const isSearching = isSearchStalled || isFetching || isInitialLoading
  const hiddenProducts = total - invalidProducts.length - maxToDisplay

  // If any product is not found, add it to the invalid products list
  useEffect(() => {
    if (data && searchResults?.nbHits !== undefined && searchResults.nbHits !== total && total > 0) {
      const allIds = [
        ...(searchState.refinementList?.CM_ID ?? []).map((id) => ({ id, key: 'CM_ID' as const })),
        ...(searchState.refinementList?.objectID ?? []).map((id) => ({ id, key: 'objectID' as const }))
      ]
      if (data.length !== allIds.length) {
        setInvalidProducts(
          allIds.filter(({ id, key }) => !data.find((product) => product[key as 'CM_ID' | 'objectID'] === id))
        )
      }
    }
  }, [
    data,
    searchState.refinementList?.CM_ID,
    searchState.refinementList?.objectID,
    invalidProducts.length,
    searchResults?.nbHits,
    total
  ])

  // If the invalid products are removed, remove the invalid products list to hide the alert
  useEffect(() => {
    if (
      invalidProducts.length > 0 &&
      ((searchResults?.nbHits !== undefined && searchResults.nbHits === total) || total === 0)
    ) {
      setInvalidProducts([])
    }
  }, [invalidProducts.length, searchResults?.nbHits, total])

  const handleRemoveInvalidProducts = useCallback(() => {
    onRemoveInvalid?.(invalidProducts)
  }, [invalidProducts, onRemoveInvalid])

  return (
    <Box display="flex" flexWrap="wrap" alignSelf="flex-start" sx={{ width: '100%' }} gap={1}>
      {!hideArrow && (
        <S.Icon className="arrow-icon">
          {isSearching ? <CircularProgress sx={{ color: palette.darkest }} size={18} /> : <ArrowForwardIos />}
        </S.Icon>
      )}
      {isSearching ? (
        [...Array(Math.min(total, maxToDisplay)).keys()].map((i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            width={120}
            height={24}
            sx={{ borderRadius: 2 }}
            data-testid="current-products-refinement--skeleton"
          />
        ))
      ) : (
        <SimpleProductHits HitComponent={ProductChip} hitProps={{ onRemove }} emptyListPlaceholder={<div />} />
      )}
      {!isSearching && hiddenProducts > 0 && (
        <S.MoreLink ml={2} onClick={onClickMore} data-testid="current-products-refinement--more">
          <Typography variant="body2">and {hiddenProducts} more</Typography>
        </S.MoreLink>
      )}
      {invalidProducts.length > 0 && (
        <Chip
          color="error"
          size="small"
          label={`${invalidProducts.length} ${pluralize('product', invalidProducts.length)} not found`}
          onDelete={onRemoveInvalid ? handleRemoveInvalidProducts : undefined}
          data-testid="current-products-refinement--invalid-chip"
          sx={{ m: 0 }}
        />
      )}
      <Collapse in={onRemoveInvalid && invalidProducts.length > 0}>
        <Alert severity="warning" sx={{ mt: 1 }} data-testid="current-products-refinement--invalid-alert">
          <Typography variant="body2">
            You have {invalidProducts.length} {pluralize('product', invalidProducts.length)} that{' '}
            {invalidProducts.length > 1 ? "don't" : "doesn't"} exist anymore. This can affect the results related to
            these filters. We recommend you to remove {invalidProducts.length > 1 ? 'them' : 'it'} by clicking the{' '}
            <Cancel sx={{ fontSize: '13px', transform: 'translateY(2px)' }} /> button on the red chip above, and
            re-save/re-apply this filterset.
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  )
}
