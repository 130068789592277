import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import { PartialRecord } from '@lib/types/utils'
import clone from 'fast-clone'
import { RequireExactlyOne, SetRequired } from 'type-fest'
import { FORCE_NO_RESULTS_FILTER, FilterKeyType, Filters, intersectFiltersList } from './filterset'

export const NEW_URL_KEY = 'new'
export const TAG_IMPORT_DELIMITER = ','
export const IMPORT_URL_KEY = 'import'

export const TAG_COLORS = [
  '#000000',
  '#666666',
  '#4C1130',
  '#513F86',
  '#0A5394',
  '#007680',
  '#37761C',
  '#8D8100',
  '#CD0000',
  '#B90097'
]

export enum TagType {
  brand = 'brand',
  product = 'product',
  dispensary = 'dispensary',
  filterset = 'filterset',
  report = 'report'
}

enum TagTypeProducts {
  masteredProduct = 'masteredProduct',
  unmasteredProduct = 'unmasteredProduct'
}

export type TagContentType = TagType | TagTypeProducts

type TagColumns = RequireExactlyOne<{
  brandId?: string
  cmId?: string
  menuId?: string
  dispensaryId?: string
  filtersetId?: string
  reportId?: string
}>

export type DraftTag = Partial<TagWithItems>

export type ItemTagKey = keyof TagColumns

export type ItemTag = TagColumns & {
  itemTagId?: string
  subscriptionId?: string
  data?: Record<string, unknown>
}

export type RequiredItemTag = SetRequired<ItemTag, 'itemTagId'>

export type TagWithCount = Tag & {
  itemsCount?: {
    dispensary: number
    brand: number
    product: number
    filterset: number
    report: number
  }
}

export type TagWithItems = TagWithCount & {
  items?: ItemTag[]
}

export type ItemTagWithTag = ItemTag & {
  tag: TagWithCount
}

export type ItemTagDb = ReturnType<typeof toDbItemTagFormat> & {
  tag_id: string
  item_tag_id?: string
}

export type TagWithItemsCountResponse = Tag & {
  itemsCount: {
    brands: {
      count: number
    }
    dispensaries: {
      count: number
    }
    reports: {
      count: number
    }
    filtersets: {
      count: number
    }
    masteredProducts: {
      count: number
    }
    nonMasteredProducts: {
      count: number
    }
  }
}

export type ItemTagContent = {
  id: string
  isMastered?: boolean
  label: string
  link: string
  type: TagType
}

export const tagTypeKeys: Record<TagType, ItemTagKey[]> = {
  brand: ['brandId'],
  product: ['cmId', 'menuId'],
  dispensary: ['dispensaryId'],
  filterset: ['filtersetId'],
  report: ['reportId']
}

export const tagTypeKeyToFilterKey: PartialRecord<ItemTagKey, FilterKeyType<string[]>> = {
  brandId: 'brands',
  cmId: 'cmIds',
  menuId: 'menuIds',
  dispensaryId: 'dispensaryIds'
}

export const tagKeyToName: Record<ItemTagKey, string> = {
  brandId: 'Brand',
  cmId: 'Product',
  menuId: 'Product',
  dispensaryId: 'Dispensary',
  filtersetId: 'Filterset',
  reportId: 'Report'
}

export const tagKeys = Object.keys(tagKeyToName) as ItemTagKey[]

const itemTagDbMap: Record<ItemTagKey, string> = {
  brandId: 'brand_id',
  cmId: 'cm_id',
  dispensaryId: 'dispensary_id',
  filtersetId: 'filterset_id',
  menuId: 'menu_id',
  reportId: 'report_id'
}

export const emptyDraftTag = (): DraftTag => ({
  color: TAG_COLORS[0],
  label: ''
})

export const toDbTagFormat = (tag: DraftTag) => {
  return {
    tag_id: tag.id,
    tag_label: tag.label,
    color: tag.color,
    item_tags: tag.items?.map(toDbItemTagFormat) ?? []
  }
}

export const toDbItemTagFormat = (item: ItemTag) => {
  return {
    subscription_id: item.subscriptionId,
    brand_id: item.brandId,
    cm_id: item.cmId,
    menu_id: item.menuId,
    dispensary_id: item.dispensaryId,
    filterset_id: item.filtersetId,
    report_id: item.reportId,
    meta_data: item.data
  }
}

export const itemTagsToInClause = (items: ItemTag[]) => {
  const mappedItems = items.reduce((acc, item) => {
    const key = tagKeys.find((key) => item[key]) as ItemTagKey
    if (acc[key]) {
      acc[key].push(item[key] as string)
    } else {
      acc[key] = [item[key] as string]
    }
    return acc
  }, {} as Record<ItemTagKey, string[]>)

  return Object.entries(mappedItems).map(([key, items]) => ({ [itemTagDbMap[key as ItemTagKey]]: { _in: items } }))
}

export const parseTagWithItemsCount = (tag: TagWithItemsCountResponse): SetRequired<TagWithCount, 'itemsCount'> => {
  const { itemsCount, ...tagWithoutItemsCount } = tag
  return {
    ...tagWithoutItemsCount,
    itemsCount: {
      dispensary: itemsCount.dispensaries.count,
      brand: itemsCount.brands.count,
      product: itemsCount.masteredProducts.count + itemsCount.nonMasteredProducts.count,
      filterset: itemsCount.filtersets.count,
      report: itemsCount.reports.count
    }
  }
}

export const convertItemTagToTag = (itemTag: ItemTagWithTag): TagWithItems => {
  const { tag, ...itemTagWithoutTag } = itemTag
  return {
    ...tag,
    items: [itemTagWithoutTag]
  }
}

export const sortTags = <T extends Tag>(tags: T[], userId: string): T[] => {
  // Sort by: my tags, shared with me tags, and then global tags
  return tags.sort((a, b) => {
    if (a.userId === userId && b.userId !== userId) {
      return -1
    }
    if (a.userId !== userId && b.userId === userId) {
      return 1
    }
    if (a.userId && !b.userId) {
      return -1
    }
    if (!a.userId && b.userId) {
      return 1
    }
    return a.label.localeCompare(b.label)
  })
}

export const getUniqueTags = <T extends TagWithItems>(tags: T[], userId: string): T[] => {
  const sortedTags = sortTags(tags, userId)
  const uniqueTags: T[] = []

  sortedTags.forEach((tag) => {
    const tagExists = uniqueTags.find(({ label }) => label === tag.label)
    // Replace any repeated tag, but preserving the order
    if (!tagExists) {
      uniqueTags.push(tag)
    }
  })

  return uniqueTags
}

export const getItemTagType = (itemTag: ItemTag): TagType => {
  return Object.keys(tagTypeKeys).find((key) => {
    return tagTypeKeys[key as TagType].some((itemTagKey) => itemTag[itemTagKey])
  }) as TagType
}

export const getItemTagContentType = (itemTag: ItemTag): { type: TagContentType; contentId: string } => {
  const type = getItemTagType(itemTag)

  if (type === TagType.product) {
    if (itemTag.cmId) {
      return { type: TagTypeProducts.masteredProduct, contentId: itemTag.cmId }
    } else {
      return { type: TagTypeProducts.unmasteredProduct, contentId: itemTag.menuId as string }
    }
  }

  return { type, contentId: itemTag[tagTypeKeys[type][0]] as string }
}

export const getVisibleTags = (tags: TagWithCount[]): TagWithCount[] => {
  return tags.filter((tag) => !tag.data?.hidden)
}

export const applyTagsOnFilters = (filters: Filters, itemTags: ItemTag[], type: TagType) => {
  // If there are no itemTags to filter on, return the current filters
  if (!itemTags.length) {
    return filters
  }
  // Create a new filters object to avoid mutating the original
  let newFilters = clone(filters)
  tagTypeKeys[type].forEach((itemTagKey) => {
    const list = itemTags.map((itemTag) => itemTag[itemTagKey] as string).filter(Boolean)
    const filterKey = tagTypeKeyToFilterKey[itemTagKey] as FilterKeyType<string[]>
    newFilters = intersectFiltersList(newFilters, list, filterKey)
  })
  // Specific rule for fields that can have multiple fields
  if (tagTypeKeys[type].length > 1) {
    // Check if there are no results for some of the fields
    const hasNoResults = tagTypeKeys[type].filter((itemTagKey) =>
      newFilters.filterBy[tagTypeKeyToFilterKey[itemTagKey] as FilterKeyType<string[]>]?.includes(
        FORCE_NO_RESULTS_FILTER
      )
    )
    // If there are no results for some of the fields, but not all of them, remove the no results filter to not break the OR query
    if (hasNoResults.length > 0 && hasNoResults.length < tagTypeKeys[type].length) {
      // Check if there are any filters for the other fields
      const needsToBeRemoved = tagTypeKeys[type].some((itemTagKey) => {
        const key = tagTypeKeyToFilterKey[itemTagKey] as FilterKeyType<string[]>
        return !newFilters.filterBy[key]?.includes(FORCE_NO_RESULTS_FILTER) && !!newFilters.filterBy[key]?.length
      })
      // If there are filters for the other fields, remove the no results filter
      if (needsToBeRemoved) {
        hasNoResults.forEach((itemTagKey) => {
          newFilters.filterBy[tagTypeKeyToFilterKey[itemTagKey] as FilterKeyType<string[]>] = []
        })
      }
    }
  }
  return newFilters
}
