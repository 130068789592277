import { FilterPill } from '@client/components/algolia/filter-pill/filter-pill/FilterPill'
import { slugify } from '@client/helpers/strings'
import { ToggleRefinementExposed, ToggleRefinementProvided } from '@client/types/connectors'
import HRN from 'human-readable-numbers'
import { memo, useCallback, useEffect, useMemo } from 'react'
import * as S from './FilterPillToggleBoolean.style'

export interface FilterPillToggleBooleanProps extends ToggleRefinementExposed, ToggleRefinementProvided {
  asMenuItem?: boolean
  onClick?: (currentRefinement: boolean, label: string) => void
  onCleared?: (label: string) => void
  onInitialSelection?: (label: string) => void
  isMultiple?: boolean
  selectedFilter: string | null
}

export const FilterPillToggleBoolean: React.FC<FilterPillToggleBooleanProps> = memo<FilterPillToggleBooleanProps>(
  function FilterPillToggleBoolean({
    label,
    count,
    refine,
    currentRefinement,
    asMenuItem,
    onClick,
    onCleared,
    onInitialSelection,
    isMultiple,
    selectedFilter
  }: FilterPillToggleBooleanProps) {
    useEffect(() => {
      if (currentRefinement) {
        onInitialSelection?.(label)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (currentRefinement && selectedFilter && selectedFilter !== label) {
        refine(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter])

    const handleClick = useCallback(() => {
      onClick && onClick(!currentRefinement, label)
      refine(!currentRefinement)
    }, [currentRefinement, label, onClick, refine])

    useEffect(() => {
      if (onCleared && !currentRefinement && selectedFilter === label) {
        onCleared(label)
      }
    }, [currentRefinement, label, onCleared, selectedFilter])

    const countNumber = useMemo(
      () => (isMultiple || !selectedFilter || currentRefinement ? count.unchecked : undefined),
      [currentRefinement, isMultiple, selectedFilter, count]
    )

    return asMenuItem ? (
      <S.MenuItem
        selected={currentRefinement}
        autoFocus={currentRefinement}
        key={label}
        onClick={handleClick}
        data-testid={`toggle-${slugify(label)}`}
      >
        {label}
        {!!countNumber && <S.Chip label={HRN.toHumanString(countNumber.toString())} variant="outlined" size="small" />}
      </S.MenuItem>
    ) : (
      <FilterPill
        onClick={handleClick}
        isActive={!!currentRefinement}
        label={label}
        count={countNumber}
        className="MuiToggleButtonGroup-grouped"
      />
    )
  }
)
