import { Typography as MuiTypography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { spacing } from '@mui/system'
import styled from 'styled-components'

export const NoResults = styled(MuiTypography)`
  ${spacing}
  font-style: italic;
`

export const CheckboxLabelText = styled.div`
  overflow: hidden;
  font-size: ${({ theme }) => theme.typography.size.s2};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const useFormControlLabelStyles = makeStyles(() => ({
  root: {
    /* Stretch to full width of parent (left margin is -11px) */
    maxWidth: 'calc(100% + 11px)',
    marginRight: 0,
    alignItems: 'flex-start'
  },
  label: {
    minWidth: 0
  }
}))
