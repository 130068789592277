import { slugify } from '@client/helpers/strings'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { BoxProps, Typography } from '@mui/material'
import React, { memo } from 'react'

interface SummarizedDataTextProps {
  summaryData?: string[][]
  emptyResultText?: string
  boxProps?: BoxProps
}

export const SummarizedDataText: React.FC<SummarizedDataTextProps> = memo(function SummarizedDataText({
  summaryData,
  emptyResultText = 'No results found',
  boxProps
}) {
  if (!summaryData?.length) {
    return emptyResultText ? (
      <Typography variant="body2" color="textSecondary">
        {emptyResultText}
      </Typography>
    ) : null
  }
  return (
    <Box {...boxProps}>
      {summaryData.map(([key, value]) => (
        <Box key={key} data-testid={`data-summary-${slugify(key + ' ' + value)}`} gap={1} py={0.25}>
          <span style={{ fontWeight: 700, color: palette.purpleBlue }}>{key}:</span> {value}
        </Box>
      ))}
    </Box>
  )
})
