import { Chip as MuiChip, lighten } from '@mui/material'
import styled from 'styled-components'

export const Chip = styled(MuiChip)<{ $hideSecondaryLabel?: boolean }>`
  align-self: flex-start;
  max-width: calc(50% - ${(props) => props.theme.spacing(2)});
  margin: 0;
  color: #fff;
  height: ${({ $hideSecondaryLabel }) => ($hideSecondaryLabel ? '24px' : '30px')};
  background-color: ${(props) => props.theme.palette.primaryGreen};

  & .MuiChip-label {
    line-height: 1.1;
  }
`

export const LabelSecondary = styled.div`
  font-size: 0.6rem;
  color: ${({ theme }) => lighten(theme.palette.success.main, 0.7)};
`
