import { useAlgolia } from '@client/providers/algolia'
import { useOpenSearchApi } from '@client/providers/open-search-api'
import { ALGOLIA_INDEX, MAX_FILTERS, ProductHit, SearchOptions } from '@client/services/algolia'
import { trpc } from '@client/services/api/api-client'
import { DatabaseFilterMap, FilterKeyWithAlternativeKey, filtersToFiltersString } from '@client/types/filterset'
import { Filters } from '@hoodie/hoodie-filters/lib/filterset'
import { ElasticMapping } from '@lib/types/open-search'
import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query'
import { SearchClient } from 'algoliasearch'
import { useMemo } from 'react'

const { useMutation: getDataMatch } = trpc.openSearch.distinctDataMatch
const { useMutation: getProductsMatch } = trpc.openSearch.distinctProductsMatch

export type DistinctComplementData = {
  filterKey: FilterKeyWithAlternativeKey
  index: ALGOLIA_INDEX
  params?: Partial<SearchOptions>
}

type DistinctDataProps<D = keyof ElasticMapping, F = Array<keyof ElasticMapping>> = {
  distinct: D
  fields: F
  filterset?: Filters
  licenseNumbers?: string[]
  size?: number
  dataComplement?: DistinctComplementData
}

type DistinctDataQueryOptions = {
  enabled?: boolean
  keepPreviousData?: boolean
}

export const DISTINCT_DATA_MATCH_QUERY_KEY = 'DISTINCT_DATA_MATCH'
export const DISTINCT_PRODUCTS_MATCH_QUERY_KEY = 'DISTINCT_PRODUCTS_MATCH'

export const getComplementData = async <T = Record<string, any>>({
  dataComplement,
  filterset,
  searchClient
}: {
  dataComplement: Pick<DistinctComplementData, 'index' | 'params'>
  filterset?: Filters
  searchClient: SearchClient
}) => {
  const { index, params } = dataComplement

  const { results } = await searchClient.search<T>([
    {
      indexName: index,
      params: {
        ...params,
        hitsPerPage: MAX_FILTERS,
        filters: filterset && filtersToFiltersString(filterset, index)
      }
    }
  ])

  return results?.[0]?.hits || []
}

export const useDistinctData = <
  Distinct extends keyof ElasticMapping,
  F extends keyof ElasticMapping,
  Fields extends Exclude<F, Distinct>,
  Response = Array<
    { [K in Distinct]: NonNullable<ElasticMapping[Distinct]> } & { [K in Fields]: ElasticMapping[Fields] }
  >
>(
  { dataComplement, distinct, fields, filterset, licenseNumbers, size }: DistinctDataProps<Distinct, Array<F>>,
  options?: DistinctDataQueryOptions
) => {
  const { algoliaSearchClients } = useAlgolia()
  const { latestIndexDate } = useOpenSearchApi()
  const input = useMemo(
    () => ({
      filterset,
      variables: {
        date: { on: latestIndexDate },
        distinct,
        fields,
        licenseNumbers,
        size
      }
    }),
    [distinct, fields, filterset, latestIndexDate, licenseNumbers, size]
  )

  const { mutateAsync } = getDataMatch()
  const { data, ...query } = useQuery(
    [DISTINCT_DATA_MATCH_QUERY_KEY, input],
    async () => {
      const elasticData = await mutateAsync(input)

      if (dataComplement) {
        const { index, filterKey } = dataComplement
        const complementFilters = {
          ...filterset,
          mustNot: {
            ...filterset?.mustNot,
            [filterKey]: elasticData?.map((d) => d?.[distinct]).filter(Boolean) || []
          }
        } as Filters

        const complementData = await getComplementData({
          dataComplement,
          filterset: complementFilters,
          searchClient: algoliaSearchClients[index]
        })

        return [
          ...elasticData,
          ...complementData.map((h) => ({ ...h, [distinct]: h[DatabaseFilterMap[filterKey][index]] }))
        ]
      }

      return elasticData
    },
    options
  )

  return {
    ...query,
    refetch: query.refetch as (options?: RefetchOptions) => Promise<QueryObserverResult<Response>>,
    data: data as Response | undefined,
    queryInput: input
  }
}

export type DistinctProductsProps<F = Array<keyof ElasticMapping>, D = keyof ElasticMapping> = {
  productsNames?: string[]
} & Pick<DistinctDataProps<D, F>, 'fields' | 'filterset' | 'size'> & {
    dataComplement?: Pick<DistinctComplementData, 'params' | 'index'>
  }

export const useDistinctProducts = <
  Fields extends keyof ElasticMapping,
  ResponseData = { [K in Fields]: ElasticMapping[Fields] },
  Response = Array<Omit<ResponseData, 'MENU_ID'> & { MENU_ID: string; CM_ID: string | null }> | undefined
>(
  { dataComplement, fields, filterset, productsNames, size }: DistinctProductsProps<Array<Fields>>,
  options?: DistinctDataQueryOptions
) => {
  const { algoliaSearchClients } = useAlgolia()
  const { latestIndexDate } = useOpenSearchApi()
  const input = useMemo(
    () => ({
      filterset,
      variables: {
        date: { on: latestIndexDate },
        fields: [...fields],
        productsNames,
        size
      }
    }),
    [fields, filterset, latestIndexDate, productsNames, size]
  )

  const { mutateAsync } = getProductsMatch()
  const { data, ...query } = useQuery(
    [DISTINCT_PRODUCTS_MATCH_QUERY_KEY, input],
    async () => {
      const elasticData = await mutateAsync(input)

      if (dataComplement) {
        const { index } = dataComplement
        const complementFilters = {
          ...filterset,
          mustNot: {
            ...filterset?.mustNot,
            cmIds: elasticData?.map((d) => d?.CM_ID).filter(Boolean) || [],
            menuIds: elasticData?.map((d) => d?.MENU_ID).filter(Boolean) || []
          }
        } as Filters

        const complementData = await getComplementData<ProductHit>({
          dataComplement,
          filterset: complementFilters,
          searchClient: algoliaSearchClients[index]
        })

        return [...elasticData, ...complementData.map((h) => ({ ...h, CM_ID: h.CM_ID, MENU_ID: h.objectID }))]
      }

      return elasticData
    },
    options
  )

  return {
    ...query,
    refetch: query.refetch as (options?: RefetchOptions) => Promise<QueryObserverResult<Response>>,
    data: data as Response,
    queryInput: input
  }
}
