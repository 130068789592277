import { ProductSkuFacet } from '@client/components/ProductSkuFacet/ProductSkuFacet'
import { ProductHits } from '@client/components/algolia/hits/ProductHits'
import { SearchBox } from '@client/components/algolia/search-box'
import { MasteredStatusFilter } from '@client/components/edit-filterset-dialog/components/filter-product-sku/components/mastered-status-filter'
import { TabSelectorFooter } from '@client/components/tab-selector-footer/TabSelectorFooter'
import { useDistinctProductsSelectorView } from '@client/hooks/use-distinct-selector-view'
import { useAlgolia } from '@client/providers/algolia'
import { ProductHit, VARIANT_INDEX } from '@client/services/algolia'
import { Box } from '@client/styles/theme/box'
import { MASTERED_STATUS_ATTRIBUTE, filtersToFiltersString, searchStateFromFilters } from '@client/types/filterset'
import { Filters, defaultFilters } from '@hoodie/hoodie-filters/lib/filterset'
import { ErrorOutline } from '@mui/icons-material'
import { SvgIconProps, Typography } from '@mui/material'
import React, { ComponentType, Fragment, memo, useCallback, useState } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useUpdateEffect } from 'usehooks-ts'

const DISPLAY_PER_PAGE = 10
const MASTER_ATTRIBUTES_TO_RETRIEVE = ['NAME', 'IMG', 'ACTIVE_VARIANTS', 'CATEGORY_2', 'BRAND', 'CM_ID', 'objectId']

interface ProductSkuSelectorProps {
  emptyListPlaceholderProps?: {
    color?: SvgIconProps['color']
    message?: string
  }
  filters?: Filters
  hitsPerPage?: number
  maxHeight?: number
  selectedMastered: string[]
  selectedNonMastered?: string[]
  onSelectMastered: (selection: string[], toggledItem: string | string[]) => void
  onSelectNonMastered?: (selection: string[], toggledItem: string | string[]) => void
  ContentWrapper?: ComponentType<React.PropsWithChildren>
  MasterFilterWrapper?: ComponentType<React.PropsWithChildren>
}

const parseFiltersToFilterString = (filters: Filters, omitNonMastered = false) => {
  let string = filtersToFiltersString(filters, VARIANT_INDEX)
  if (omitNonMastered) {
    string += `${string.length ? ' AND ' : ''}${MASTERED_STATUS_ATTRIBUTE}:mastered`
  }
  return string
}

export const ProductSkuSelector: React.FC<ProductSkuSelectorProps> = memo(function ProductSkuSelector({
  emptyListPlaceholderProps,
  filters = defaultFilters,
  hitsPerPage = DISPLAY_PER_PAGE,
  maxHeight,
  ContentWrapper = Fragment,
  MasterFilterWrapper,
  selectedMastered,
  selectedNonMastered = [],
  onSelectMastered,
  onSelectNonMastered
}) {
  const { algoliaSearchClients } = useAlgolia()

  const [searchState, setSearchState] = useState<SearchState>(searchStateFromFilters(filters, VARIANT_INDEX))
  const [filterString, setFilterString] = useState(parseFiltersToFilterString(filters, !onSelectNonMastered))
  const [isLoading, setIsLoading] = useState(false)

  const { selectAll, updateDistinctCache } = useDistinctProductsSelectorView({
    dataComplement: { index: VARIANT_INDEX },
    selectedMastered,
    selectedNonMastered,
    filters
  })

  useUpdateEffect(() => {
    const newFilterString = parseFiltersToFilterString(filters, !onSelectNonMastered)
    // Only update filterString if it has changed
    if (filterString !== newFilterString) {
      setFilterString(newFilterString)
      // Reset search state when filters change
      setSearchState(searchStateFromFilters(filters, VARIANT_INDEX))
    }
  }, [filters, onSelectNonMastered])

  const hitPropsCallback = useCallback(
    (item: ProductHit) => {
      const isMastered = !!item.CM_ID
      return {
        selected: isMastered
          ? selectedMastered.includes(item.CM_ID as string)
          : selectedNonMastered.includes(item.objectID)
      }
    },
    [selectedMastered, selectedNonMastered]
  )

  const handleSelect = useCallback(
    (item: ProductHit) => {
      const { CM_ID, objectID: MENU_ID, NAME } = item
      const cacheItem = { CM_ID, MENU_ID, NAME }

      if (item.CM_ID) {
        const includes = selectedMastered.includes(item.CM_ID)
        const newMastered = includes
          ? selectedMastered.filter((s) => s !== item.CM_ID)
          : [...selectedMastered, item.CM_ID]

        updateDistinctCache(!includes ? [cacheItem] : [], newMastered, selectedNonMastered)
        onSelectMastered(newMastered, item.CM_ID)
      } else if (onSelectNonMastered) {
        const includes = selectedNonMastered.includes(item.objectID)
        const newNonMastered = includes
          ? selectedNonMastered.filter((s) => s !== item.objectID)
          : [...selectedNonMastered, item.objectID]

        updateDistinctCache(!includes ? [cacheItem] : [], selectedMastered, newNonMastered)
        onSelectNonMastered?.(newNonMastered, item.objectID)
      }
    },
    [onSelectMastered, onSelectNonMastered, selectedMastered, selectedNonMastered, updateDistinctCache]
  )

  const handleSelectAll = useCallback(async () => {
    setIsLoading(true)
    const { selectedMastered, selectedNonMastered } = await selectAll().finally(() => setIsLoading(false))
    onSelectMastered(selectedMastered, selectedMastered)
    onSelectNonMastered?.(selectedNonMastered, selectedNonMastered)
  }, [onSelectMastered, onSelectNonMastered, selectAll])

  const handleClearSelection = useCallback(() => {
    onSelectMastered([], selectedMastered)
    onSelectNonMastered?.([], selectedNonMastered)
  }, [onSelectMastered, onSelectNonMastered, selectedMastered, selectedNonMastered])

  const isSelectable = useCallback(
    (prod: ProductHit) => (!prod.CM_ID && !!onSelectNonMastered) || !!prod.CM_ID,
    [onSelectNonMastered]
  )

  return (
    <InstantSearch
      searchState={searchState}
      onSearchStateChange={setSearchState}
      searchClient={algoliaSearchClients[VARIANT_INDEX]}
      indexName={VARIANT_INDEX}
      key="product-sku-selector"
    >
      <Configure
        filters={filterString}
        hitsPerPage={hitsPerPage}
        restrictSearchableAttributes={['NAME']}
        attributesToRetrieve={MASTER_ATTRIBUTES_TO_RETRIEVE}
        distinct={1}
      />
      <ContentWrapper>
        <Box width="100%" flexGrow={1} px={{ xs: 2, md: 4 }}>
          <SearchBox disabled={isLoading} margin="dense" />
        </Box>
        <ProductHits
          BoxProps={{ maxHeight, overflow: 'auto' }}
          LoadingButtonProps={{ sx: { ml: { xs: 1, md: 2 } } }}
          HitComponent={ProductSkuFacet}
          hitProps={{ onSelect: handleSelect, isSelectable }}
          hitPropsCallback={hitPropsCallback}
          emptyListPlaceholder={
            <Box display="flex" gap={4} justifyContent="center" alignItems="center" py={10}>
              <ErrorOutline
                color={
                  !searchState.query && emptyListPlaceholderProps?.color ? emptyListPlaceholderProps?.color : 'warning'
                }
              />
              <Typography variant="caption">
                {!searchState.query && emptyListPlaceholderProps?.message
                  ? emptyListPlaceholderProps?.message
                  : 'No products/SKUs found with the given search term.'}
              </Typography>
            </Box>
          }
          manualLoadMore
          disabled={isLoading}
          FooterComponent={
            <TabSelectorFooter
              onClearSelection={selectedMastered.length + selectedNonMastered.length ? handleClearSelection : undefined}
              onSelectAll={handleSelectAll}
            />
          }
        />
      </ContentWrapper>
      {!!onSelectNonMastered &&
        (MasterFilterWrapper ? (
          <MasterFilterWrapper>
            <MasteredStatusFilter attribute={MASTERED_STATUS_ATTRIBUTE} />
          </MasterFilterWrapper>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} px={2}>
            <MasteredStatusFilter attribute={MASTERED_STATUS_ATTRIBUTE} />
          </Box>
        ))}
    </InstantSearch>
  )
})
