export type Role = {
  id: string
  code: string
  name: string
  description: string | null
  parentId: string | null
}

export type RoleItemResponse = {
  role_id: string
  role_name: string
  role_code: string
  role_description: string | null
  parent_role_id: string | null
}

export type RoleResponse = {
  role: RoleItemResponse[]
}

// Primarily used by the audit log
export type CoreRole = {
  roleId: string
  roleCode: string
  roleName: string
}

export const KnownRoleCodes = {
  SUBSCRIPTION_OWNER: 'SUBSCRIPTION_OWNER'
} as const

export type KnownRoleCode = (typeof KnownRoleCodes)[keyof typeof KnownRoleCodes]
