import { useAuthorization } from '@client/hooks/use-authorization'
import { useItemTagsByType } from '@client/hooks/use-item-tags-by-type'
import { usePermission } from '@client/hooks/use-permissions'
import { trpc } from '@client/services/api/api-client'
import { LogiDashboardWithTags } from '@client/types/logi-composer'
import { TagType, convertItemTagToTag } from '@client/types/tags'
import { Permission } from '@lib/types/permission'
import { useMemo } from 'react'

const { useQuery: useDashboards } = trpc.reports.getDashboards

export const useReportsDashboards = (enabled = true) => {
  const { data } = useAuthorization()
  const { hasPermission } = usePermission(Permission.REPORTS_READ)

  const query = useDashboards(undefined, {
    enabled: enabled && hasPermission && !!data?.logiComposer?.access_token
  })

  const { itemTags: tags, isLoading: isLoadingTags } = useItemTagsByType(
    TagType.report,
    query.data?.map(({ id }) => id) ?? []
  )

  // Associate tags to dashboards
  const dashboards: LogiDashboardWithTags[] | undefined = useMemo(() => {
    if (!hasPermission || query.data === undefined || tags === undefined) {
      return undefined
    }
    if (!query.data || !tags?.length) {
      return query.data
    }
    return query.data.map((dashboard) => ({
      ...dashboard,
      tags: tags?.filter(({ reportId }) => reportId === dashboard.id).map(convertItemTagToTag) ?? []
    }))
  }, [hasPermission, query.data, tags])

  const reportsMap = useMemo(() => {
    return dashboards?.reduce((acc, dashboard) => {
      acc[dashboard.id] = dashboard
      return acc
    }, {} as Record<string, LogiDashboardWithTags>)
  }, [dashboards])

  return {
    ...query,
    isLoading: isLoadingTags || query.isLoading,
    data: dashboards,
    reportsMap
  }
}

export const useReportsDashboard = (dashboardId: string) => {
  const { data } = useReportsDashboards()
  return useMemo(() => data?.find((dashboard) => dashboard.id === dashboardId), [data, dashboardId])
}
