import { useAuth0 } from '@auth0/auth0-react'
import { View, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { Delete, Edit, MoreVert, Notifications, People } from '@mui/icons-material'
import { Alert, AlertTitle, Box, DialogContentText, IconButton, ListItemButton, ListItemText } from '@mui/material'
import { MouseEventHandler, memo, useCallback, useMemo, useState } from 'react'
import { SharedIcon } from '@client/components/SharedIcon/SharedIcon'
import { ConfirmationDialog } from '@client/components/confirmation-dialog/ConfirmationDialog'
import { MenuItemIcon, MenuItemText } from '@client/components/menu'
import { SummarizedDataText as FilterSummary } from '@client/components/summarized-data-text/SummarizedDataText'
import { TagList } from '@client/components/tags/TagList/TagList'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useCustomTerritories } from '@client/hooks/use-custom-territories'
import { useDrawers } from '@client/hooks/use-drawers'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { useSavedViews } from '@client/providers/saved-views'
import { useDrawerState } from '@client/stores/use-drawer-state'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { getFilterSummary } from '@client/types/filterset'

export interface ViewListItemProps {
  onClick: () => void
  view: View
  isActive?: boolean
}

export const ViewListItemContent: React.FC<ViewListItemProps & { hasNotifications?: boolean }> = memo(
  function ViewListItemContent({ view, isActive, hasNotifications = false, onClick }) {
    const { user } = useAuth0()
    const { toast } = useToastNotification()
    const { drawer, clearDrawer } = useDrawerState()
    const navigate = useNavigateRef()
    const { isTerritory } = useCustomTerritories()
    const { openSaveViewDrawer, openShareViewDrawer } = useDrawers()
    const { setGlobalFilterset, deleteSavedView } = useSavedViews()
    const { deleteAlertsByFiltersetID } = useNotificationPreferences()
    const { handleClose: closeMenu, handleClick: openMenu, menuAnchor } = useMenuAnchor()
    const [busy, setBusy] = useState(false)
    const {
      value: confirmingDeleteView,
      setTrue: confirmDelete,
      setFalse: closeDeleteConfirmation
    } = useBooleanState(false)

    const isMyView = useMemo(() => view.userId === user?.sub, [user, view])
    const isCustomTerritory = useMemo(() => isTerritory(view), [isTerritory, view])

    const filterSummary = useMemo(() => getFilterSummary(view), [view])

    const handleSavedViewClick = useCallback(
      (event: any, view: View) => {
        // Skip if we are just dismissing the context menu
        if (event.target.className.includes('MuiBackdrop-root')) {
          return
        }
        setGlobalFilterset(isActive ? emptyDraftFilterset() : view)
        onClick()
        if (isActive) {
          toast.success('Cleared view selection', { autoHideDuration: 2000 })
        } else {
          toast.success(`Selected view: ${view.name}`, { autoHideDuration: 2000 })
        }
      },
      [isActive, onClick, setGlobalFilterset, toast]
    )

    const handleEditView: MouseEventHandler<HTMLLIElement | HTMLButtonElement> = useCallback(
      (event) => {
        event.stopPropagation()
        event.preventDefault()
        openSaveViewDrawer(view, drawer)
        closeMenu()
      },
      [closeMenu, drawer, openSaveViewDrawer, view]
    )

    const handleEditTerritory: MouseEventHandler<HTMLLIElement | HTMLButtonElement> = useCallback(
      (event) => {
        event.stopPropagation()
        event.preventDefault()
        navigate.current(`/territory-builder/${view.id}`)
        clearDrawer()
        closeMenu()
      },
      [navigate, view.id, clearDrawer, closeMenu]
    )

    const handleShareView: MouseEventHandler<HTMLLIElement | HTMLButtonElement> = useCallback(
      (event) => {
        event.stopPropagation()
        event.preventDefault()
        openShareViewDrawer(view, drawer)
        closeMenu()
      },
      [closeMenu, drawer, openShareViewDrawer, view]
    )

    const handleDeleteView = useCallback(async () => {
      const viewToDelete = view
      setBusy(true)
      const deleted = await deleteSavedView(viewToDelete.id)
      await deleteAlertsByFiltersetID(viewToDelete.id)

      if (deleted && isActive) {
        setGlobalFilterset(emptyDraftFilterset())
      }
      if (deleted) {
        toast.success(`Deleted view "${viewToDelete.name}"`, { autoHideDuration: 2000 })
      } else {
        toast.error(`Could not delete view "${viewToDelete.name}"`)
      }
      closeDeleteConfirmation()
      closeMenu()
      setBusy(false)
    }, [
      view,
      deleteSavedView,
      deleteAlertsByFiltersetID,
      isActive,
      closeDeleteConfirmation,
      closeMenu,
      setGlobalFilterset,
      toast
    ])

    return (
      <>
        <ListItemButton
          data-testid={`view-list-item-${view.id}`}
          selected={isActive}
          key={view.id}
          onClick={(ev) => handleSavedViewClick(ev, view)}
          divider
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'h5',
              component: 'div'
            }}
            primary={
              <Box display="flex" alignItems="center" flexDirection="row" mb={1}>
                <Box data-testid="view-name">{view.name}</Box>
                {view.subscriptionId && <SharedIcon isMine={isMyView} sx={{ ml: 2 }} />}
                {hasNotifications && (
                  <IconButton sx={{ ml: 2 }} onClick={handleEditView} data-testid="view-notifications">
                    <Notifications fontSize="small" />
                  </IconButton>
                )}
              </Box>
            }
            secondaryTypographyProps={{
              component: 'div'
            }}
            secondary={
              <>
                <FilterSummary summaryData={filterSummary} />
                {!!view.tags?.length && (
                  <Box mt={1}>
                    <TagList tags={view.tags} readonly />
                  </Box>
                )}
              </>
            }
          />
          <IconButton edge="end" onClick={openMenu} data-testid="view-menu">
            <MoreVert />
          </IconButton>
        </ListItemButton>
        <PopupMenu
          id={`saved-view-menu-${view.id}`}
          anchorEl={menuAnchor}
          onClose={() => closeMenu()}
          open={!!menuAnchor}
        >
          {isCustomTerritory ? (
            <PopupMenuItem
              onClick={handleEditTerritory}
              disabled={!isMyView}
              data-testid="popup-menu-item-edit-territory"
            >
              <MenuItemIcon>
                <Edit fontSize="small" />
              </MenuItemIcon>
              <MenuItemText>Edit territory</MenuItemText>
            </PopupMenuItem>
          ) : (
            <PopupMenuItem onClick={handleEditView} disabled={!isMyView} data-testid="popup-menu-item-rename-view">
              <MenuItemIcon>
                <Edit fontSize="small" />
              </MenuItemIcon>
              <MenuItemText>Rename view</MenuItemText>
            </PopupMenuItem>
          )}
          <PopupMenuItem onClick={handleShareView} disabled={!isMyView} data-testid="popup-menu-item-share-view">
            <MenuItemIcon>
              <People fontSize="small" />
            </MenuItemIcon>
            <MenuItemText>Share/transfer {!isCustomTerritory ? 'view' : 'territory'}</MenuItemText>
          </PopupMenuItem>
          <PopupMenuItem onClick={handleEditView} data-testid="popup-menu-item-notification-preferences">
            <MenuItemIcon>
              <Notifications fontSize="small" />
            </MenuItemIcon>
            <MenuItemText>Alert preferences</MenuItemText>
          </PopupMenuItem>
          <PopupMenuItem onClick={confirmDelete} disabled={!isMyView} data-testid="popup-menu-item-delete-view">
            <MenuItemIcon>
              <Delete fontSize="small" />
            </MenuItemIcon>
            <MenuItemText>Delete {!isCustomTerritory ? 'view' : 'territory'}</MenuItemText>
          </PopupMenuItem>
        </PopupMenu>
        <ConfirmationDialog
          open={confirmingDeleteView}
          title={`Delete ${view.subscriptionId ? 'shared ' : ''}${!isCustomTerritory ? 'view' : 'territory'}`}
          confirmButtonText="Delete"
          loading={busy}
          loadingIndicator="Deleting..."
          content={
            <>
              <DialogContentText>
                Are you sure you want to delete <strong>{view.name}</strong>?
              </DialogContentText>
              {view.subscriptionId && (
                <Alert sx={{ mt: 2 }} severity="warning" data-testid="alert-delete-shared-view">
                  <AlertTitle>Shared {!isCustomTerritory ? 'view' : 'territory'}</AlertTitle>
                  This {!isCustomTerritory ? 'view' : 'territory'} is shared with other users. Deleting it will remove
                  it from their list of views.
                </Alert>
              )}
            </>
          }
          onCancel={closeDeleteConfirmation}
          onConfirm={handleDeleteView}
        />
      </>
    )
  }
)

export const ViewListItem: React.FC<ViewListItemProps> = memo(function ViewListItem({ view, ...rest }) {
  const { preferencesForFiltersetId } = useNotificationPreferences()
  const hasNotifications = useMemo(
    () => preferencesForFiltersetId(view.id).size > 0,
    [preferencesForFiltersetId, view.id]
  )
  return <ViewListItemContent {...rest} view={view} hasNotifications={hasNotifications} />
})
