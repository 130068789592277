import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box, Drawer, DrawerProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC, PropsWithChildren, useCallback, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'

const DEFAULT_DRAWER_WIDTH = 320

const useStyles = makeStyles(() => ({
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: '#F8F8F8'
  }
}))

export const ResizableDrawer: FC<PropsWithChildren<DrawerProps>> = ({ children, ...props }) => {
  const classes = useStyles()
  const { isMobile } = useResponsiveState()

  const [drawerWidth, setDrawerWidth] = useState(320)

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e: any) => {
    const newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft)
    if (newWidth >= DEFAULT_DRAWER_WIDTH) {
      setDrawerWidth(newWidth)
    }
  }, [])

  // Set the drawer width to the default width when it goes to mobile
  useUpdateEffect(() => {
    if (isMobile) {
      setDrawerWidth(DEFAULT_DRAWER_WIDTH)
    }
  }, [isMobile])

  // Set the drawer width to the default width when the drawer is opened
  useUpdateEffect(() => {
    if (props.open) {
      setDrawerWidth(DEFAULT_DRAWER_WIDTH)
    }
  }, [props.open])

  return (
    <Drawer
      {...props}
      PaperProps={{
        ...props.PaperProps,
        sx: {
          ...props.PaperProps?.sx,
          maxWidth: '95vw',
          width: `${drawerWidth}px !important`
        }
      }}
    >
      {!isMobile && (
        <Box onMouseDown={handleMouseDown} className={classes.dragger} data-testid="resizable-drawer--dragger" />
      )}
      {children}
    </Drawer>
  )
}
