import { shouldInvalidateUserQuery } from '@client/helpers/graphql/graphql-helpers'
import { useUser as useUserProvider } from '@client/providers/user'
import { apiClient } from '@client/services/api/api-client'
import { UNASSIGNED_ACCOUNT_ID } from '@client/types/account'
import {
  HasuraUser,
  UpdateUserResponse,
  UserByPkResponse,
  UsersOptions,
  UsersResponse,
  UsersSortField
} from '@client/types/user'
import { UserAccountAndRoles, UsersListItem } from '@client/types/user-accounts-subscriptions'
import { graphql } from '@lib/clients/graphql'
import { addSearchTerm } from '@lib/helpers/graphql'
import { splitUserAccountRoles } from '@lib/helpers/user'
import { SortOrder } from '@lib/types/orderBy'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { graphqlMutation, useGraphqlQuery } from './use-admin-graphql'

type UserWithSubscription = { userId: string; subscriptionId?: string }

const { mutate: deleteUserMutate } = apiClient.users.deleteUser
const { mutate: suspendUserMutate } = apiClient.users.suspendUser
const { mutate: reactivateUserMutate } = apiClient.users.reactivateUser
const { mutate: addUserToAccountMutate } = apiClient.users.addUserToAccount
const { mutate: updateUserAccountRolesMutate } = apiClient.users.updateUserAccountRoles
const { mutate: removeUserFromAccountMutate } = apiClient.users.removeUserFromAccount
const { query: listSubscriptionUsers } = apiClient.auditLog.getSubscriptionUsers

const USER_QUERY = graphql(`
  query GetUsers($limit: Int!, $offset: Int!, $sort: [user_order_by!], $where: user_bool_exp!) {
    user(order_by: $sort, limit: $limit, offset: $offset, where: $where) {
      user_id
      fname
      lname
      email
      suspended_at
      deleted_at
      latest_login
      user_subscription_roles {
        role {
          role_name
          role_id
          role_code
          role_description
          parent_role_id
        }
        subscription {
          subscription_id
          subscription_name
        }
      }
    }
    user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`)

const USER_ID_QUERY = graphql(`
  query GetUser($user_id: String!) {
    user_by_pk(user_id: $user_id) {
      fname
      lname
      email
      user_id
      suspended_at
      deleted_at
      user_subscription_filtersets {
        user_subscription_filterset_id
        subscription_id
        filterset_id
        filterset {
          filters
        }
      }
      user_subscription_roles {
        subscription {
          subscription_name
          subscription_id
        }
        role {
          role_id
          role_name
          role_code
          role_description
          parent_role_id
        }
      }
    }
  }
`)

const UPDATE_USER = graphql(`
  mutation UpdateUser($user_id: String!, $fname: String, $lname: String) {
    update_user_by_pk(pk_columns: { user_id: $user_id }, _set: { fname: $fname, lname: $lname }) {
      user_id
    }
  }
`)

export const parseUserSortField = (sortField: UsersSortField, sortOrder: SortOrder = 'asc') => {
  switch (sortField) {
    case UsersSortField.FIRST_NAME:
      return [{ fname: sortOrder }]
    case UsersSortField.LAST_NAME:
      return [{ lname: sortOrder }]
    case UsersSortField.CREATION_DATE:
      return [{ created_at: sortOrder }]
    case UsersSortField.LATEST_LOGIN:
      return [{ latest_login: `${sortOrder}_nulls_${sortOrder === 'asc' ? 'first' : 'last'}` }]
    default:
      return []
  }
}

export const parseUserWhere = (
  searchTerm?: string,
  accountId?: string,
  roleId?: string,
  excludeSuspended = false,
  excludeDeleted = true
) => {
  const where: Record<string, any> = {}
  if (excludeDeleted) {
    where.deleted_at = { _is_null: true }
  }
  if (excludeSuspended) {
    where.suspended_at = { _is_null: true }
  }
  addSearchTerm(where, ['email', 'fname', 'lname'], searchTerm)
  const accountFilterSet = accountId && accountId !== UNASSIGNED_ACCOUNT_ID
  if (accountFilterSet || roleId) {
    where.user_subscription_roles = {} as Record<string, unknown>
    if (accountFilterSet) {
      where.user_subscription_roles.subscription_id = { _eq: accountId }
    }
    if (roleId) {
      where.user_subscription_roles.role_id = { _eq: roleId }
    }
  }
  // Use a special account ID value to to filter out users that have any subscriptions
  if (accountId === UNASSIGNED_ACCOUNT_ID) {
    where.user_subscription_roles_aggregate = { count: { predicate: { _eq: 0 } } }
  }
  return where
}

const parseUser = (user: HasuraUser): UsersListItem => {
  const accounts: Record<string, UserAccountAndRoles> = user.user_subscription_roles.reduce(
    (acc, { role, subscription }) => {
      if (!acc[subscription.subscription_id]) {
        acc[subscription.subscription_id] = {
          account: {
            id: subscription.subscription_id,
            name: subscription.subscription_name
          },
          roles: []
        }
      }
      acc[subscription.subscription_id].roles.push({
        id: role.role_id,
        name: role.role_name,
        code: role.role_code,
        description: role.role_description,
        parentId: role.parent_role_id
      })
      return acc
    },
    {} as Record<string, UserAccountAndRoles>
  )

  // add user account filterset to account
  for (const userFilterset of user.user_subscription_filtersets ?? []) {
    if (accounts[userFilterset.subscription_id]) {
      accounts[userFilterset.subscription_id].filterset = {
        id: userFilterset.filterset_id,
        filters: userFilterset.filterset.filters
      }
    }
  }

  const accountsArray = Object.values(accounts)

  return {
    user: {
      id: user.user_id,
      firstName: user.fname,
      lastName: user.lname,
      email: user.email,
      latestLogin: user.latest_login ? new Date(user.latest_login) : null,
      isSuspended: !!user.suspended_at,
      isDeleted: !!user.deleted_at
    },
    accounts: accountsArray
  }
}

export const useUsers = (usersOptions: UsersOptions, autoFetch = true) => {
  const variables = useMemo(
    () => ({
      sort: parseUserSortField(usersOptions.sortField, usersOptions.sortOrder),
      limit: usersOptions.pageSize,
      offset: usersOptions.from,
      where: parseUserWhere(
        usersOptions.search,
        usersOptions.account?.id,
        usersOptions.roleId,
        usersOptions.excludeSuspended,
        usersOptions.excludeDeleted
      )
    }),
    [usersOptions]
  )
  const { data, ...response } = useGraphqlQuery<UsersResponse>({ query: USER_QUERY, variables }, { enabled: autoFetch })

  const { total, users } = useMemo(() => {
    return data ? transformUsersData(data) : { total: 0, users: undefined }
  }, [data])

  return {
    ...response,
    data: users,
    total
  }
}

export const transformUsersData = (data: UsersResponse) => {
  let total = 0
  if (data.user_aggregate.aggregate?.count !== undefined) {
    total = data.user_aggregate.aggregate.count
  }
  const users = data.user.map(parseUser)
  return {
    total,
    users
  }
}

export const useUser = (userId?: string) => {
  const { data, ...response } = useGraphqlQuery<UserByPkResponse>(
    {
      query: USER_ID_QUERY,
      variables: {
        user_id: userId
      }
    },
    { enabled: !!userId && userId !== 'new' }
  )

  const userData = useMemo(() => {
    return data?.user_by_pk && parseUser(data?.user_by_pk)
  }, [data?.user_by_pk])

  return {
    ...response,
    error: data?.user_by_pk ? response.error : 'User not found',
    data: userData
  }
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const invalidateQueries = useCallback(
    async (id?: string) => {
      return await queryClient.invalidateQueries({
        predicate: (query) => shouldInvalidateUserQuery(query.queryKey, id)
      })
    },
    [queryClient]
  )

  const updateUser = useCallback(
    async (userId: string, firstName: string, lastName: string, invalidate = true) => {
      const { update_user_by_pk } = await graphqlMutation<UpdateUserResponse>({
        query: UPDATE_USER,
        variables: {
          user_id: userId,
          fname: firstName,
          lname: lastName
        }
      })
      invalidate && invalidateQueries(userId)
      return update_user_by_pk
    },
    [invalidateQueries]
  )

  const updateUserRoles = async (
    user: { userId: string; email: string },
    prevSubscriptions: UserAccountAndRoles[],
    nextSubscriptions: UserAccountAndRoles[],
    invalidate = true
  ) => {
    const { accountsToInsert, accountsToRemove, accountsToUpdate } = splitUserAccountRoles(
      prevSubscriptions,
      nextSubscriptions
    )

    // We should avoid to call these methods in parallel to avoid hasura conflict errors on updating the user record
    const insertAndRemoveUserFromAccounts = async () => {
      for (const toRemove of accountsToRemove) {
        await removeUserFromAccountMutate({
          user,
          ...toRemove
        })
      }
      for (const toInsert of accountsToInsert) {
        await addUserToAccountMutate({
          user,
          ...toInsert
        })
      }
    }

    await Promise.all([
      insertAndRemoveUserFromAccounts(),
      ...accountsToUpdate.map((account) =>
        updateUserAccountRolesMutate({
          user,
          ...account
        })
      )
    ])
    invalidate && invalidateQueries(user.userId)
    return { success: true }
  }

  const suspendUser = useCallback(
    async ({ userId, subscriptionId }: UserWithSubscription) => {
      return suspendUserMutate({ userId, subscriptionId }).then(() => invalidateQueries(userId))
    },
    [invalidateQueries]
  )

  const reactivateUser = useCallback(
    async ({ userId, subscriptionId }: UserWithSubscription) => {
      return reactivateUserMutate({ userId, subscriptionId }).then(() => invalidateQueries(userId))
    },
    [invalidateQueries]
  )

  const deleteUser = useCallback(
    async ({ userId, subscriptionId }: UserWithSubscription) => {
      return deleteUserMutate({ userId, subscriptionId }).then(() => invalidateQueries(userId))
    },
    [invalidateQueries]
  )

  return {
    updateUser,
    updateUserRoles,
    suspendUser,
    reactivateUser,
    deleteUser,
    invalidateQueries
  }
}

export const useSubscriptionUsers = ({ excludeSuspended = false, excludeDeleted = true, subscriptionId = '' }) => {
  const { account } = useUserProvider()
  const accountId = useMemo(() => subscriptionId || account?.id, [account, subscriptionId])
  return useQuery(
    ['listSubscriptionUsers', accountId, excludeSuspended, excludeDeleted],
    async () => listSubscriptionUsers({ subscriptionId: accountId as string, excludeSuspended, excludeDeleted }),
    {
      enabled: !!accountId
    }
  )
}
