import { MeasureUnit, timeMeasureQueryMapping } from '@client/types/charts'
import { DAILY, DOW, TIME, WEEKLY } from '@client/types/notification'
import { DataDisplay } from '@lib/types/modules'
import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

type Dateable = string | number | Date | dayjs.Dayjs

export const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

// TODO: Make use of this everywhere!
export const ELASTIC_DT_FORMAT = 'YYYY-MM-DD'

export function formatMonthYear(date: Dateable) {
  return dayjs(date).format('MMMM YYYY')
}

export function formatDayMonthDateClean(date: Dateable) {
  return dayjs(date).format('MMM Do')
}

export function formatMonthYearDate(date: Dateable) {
  return dayjs(date).format('MMM YYYY')
}

export function formatMultiModuleChartDates(dates: { on: string }[], dataDisplay: DataDisplay = 'SE'): string[] {
  return dates.map(({ on }) => (dataDisplay === 'MI' ? formatMonthYearDate(on) : formatDayMonthDate(on)))
}

export function formatDayMonthDate(date: Dateable) {
  return dayjs(date).format('MM/DD')
}

export function formatDayMonthDateUTC(date: Dateable) {
  return dayjs.utc(date).format('MM/DD')
}

export function formatDayMonthDateLocalized(date: Dateable) {
  return dayjs(date).format('MMM D, h:mm A')
}

export function getYesterdaysDate(): string {
  return dayjs(new Date()).subtract(1, 'day').format(ELASTIC_DT_FORMAT)
}

export function getTwoMonthsAgo(): string {
  return dayjs(new Date()).subtract(2, 'month').startOf('month').format(ELASTIC_DT_FORMAT)
}

export const getMonths = (date: Dateable, count: number, interval: number) => {
  const dates = []
  for (let i = 0; i < count; i++) {
    dates.push(
      dayjs(date)
        .subtract(i * interval, 'month')
        .startOf('month')
        .format('YYYY-MM-DD')
    )
  }
  return dates.reverse()
}

export function getDateStrings(startDate: Dateable, count: number, interval = 7, useMonth = false): string[] {
  if (useMonth) {
    return getMonths(startDate, count, interval)
  } else {
    const dates = []
    for (let i = 0; i < count; i++) {
      dates.push(
        dayjs(startDate)
          .subtract(i * interval, 'day')
          .format('YYYY-MM-DD')
      )
    }
    return dates.reverse()
  }
}

export function daysAgoToTimeStamp(daysAgo: number): number {
  return dayjs(Date.now()).startOf('day').subtract(daysAgo, 'day').unix()
}

/**
 * Returns yesterday (in the string format 'YYYY-MM-DD') if the supplied
 * date is today or a date in the future. Otherwise, just returns the supplied date.
 * @param date - the date to potentially convert
 * @returns a date string in the format 'YYYY-MM-DD' that is guaranteed to be before today
 */
export function latestDateBeforeToday(date: string | undefined): string {
  const yesterday = dayjs(Date.now()).startOf('day').subtract(1, 'day')
  if (!date || !dayjs(date).isValid()) {
    return yesterday.format(ELASTIC_DT_FORMAT)
  }
  const dateToCheck = dayjs(date).startOf('day')
  if (dateToCheck.isAfter(yesterday)) {
    return yesterday.format(ELASTIC_DT_FORMAT)
  }
  return dateToCheck.format(ELASTIC_DT_FORMAT)
}

export function latestDateSE(date: string | undefined): string {
  if (!date || !dayjs(date).isValid()) {
    return getYesterdaysDate()
  }
  const dateToCheck = dayjs(date).startOf('day')
  const today = dayjs(Date.now()).startOf('day')
  if (dateToCheck.isAfter(today)) {
    return getYesterdaysDate()
  }
  return date
}

export function latestDateMI(date: string | undefined): string {
  // If we start projecting current month in Elastic MI will need to change this logic
  if (!date || !dayjs(date).isValid()) {
    return getTwoMonthsAgo()
  }
  const dateToCheck = dayjs(date).startOf('day')
  const lastMonth = dayjs(new Date()).subtract(1, 'month').startOf('month')
  if (dateToCheck.isAfter(lastMonth)) {
    return getTwoMonthsAgo()
  }
  return date
}

export const getLatestModuleDate = (
  seDate: string | undefined,
  miDate: string | undefined,
  dataDisplay: DataDisplay = 'SE'
) => {
  return dataDisplay === 'MI' ? latestDateMI(miDate) : latestDateSE(seDate)
}

export const getModuleDateStringsParams = (
  seDate: string | undefined,
  miDate: string | undefined,
  dataDisplay: DataDisplay = 'SE',
  measure: MeasureUnit
) => {
  const latestDate = getLatestModuleDate(seDate, miDate, dataDisplay)
  const useMonths = dataDisplay === 'MI'
  return getDateStrings(
    dayjs(latestDate),
    timeMeasureQueryMapping[measure].measureMaxSize,
    timeMeasureQueryMapping[measure].measureInterval,
    useMonths
  ).map((date) => ({
    on: date
  }))
}

export const formatSchedule = (frequency: DAILY | WEEKLY, time: TIME, dow?: DOW) => {
  if (frequency === 'weekly' && dow === undefined) {
    throw new Error("'dow' must be defined for 'WEEKLY' frequency.")
  }

  if (dow !== undefined && (dow < 0 || dow > 6)) {
    throw new Error("'dow' must be within the range 0-6.")
  }

  const frequencyText = frequency === 'daily' ? 'Every day' : `Every ${dow !== undefined ? daysOfWeek[dow] : ''}`
  const timeText = dayjs().hour(time).format('h:00a')
  return `${frequencyText} at ${timeText}`
}

export const minutesFromNowToDate = (minutes?: string | number) => {
  const mins = typeof minutes === 'number' ? minutes : parseInt(minutes ?? '')
  return isNaN(mins) ? undefined : dayjs().add(mins, 'minutes').toDate()
}

export type DateRange = [dayjs.Dayjs | null, dayjs.Dayjs | null]

export function getDateRangeShortcuts(today?: Dayjs) {
  today = (today || dayjs()).endOf('day')
  const startofWeek = today.startOf('week')
  const prevWeekStart = today.subtract(7, 'day').startOf('week')
  const prevWeekEnd = prevWeekStart.endOf('week')
  const startofMonth = today.startOf('month')
  const prevMonthStart = today.startOf('month').subtract(1, 'day').startOf('month')
  const prevMonthEnd = prevMonthStart.endOf('month')
  return [
    {
      label: 'This Week',
      getValue: () => {
        return [startofWeek, today]
      }
    },
    {
      label: 'Previous Week',
      getValue: () => {
        return [prevWeekStart, prevWeekEnd]
      }
    },
    {
      label: 'This Month',
      getValue: () => {
        return [startofMonth, today]
      }
    },
    {
      label: 'Previous Month',
      getValue: () => {
        return [prevMonthStart, prevMonthEnd]
      }
    },
    { label: 'Reset', getValue: () => [null, null] }
  ] as { label: string; getValue: () => DateRange }[]
}

export function getDateRangeDescription(dateRange: DateRange | undefined): string {
  const shortcuts = getDateRangeShortcuts()
  const [start, end] = dateRange || [null, null]
  if (start && end) {
    const shortcut = shortcuts.find((s) => {
      const [sStart, sEnd] = s.getValue()
      return start.startOf('day').isSame(sStart) && end.endOf('day').isSame(sEnd)
    })
    if (shortcut) {
      return shortcut.label
    }
    if (start.isSame(end, 'day')) {
      return start.format('MMM D, YYYY')
    }
    return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`
  }
  if (!start && end) {
    return `Before ${end.format('MMM D, YYYY')}`
  }
  if (start && !end) {
    return `After ${start.format('MMM D, YYYY')}`
  }
  return 'All Time'
}
