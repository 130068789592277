import { LoadingButton } from '@mui/lab'
import { Button, Stack, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { memo, useCallback } from 'react'
import { useBoolean } from 'usehooks-ts'

type Props = {
  sx?: SxProps<Theme>
  onSelectAll?: () => Promise<void>
  onClearSelection?: () => void
}

export const TabSelectorFooter = memo(function TabSelectorFooter({ sx, onClearSelection, onSelectAll }: Props) {
  const { setValue: setIsLoading, value: isLoading } = useBoolean(false)
  const handleSelectAll = useCallback(() => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    onSelectAll?.().finally(() => {
      setIsLoading(false)
    })
  }, [isLoading, onSelectAll, setIsLoading])

  return onSelectAll || onClearSelection ? (
    <Stack direction="row" alignItems="center" gap={1} ml="auto" mt={2} sx={sx}>
      {!!onClearSelection && !isLoading && (
        <Button data-testid="infinite-hits--clear-selection-button" onClick={onClearSelection}>
          Clear selection
        </Button>
      )}
      <LoadingButton data-testid="infinite-hits--select-all-button" loading={isLoading} onClick={handleSelectAll}>
        Select all results
      </LoadingButton>
    </Stack>
  ) : null
})
