import {
  ApolloClient,
  DocumentNode,
  FetchResult,
  gql,
  MutationOptions,
  Observable,
  OperationVariables,
  QueryOptions
} from '@apollo/client'
import { camelToSnakeCase } from '@client/helpers/strings'
import { Comparison, ComparisonResponse, DraftComparison } from '@client/types/comparison'
import { DraftFavorite, Favorite } from '@client/types/favorites'
import { FiltersetGraphQL } from '@client/types/filterset'
import {
  DraftNotificationPreference,
  NotificationOptions,
  NotificationPreference,
  preparePreferenceRequest,
  toOptionsFromResult,
  toPreferenceFromResult,
  toPreferencesFromResults
} from '@client/types/notification'
import { KnownRoleCode } from '@client/types/role'
import {
  DraftTag,
  ItemTag,
  ItemTagDb,
  itemTagsToInClause,
  ItemTagWithTag,
  parseTagWithItemsCount,
  RequiredItemTag,
  TagType,
  tagTypeKeys,
  TagWithCount,
  TagWithItems,
  TagWithItemsCountResponse,
  toDbItemTagFormat,
  toDbTagFormat
} from '@client/types/tags'
import type { DraftView, Filters, View } from '@hoodie/hoodie-filters/lib/filterset'
import { emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import { ReturningResults } from '@lib/types/graphql'
import { NonNullableValues } from '@lib/types/utils'
import dayjs from 'dayjs'
import deepmerge from 'deepmerge'
import { SetRequired } from 'type-fest'

export enum UserDataKeys {
  SavedViews = 'saved_views',
  ChartsSettings = 'charts_settings',
  DispensaryAnalyticsKPISettings = 'menu_analytics_kpi_settings',
  DashboardGrid = 'dashboard_grid',
  UserFlags = 'user_flags',
  ProductsGridOptions = 'products_grid_options',
  PimDataGridOptions = 'pim_data_grid_options'
}

export type UserData = {
  fname: string
  lname: string
  phone: string
}

const NOTIFICATION_OPTIONS = gql`
  query NotificationOptions {
    notification_channel {
      notification_channel_id
      notification_channel_name
      notification_channel_description
    }
    notification_category {
      parent_notification_category_id
      notification_category_id
      category_name
      category_code
      description
      group
    }
  }
`

const NOTIFICATION_PREFERENCES = gql`
  query NotificationPreferences {
    notification_preference {
      notification_preference_id
      filterset_id
      comparison_id
      is_enabled
      disabled_until
      schedule
      data
      notification_preference_notification_channels {
        notification_channel_id
      }
      notification_preference_notification_categories {
        notification_category_id
      }
    }
  }
`

const NOTIFICATIONS = gql`
  subscription subscribeToNotifications($thirtyDaysAgo: timestamptz!) {
    notification(order_by: { created_at: desc }, where: { created_at: { _gte: $thirtyDaysAgo } }) {
      notification_id
      web_read_at
      created_at
      subject
      message
      data
    }
  }
`

const NOTIFICATION_MUTATIONS = {
  ChangeNotificationStatus: gql`
    mutation ChangeNotificationStatus($notificationId: uuid!, $readAt: timestamptz) {
      update_notification_by_pk(pk_columns: { notification_id: $notificationId }, _set: { web_read_at: $readAt }) {
        notification_id
        web_read_at
      }
    }
  `,
  UpdateAllUnreadNotifications: gql`
    mutation UpdateAllUnreadNotifications($readAt: timestamptz) {
      update_notification(where: { web_read_at: { _is_null: true } }, _set: { web_read_at: $readAt }) {
        affected_rows
      }
    }
  `,
  InsertNotificationPreference: gql`
    mutation InsertNotficationPreference($preference: notification_preference_insert_input!) {
      insert_notification_preference_one(object: $preference) {
        notification_preference_id
        filterset_id
        comparison_id
        is_enabled
        schedule
        data
        notification_preference_notification_channels {
          notification_channel_id
        }
        notification_preference_notification_categories {
          notification_category_id
        }
      }
    }
  `,
  UpdateNotificationPreference: gql`
    mutation UpdateNotificationPreference(
      $preferenceID: uuid!
      $deletedAt: timestamptz
      $preference: notification_preference_insert_input!
    ) {
      update_notification_preference_notification_category(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      update_notification_preference_notification_channel(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      insert_notification_preference_one(
        object: $preference
        on_conflict: { constraint: notification_preference_pkey, update_columns: [data, schedule, disabled_until] }
      ) {
        notification_preference_id
        filterset_id
        comparison_id
        is_enabled
        disabled_until
        schedule
        data
        notification_preference_notification_channels {
          notification_channel_id
        }
        notification_preference_notification_categories {
          notification_category_id
        }
      }
    }
  `,
  DeleteNotificationPreference: gql`
    mutation SoftDeleteNotificationPreference($preferenceID: uuid!, $deletedAt: timestamptz) {
      update_notification_preference_notification_category(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      update_notification_preference_notification_channel(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      update_notification_preference_by_pk(
        pk_columns: { notification_preference_id: $preferenceID }
        _set: { deleted_at: $deletedAt }
      ) {
        notification_preference_id
      }
    }
  `
}

const COMPARISON_FRAGMENT = gql`
  fragment ComparisonRecord on comparison {
    comparison_id
    user_id
    subscription_id
    context
    filters
    items
    name
    comparison_filtersets {
      filterset {
        filters
        filterset_id
        filterset_name
      }
    }
  }
`

const COMPARISONS_QUERIES = {
  GET_COMPARISONS: gql`
    ${COMPARISON_FRAGMENT}
    query GetComparisons {
      comparison(where: { deleted_at: { _is_null: true } }) {
        ...ComparisonRecord
      }
    }
  `,
  CHECK_NAME: gql`
    query CheckComparisonName($name: String!) {
      comparison(where: { deleted_at: { _is_null: true }, name: { _eq: $name } }) {
        comparison_id
      }
    }
  `
}

const COMPARISON_MUTATIONS = {
  InsertComparison: gql`
    ${COMPARISON_FRAGMENT}
    mutation InsertComparison(
      $name: String!
      $context: String!
      $items: jsonb
      $filters: jsonb
      $views: comparison_filterset_arr_rel_insert_input
    ) {
      insert_comparison_one(
        object: { name: $name, context: $context, items: $items, filters: $filters, comparison_filtersets: $views }
      ) {
        ...ComparisonRecord
      }
    }
  `,
  UpdateComparison: gql`
    ${COMPARISON_FRAGMENT}
    mutation UpdateComparison(
      $id: uuid!
      $userId: String!
      $subscriptionId: uuid
      $name: String!
      $context: String!
      $items: jsonb
      $filters: jsonb
      $views: [comparison_filterset_insert_input!]!
    ) {
      delete_comparison_filterset(where: { comparison_id: { _eq: $id } }) {
        affected_rows
      }
      insert_comparison_filterset(objects: $views) {
        affected_rows
      }
      update_comparison_by_pk(
        pk_columns: { comparison_id: $id }
        _set: {
          name: $name
          context: $context
          items: $items
          filters: $filters
          user_id: $userId
          subscription_id: $subscriptionId
        }
      ) {
        ...ComparisonRecord
      }
    }
  `,
  DeleteComparison: gql`
    mutation DeleteComparison($comparisonId: uuid!, $deletedAt: timestamptz!) {
      update_comparison(where: { comparison_id: { _eq: $comparisonId } }, _set: { deleted_at: $deletedAt }) {
        affected_rows
      }
    }
  `
}

const TAG_FRAGMENT = gql`
  fragment TagRecord on tag {
    id: tag_id
    label: tag_label
    color
    userId: user_id
    data
  }
`

const ITEM_TAGS_COUNT_FRAGMENT = gql`
  fragment ItemTagsCountRecord on tag {
    itemsCount: item_tags_aggregate {
      brands: aggregate {
        count(columns: brand_id)
      }
      dispensaries: aggregate {
        count(columns: dispensary_id)
      }
      reports: aggregate {
        count(columns: report_id)
      }
      filtersets: aggregate {
        count(columns: filterset_id)
      }
      masteredProducts: aggregate {
        count(columns: cm_id)
      }
      nonMasteredProducts: aggregate {
        count(columns: menu_id)
      }
    }
  }
`

const ITEM_TAG_FRAGMENT = gql`
  fragment ItemTagRecord on item_tag {
    itemTagId: item_tag_id
    subscriptionId: subscription_id
    brandId: brand_id
    cmId: cm_id
    menuId: menu_id
    dispensaryId: dispensary_id
    filtersetId: filterset_id
    reportId: report_id
    data: meta_data
  }
`

export const TAGS_QUERIES = {
  ListTags: gql`
    ${TAG_FRAGMENT}
    ${ITEM_TAGS_COUNT_FRAGMENT}
    query ListTags {
      tag(order_by: { tag_label: asc }) {
        ...TagRecord
        ...ItemTagsCountRecord
      }
    }
  `,
  ListItemTags: gql`
    ${TAG_FRAGMENT}
    ${ITEM_TAG_FRAGMENT}
    query ListItemTags($where: item_tag_bool_exp!) {
      item_tag(where: $where, order_by: { tag: { user_id: asc_nulls_first, tag_label: asc } }) {
        ...ItemTagRecord
        tag {
          ...TagRecord
        }
      }
    }
  `,
  ListItemsFromTag: gql`
    ${ITEM_TAG_FRAGMENT}
    query ListItemsFromTag($where: item_tag_bool_exp!) {
      items: item_tag(where: $where) {
        ...ItemTagRecord
      }
    }
  `
}
export const TAGS_MUTATIONS = {
  InsertTag: gql`
    ${TAG_FRAGMENT}
    ${ITEM_TAG_FRAGMENT}
    ${ITEM_TAGS_COUNT_FRAGMENT}
    mutation InsertTag($label: String!, $color: String!, $items: item_tag_arr_rel_insert_input) {
      insert_tag_one(object: { tag_label: $label, color: $color, item_tags: $items }) {
        ...TagRecord
        ...ItemTagsCountRecord
        items: item_tags {
          ...ItemTagRecord
        }
      }
    }
  `,
  UpdateTag: gql`
    ${TAG_FRAGMENT}
    ${ITEM_TAG_FRAGMENT}
    ${ITEM_TAGS_COUNT_FRAGMENT}
    mutation UpdateTag(
      $tagId: uuid!
      $label: String!
      $color: String!
      $addItems: [item_tag_insert_input!]!
      $updateItems: [item_tag_updates!]!
      $removeItems: [uuid!]!
    ) {
      insert_item_tag(objects: $addItems) {
        returning {
          ...ItemTagRecord
        }
      }
      update_item_tag_many(updates: $updateItems) {
        returning {
          ...ItemTagRecord
        }
      }
      delete_item_tag(where: { item_tag_id: { _in: $removeItems } }) {
        affected_rows
      }
      update_tag_by_pk(pk_columns: { tag_id: $tagId }, _set: { color: $color, tag_label: $label }) {
        ...TagRecord
        ...ItemTagsCountRecord
      }
    }
  `,
  InsertItemTag: gql`
    ${ITEM_TAG_FRAGMENT}
    mutation InsertItemTag($object: item_tag_insert_input!) {
      insert_item_tag_one(object: $object) {
        ...ItemTagRecord
      }
    }
  `,
  UpdateItemTag: gql`
    ${ITEM_TAG_FRAGMENT}
    mutation UpdateItemTag($itemTagId: uuid!, $subscriptionId: uuid) {
      update_item_tag_by_pk(pk_columns: { item_tag_id: $itemTagId }, _set: { subscription_id: $subscriptionId }) {
        ...ItemTagRecord
      }
    }
  `,
  RemoveItemTag: gql`
    ${ITEM_TAG_FRAGMENT}
    mutation RemoveItemTag($itemTagId: uuid!) {
      delete_item_tag_by_pk(item_tag_id: $itemTagId) {
        ...ItemTagRecord
      }
    }
  `,
  DeleteTag: gql`
    ${TAG_FRAGMENT}
    mutation DeleteTag($tagId: uuid!) {
      delete_tag_by_pk(tag_id: $tagId) {
        ...TagRecord
      }
    }
  `
}

const FILTERSET_FRAGMENT = gql`
  ${TAG_FRAGMENT}
  fragment FiltersetRecord on filterset {
    filterset_id
    filterset_name
    userId: user_id
    subscriptionId: subscription_id
    filters
    tags: filterset_tags {
      tag {
        ...TagRecord
      }
    }
  }
`

const LIST_FILTERSETS = gql`
  ${FILTERSET_FRAGMENT}
  query ListFiltersets {
    filterset(order_by: { created_at: asc }) {
      ...FiltersetRecord
    }
  }
`

const INSERT_FILTERSET = gql`
  ${FILTERSET_FRAGMENT}
  mutation InsertFilterset(
    $name: String!
    $subscriptionId: uuid
    $filters: jsonb!
    $tags: filterset_tag_arr_rel_insert_input
  ) {
    filterset: insert_filterset_one(
      object: { filterset_name: $name, subscription_id: $subscriptionId, filters: $filters, filterset_tags: $tags }
    ) {
      ...FiltersetRecord
    }
  }
`

const UPDATE_FILTERSET = gql`
  mutation UpdateFilterset(
    $filtersetId: uuid!
    $name: String
    $filters: jsonb
    $userId: String!
    $subscriptionId: uuid
  ) {
    filterset: update_filterset_by_pk(
      pk_columns: { filterset_id: $filtersetId }
      _set: { filters: $filters, filterset_name: $name, user_id: $userId, subscription_id: $subscriptionId }
    ) {
      filterset_id
    }
  }
`

const DELETE_FILTERSET = gql`
  mutation UpdateFilterset($filtersetId: uuid!, $deletedAt: timestamptz) {
    filterset: update_filterset_by_pk(pk_columns: { filterset_id: $filtersetId }, _set: { deleted_at: $deletedAt }) {
      filterset_id
    }
  }
`

const FAVORITES_FRAGMENT = gql`
  fragment FavoriteRecord on favorite {
    menuId: menu_id
    favoriteId: favorite_id
    dispensaryId: dispensary_id
    cmId: cm_id
    brandId: brand_id
    reportId: report_id
    comparisonId: comparison_id
    favoritedAt: created_at
    name
  }
`
const LIST_FAVORITES = gql`
  ${FAVORITES_FRAGMENT}
  query ListFavorites {
    favorite(order_by: { created_at: desc }) {
      ...FavoriteRecord
    }
  }
`
const FAVORITES_MUTATIONS = {
  InsertFavorite: gql`
    ${FAVORITES_FRAGMENT}
    mutation InsertFavorite(
      $name: String!
      $dispensaryId: String
      $cmId: String
      $brandId: String
      $menuId: String
      $reportId: String
      $comparisonId: uuid
    ) {
      insert_favorite_one(
        object: {
          name: $name
          dispensary_id: $dispensaryId
          cm_id: $cmId
          brand_id: $brandId
          menu_id: $menuId
          report_id: $reportId
          comparison_id: $comparisonId
        }
      ) {
        ...FavoriteRecord
      }
    }
  `,
  UpdateFavoriteName: gql`
    ${FAVORITES_FRAGMENT}
    mutation UpdateFavoriteName($favoriteId: uuid!, $name: String!) {
      update_favorite_by_pk(pk_columns: { favorite_id: $favoriteId }, _set: { name: $name }) {
        ...FavoriteRecord
      }
    }
  `,
  DeleteFavorite: gql`
    ${FAVORITES_FRAGMENT}
    mutation DeleteFavorite($favoriteId: uuid!) {
      delete_favorite_by_pk(favorite_id: $favoriteId) {
        ...FavoriteRecord
      }
    }
  `
}

const SUBSCRIPTION_MUTATIONS = {
  BulkUpdateAccountTier: gql`
    mutation UpdateAccountTier($accountIds: [uuid!]!, $tierId: uuid!) {
      update_subscription(where: { subscription_id: { _in: $accountIds } }, _set: { subscription_tier_id: $tierId }) {
        affected_rows
      }
    }
  `
}

/**
 * Convert a GraphQL representation of a filterset to a Filterset object
 * @param filtersetResult The GraphQL representation of a filterset
 * @returns The Filterset object
 */
export function filtersetResultToRecord(filtersetResult: FiltersetGraphQL): View {
  const filters: Filters = deepmerge(emptyDraftFilterset().filters, filtersetResult.filters)
  return {
    id: filtersetResult.filterset_id,
    name: filtersetResult.filterset_name,
    userId: filtersetResult.userId,
    subscriptionId: filtersetResult.subscriptionId ?? undefined,
    tags: filtersetResult.tags?.map(({ tag }) => tag),
    filters
  }
}

function userDataQuery(key: string, operationName = 'UserData'): DocumentNode {
  return gql`
  query ${operationName} {
    ${key}: user_data(where: { key: { _eq: ${key} }}) {
      value
      key
    }
  }
  `
}

function userDataUpsertMutation(key: string, operationName = 'UpsertUserData'): DocumentNode {
  return gql`
  mutation ${operationName}($value: json!) {
    insert_user_data_one(object: {value: $value, key: ${key}}, on_conflict: { constraint: user_data_user_id_application_id_key_key, update_columns: [value] }) {
      value
      key
    }
  }
  `
}

export type HasuraParams = {
  apolloClient: ApolloClient<any>
}

export class UserDataNotSetError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'UserDataNotSetError'
  }
}

type HasuraGraphQLOptions = { useRoles?: boolean }

type HasuraQueryOptions<T extends object, V extends OperationVariables = OperationVariables> = QueryOptions<V, T>

type HasuraMutationOptions<T extends object, V extends OperationVariables = OperationVariables> = MutationOptions<T, V>

type HasuraCustomRole = 'HOODIE_SUPER_ADMIN' | KnownRoleCode

export class HasuraService {
  apolloClient: ApolloClient<any>
  private _role: HasuraCustomRole | undefined

  constructor(params: HasuraParams) {
    this.apolloClient = params.apolloClient
  }

  get role() {
    return this._role
  }

  set role(role: HasuraCustomRole | undefined) {
    this._role = role
  }

  private applyRolesToOptions<T extends object, V extends OperationVariables = OperationVariables>(
    options: Pick<HasuraQueryOptions<T, V>, 'context'>,
    hoodieOptions?: HasuraGraphQLOptions
  ) {
    if (hoodieOptions?.useRoles && this.role) {
      if (!options.context) {
        options.context = {}
      }
      if (!options.context.headers) {
        options.context.headers = {}
      }
      options.context.headers['x-hasura-role'] = this.role
    }
  }

  private async query<T extends object, V extends OperationVariables = OperationVariables>(
    options: HasuraQueryOptions<T, V>,
    hoodieOptions?: HasuraGraphQLOptions
  ): Promise<T> {
    this.applyRolesToOptions(options, hoodieOptions)
    const res = await this.apolloClient.query<T, V>(options)
    if (res.errors) {
      throw new Error('GraphQL error')
    }
    return res.data as Promise<T>
  }

  private async mutate<T extends object, V extends OperationVariables = OperationVariables>(
    options: HasuraMutationOptions<T, V>,
    hoodieOptions?: HasuraGraphQLOptions
  ): Promise<NonNullableValues<T>> {
    this.applyRolesToOptions(options, hoodieOptions)
    const res = await this.apolloClient.mutate<T, V>(options)
    if (res.errors) {
      throw new Error('GraphQL error')
    }
    return res.data as Promise<NonNullableValues<T>>
  }

  async listFiltersets(): Promise<View[]> {
    const res = await this.apolloClient.query({ query: LIST_FILTERSETS })
    if (res.data.errors) {
      throw new Error('GraphQL error')
    }
    return res.data.filterset.map(filtersetResultToRecord)
  }

  async changeNotificationStatus(notificationId: string, isRead: boolean): Promise<void> {
    const { data } = await this.apolloClient.mutate({
      mutation: NOTIFICATION_MUTATIONS.ChangeNotificationStatus,
      variables: { notificationId, readAt: isRead ? new Date().toISOString() : null }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }
  }

  async markAllNotificationsAsRead(): Promise<void> {
    const { data } = await this.apolloClient.mutate({
      mutation: NOTIFICATION_MUTATIONS.UpdateAllUnreadNotifications,
      variables: { readAt: new Date().toISOString() }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }
  }

  async notifications(): Promise<Observable<FetchResult<any, Record<string, any>, Record<string, any>>>> {
    return await this.apolloClient.subscribe({
      query: NOTIFICATIONS,
      variables: {
        thirtyDaysAgo: dayjs().subtract(30, 'days').format('YYYY-MM-DD')
      }
    })
  }

  async notificationOptions(): Promise<NotificationOptions> {
    const { data } = await this.apolloClient.query({ query: NOTIFICATION_OPTIONS })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return toOptionsFromResult(data)
  }

  async notificationPreferences(): Promise<NotificationPreference[]> {
    const { data } = await this.apolloClient.query({ query: NOTIFICATION_PREFERENCES })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return toPreferencesFromResults(data.notification_preference)
  }

  async insertNotificationPreference(preference: DraftNotificationPreference): Promise<NotificationPreference> {
    const { data } = await this.apolloClient.mutate({
      mutation: NOTIFICATION_MUTATIONS.InsertNotificationPreference,
      variables: { preference: preparePreferenceRequest(preference) }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return toPreferenceFromResult(data.insert_notification_preference_one)
  }

  async updateNotificationPreference(preference: NotificationPreference): Promise<NotificationPreference> {
    const { data } = await this.apolloClient.mutate({
      mutation: NOTIFICATION_MUTATIONS.UpdateNotificationPreference,
      variables: {
        preferenceID: preference.id,
        deletedAt: new Date().toISOString(),
        preference: preparePreferenceRequest(preference)
      }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return toPreferenceFromResult(data.insert_notification_preference_one)
  }

  async deleteNotificationPreference(preference: NotificationPreference): Promise<boolean> {
    const { data } = await this.apolloClient.mutate({
      mutation: NOTIFICATION_MUTATIONS.DeleteNotificationPreference,
      variables: { preferenceID: preference.id, deletedAt: new Date().toISOString() }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return true
  }

  async insertFilterset(filterset: DraftView & { subscriptionId: View['subscriptionId'] }): Promise<View> {
    const res = await this.apolloClient.mutate({
      mutation: INSERT_FILTERSET,
      variables: {
        name: filterset.name,
        subscriptionId: filterset.subscriptionId,
        filters: filterset.filters,
        tags: { data: filterset.tags?.map(({ id }) => ({ tag_id: id })) ?? [] }
      }
    })
    if (res.data.error) {
      throw new Error('GraphQL error')
    }
    return filtersetResultToRecord(res.data.filterset)
  }

  async updateFilterset(updatedFilterset: View): Promise<View> {
    const res = await this.apolloClient.mutate({
      mutation: UPDATE_FILTERSET,
      variables: {
        filtersetId: updatedFilterset.id,
        name: updatedFilterset.name,
        filters: updatedFilterset.filters,
        userId: updatedFilterset.userId,
        subscriptionId: updatedFilterset.subscriptionId || null
      }
    })
    if (res.data.error) {
      throw new Error('GraphQL error')
    }
    return updatedFilterset
  }

  async deleteFilterset(filtersetId: string): Promise<void> {
    const res = await this.apolloClient.mutate({
      mutation: DELETE_FILTERSET,
      variables: { filtersetId, deletedAt: new Date().toISOString() }
    })
    if (res.data.error) {
      throw new Error('GraphQL error')
    }
  }

  async getUserDataByKey<T>(key: UserDataKeys, operationName?: string): Promise<T | null> {
    const query = userDataQuery(key, operationName)
    const res = await this.apolloClient.query({ query })
    return res.data[key]?.length ? res.data[key][0].value : null
  }

  async setUserDataByKey<T>(key: UserDataKeys, value: T, operationName?: string): Promise<T> {
    const mutation = userDataUpsertMutation(key, operationName)
    try {
      const res = await this.apolloClient.mutate({
        mutation: mutation,
        variables: { value }
      })
      this.apolloClient.cache.modify({
        fields: {
          user_data: (ref, details) => {
            if (details.storeFieldName === 'user_data') {
              // Update all_user_data cache:
              if (ref.find((entry: any) => entry.key === key)) {
                return ref.map((entry: any) => {
                  if (entry.key === key) {
                    return res.data.insert_user_data_one
                  }
                  return entry
                })
              } else {
                return [...ref, res.data.insert_user_data_one]
              }
              // Update specific key request
            } else if (details.storeFieldName.includes(key)) {
              return [res.data.insert_user_data_one]
            }
            // Ignore others
            return ref
          }
        }
      })
      return res.data.insert_user_data_one.value
    } catch (err) {
      throw new Error('GraphQL error')
    }
  }

  invalidateComparisonCache() {
    this.apolloClient.cache.modify({
      fields: {
        comparison: (ref, { DELETE }) => DELETE
      }
    })
  }

  async isValidComparisonName(comparisonName: string, comparisonId?: string): Promise<boolean> {
    const { data } = await this.apolloClient.query({
      query: COMPARISONS_QUERIES.CHECK_NAME,
      variables: { name: comparisonName }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return (
      data.comparison.filter(({ comparison_id }: ComparisonResponse) => comparison_id !== comparisonId).length === 0
    )
  }

  async getComparisons(): Promise<ComparisonResponse[]> {
    const { data } = await this.apolloClient.query({ query: COMPARISONS_QUERIES.GET_COMPARISONS })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return data.comparison
  }

  async insertComparison(comparison: DraftComparison): Promise<ComparisonResponse> {
    const { items, subscriptionId, ...params } = comparison
    const { data } = await this.apolloClient.mutate({
      mutation: COMPARISON_MUTATIONS.InsertComparison,
      variables: {
        ...params,
        subscriptionId: subscriptionId || null,
        items: params.context === 'custom' ? [] : items,
        views: params.context === 'custom' ? { data: items.map((v) => ({ filterset_id: v.id })) } : undefined
      }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    this.invalidateComparisonCache()

    return data.insert_comparison_one
  }

  async updateComparison(comparison: Comparison): Promise<ComparisonResponse> {
    const { items, subscriptionId, ...params } = comparison
    const { data } = await this.apolloClient.mutate({
      mutation: COMPARISON_MUTATIONS.UpdateComparison,
      variables: {
        ...params,
        subscriptionId: subscriptionId || null,
        items: params.context === 'custom' ? [] : items,
        views:
          params.context === 'custom' ? items.map((v) => ({ comparison_id: comparison.id, filterset_id: v.id })) : []
      }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    this.invalidateComparisonCache()

    return data.update_comparison_by_pk
  }

  async deleteComparison(comparisonId: string): Promise<boolean> {
    const { data } = await this.apolloClient.mutate({
      mutation: COMPARISON_MUTATIONS.DeleteComparison,
      variables: { comparisonId, deletedAt: new Date().toISOString() }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    this.invalidateComparisonCache()

    return !!data.affected_rows
  }

  async getTags(): Promise<SetRequired<TagWithCount, 'itemsCount'>[]> {
    const { data } = await this.apolloClient.query({
      query: TAGS_QUERIES.ListTags
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return data.tag.map(parseTagWithItemsCount)
  }

  async getTagsByType(type: TagType, ids?: string[]): Promise<ItemTagWithTag[]> {
    const { data } = await this.apolloClient.query({
      fetchPolicy: 'network-only', // Avoid cache as we're caching with useQuery
      query: TAGS_QUERIES.ListItemTags,
      variables: {
        where: {
          _or: tagTypeKeys[type].map((key) => ({
            [camelToSnakeCase(key)]: ids?.length ? { _in: ids } : { _is_null: false }
          }))
        }
      }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return data.item_tag
  }

  async getTagItems(tagId: string, orItems: ItemTag[] = []): Promise<ItemTag[]> {
    const { data } = await this.apolloClient.query({
      fetchPolicy: 'network-only', // Avoid cache as we're caching with useQuery
      query: TAGS_QUERIES.ListItemsFromTag,
      variables: {
        where: {
          tag_id: { _eq: tagId },
          ...(orItems.length ? { _or: itemTagsToInClause(orItems) } : {})
        }
      }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return data.items
  }

  async insertTag(tag: DraftTag): Promise<SetRequired<TagWithItems, 'items' | 'itemsCount'>> {
    const parsedTag = toDbTagFormat(tag)

    const { data } = await this.apolloClient.mutate({
      mutation: TAGS_MUTATIONS.InsertTag,
      variables: {
        label: tag.label,
        color: tag.color,
        items: parsedTag.item_tags.length ? { data: parsedTag.item_tags } : undefined
      }
    })

    if (data.errors) {
      throw new Error('GraphQL error')
    }

    return parseTagWithItemsCount(data.insert_tag_one) as SetRequired<TagWithItems, 'items' | 'itemsCount'>
  }

  async updateTag(
    tag: TagWithItems,
    itemTagsToRemove?: RequiredItemTag[]
  ): Promise<SetRequired<TagWithItems, 'items' | 'itemsCount'>> {
    const { addItems, updateItems } = (tag.items || []).reduce(
      ({ addItems, updateItems }, item) => {
        if (item.itemTagId) {
          updateItems.push({
            subscription_id: item.subscriptionId || null,
            item_tag_id: item.itemTagId
          })
        } else {
          addItems.push({
            ...toDbItemTagFormat(item),
            tag_id: tag.id
          })
        }
        return { addItems, updateItems }
      },
      { addItems: [] as ItemTagDb[], updateItems: [] as { subscription_id: string | null; item_tag_id: string }[] }
    )

    // Update tag
    const { data } = await this.apolloClient.mutate({
      mutation: TAGS_MUTATIONS.UpdateTag,
      variables: {
        tagId: tag.id,
        label: tag.label,
        color: tag.color,
        addItems,
        updateItems: updateItems.map(({ item_tag_id, ...item }) => {
          return {
            _set: item,
            where: { item_tag_id: { _eq: item_tag_id } }
          }
        }),
        removeItems: itemTagsToRemove?.map(({ itemTagId }) => itemTagId) ?? []
      }
    })

    if (data.errors) {
      throw new Error('GraphQL error')
    }

    const results = data as {
      update_tag_by_pk: TagWithItemsCountResponse
      insert_item_tag: ReturningResults<ItemTag>
      update_item_tag_many: ReturningResults<ItemTag>[]
      delete_item_tag: { affected_rows: number }
    }

    const insertedItems = results.insert_item_tag.returning
    const updatedItems = Array.isArray(results.update_item_tag_many)
      ? results.update_item_tag_many.flatMap(({ returning }) => returning)
      : []

    return {
      ...parseTagWithItemsCount(results.update_tag_by_pk),
      items: [...insertedItems, ...updatedItems]
    }
  }

  async removeItemTag(itemTagId: string): Promise<ItemTag> {
    const { data } = await this.apolloClient.mutate({
      mutation: TAGS_MUTATIONS.RemoveItemTag,
      variables: { itemTagId }
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    this.apolloClient.cache.modify({
      fields: {
        item_tag: (existingRefs, { readField }) =>
          existingRefs.filter((ref: any) => itemTagId !== readField('item_tag_id', ref))
      }
    })

    return data.delete_item_tag_by_pk
  }

  async deleteTag(tagId: string): Promise<Tag | undefined> {
    try {
      const { data } = await this.apolloClient.mutate({
        mutation: TAGS_MUTATIONS.DeleteTag,
        variables: { tagId }
      })

      if (data.error) {
        throw new Error('GraphQL error')
      }

      this.apolloClient.cache.modify({
        fields: {
          tag: (existingRefs, { readField }) => existingRefs.filter((ref: any) => tagId !== readField('tag_id', ref))
        }
      })

      return data.delete_tag_by_pk
    } catch (error) {
      return undefined
    }
  }

  async getFavorites(): Promise<Favorite[]> {
    const { data } = await this.apolloClient.query({
      query: LIST_FAVORITES
    })

    if (data.error) {
      throw new Error('GraphQL error')
    }

    return data.favorite
  }

  async insertFavorite(favorite: DraftFavorite, abortSignal?: AbortSignal): Promise<Favorite> {
    const { data } = await this.apolloClient.mutate({
      mutation: FAVORITES_MUTATIONS.InsertFavorite,
      variables: favorite,
      context: abortSignal
        ? {
            fetchOptions: {
              signal: abortSignal
            }
          }
        : undefined
    })
    if (data.error) {
      throw new Error('GraphQL error')
    }
    return data.insert_favorite_one
  }

  async updateFavoriteName(favoriteId: string, name: string): Promise<Favorite> {
    const { data } = await this.apolloClient.mutate({
      mutation: FAVORITES_MUTATIONS.UpdateFavoriteName,
      variables: {
        favoriteId,
        name
      }
    })
    if (data.error) {
      throw new Error('GraphQL error')
    }
    return data.update_favorite_by_pk
  }

  async deleteFavorite(favoriteId: string, abortSignal?: AbortSignal): Promise<boolean> {
    const { data } = await this.apolloClient.mutate({
      mutation: FAVORITES_MUTATIONS.DeleteFavorite,
      variables: { favoriteId },
      context: abortSignal
        ? {
            fetchOptions: {
              signal: abortSignal
            }
          }
        : undefined
    })
    if (data.error) {
      throw new Error('GraphQL error')
    }

    return !!data.delete_favorite_by_pk
  }

  async bulkUpdateAccountTier(accountIds: string[], tierId: string) {
    const data = await this.mutate<{ update_subscription: { affected_rows: number } }>(
      {
        mutation: SUBSCRIPTION_MUTATIONS.BulkUpdateAccountTier,
        variables: { accountIds, tierId }
      },
      { useRoles: true }
    )

    return data.update_subscription.affected_rows
  }
}
