import { FilterOption, Filters, ProductFilters, activeFilters } from '@client/types/filterset'
import deepEqual from 'fast-deep-equal'
import { useCallback, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useUpdateEffect } from 'usehooks-ts'

type AttrBooleanState = Record<string, boolean>

// Check if a filter is disabled
const isFilterDisabled = (filter: FilterOption, visibleFilters: FilterOption[]) => {
  return !visibleFilters.some((f) => deepEqual(f, filter))
}

// Initially collapse some predefined filters
const startCollapsed = (filter: FilterOption) => {
  return [
    ProductFilters.segments.filterKey,
    ProductFilters.subsegments.filterKey,
    ProductFilters.city.filterKey,
    ProductFilters.banner.filterKey
  ].includes(filter.filterKey)
}

export interface FiltersDrawerAttributesOptions {
  visibleFilters?: FilterOption[]
  searchTerm?: string
  filters?: Filters
  showOnlyActive?: boolean
  maxFiltersToShow?: number
}

export const useFiltersDrawerAttributes = (
  allFilters: FilterOption[],
  options: FiltersDrawerAttributesOptions = {}
) => {
  const { visibleFilters: defaultVisibleFilters, searchTerm = '', filters, showOnlyActive, maxFiltersToShow } = options

  const visibleFilters = useMemo(() => defaultVisibleFilters ?? allFilters, [allFilters, defaultVisibleFilters])

  const appliedFilters = useMemo(
    () => (filters ? activeFilters(filters, allFilters).map(({ label }) => label) : []),
    [filters, allFilters]
  )

  // Sets the visibility of the filters based on the search term
  const { attributeVisibility, noMatches, availableHiddenFiltersCount } = useMemo(() => {
    const searchTermRegex = new RegExp(searchTerm, 'i')
    let visibleFiltersCount = 0
    let availableHiddenFiltersCount = 0
    const attributeVisibility = allFilters.reduce((acc, { label, keywords }) => {
      const bySearch = !searchTerm || !!label.match(searchTermRegex) || keywords.includes(searchTerm.toLowerCase())
      const byActive = showOnlyActive && appliedFilters.length ? appliedFilters.includes(label) : true
      acc[label] = bySearch && byActive
      if (acc[label]) {
        visibleFiltersCount++
        // Hide filters that exceed the max filters to show
        if (maxFiltersToShow && visibleFiltersCount > maxFiltersToShow) {
          acc[label] = false
          availableHiddenFiltersCount++
        }
      }
      return acc
    }, {} as AttrBooleanState)
    const noMatches =
      (!!searchTerm || !!appliedFilters.length) && Object.values(attributeVisibility).every((visible) => !visible)
    return { attributeVisibility, noMatches, availableHiddenFiltersCount }
  }, [allFilters, appliedFilters, maxFiltersToShow, searchTerm, showOnlyActive])

  const filtersToRender = useMemo(() => {
    return allFilters.filter(({ label }) => attributeVisibility[label] || appliedFilters?.includes(label))
  }, [allFilters, appliedFilters, attributeVisibility])

  // Disable filters that are not in the visible filters
  const attributeDisabling = useMemo(() => {
    return allFilters.reduce((acc, filter) => {
      acc[filter.label] = isFilterDisabled(filter, visibleFilters)
      return acc
    }, {} as AttrBooleanState)
  }, [allFilters, visibleFilters])

  // Initally collapses filters that are disabled or have a specific starting state
  const [attributeCollapsed, setAttributeCollapsed] = useState<AttrBooleanState>(
    allFilters.reduce((acc, filter) => {
      acc[filter.label] =
        attributeDisabling[filter.label] || (startCollapsed(filter) && !appliedFilters.includes(filter.label))
      return acc
    }, {} as AttrBooleanState)
  )

  // Check if all filters are collapsed
  const isAllCollapsed = useMemo(() => {
    return Object.entries(attributeCollapsed)
      .filter(([key]) => attributeVisibility[key])
      .every(([, collapsed]) => collapsed)
  }, [attributeCollapsed, attributeVisibility])

  // Check if at least one filter can be toggled
  const isToggleCollapseAllEnabled = useMemo(() => {
    return Object.entries(attributeDisabling)
      .filter(([key]) => attributeVisibility[key])
      .some(([, disabled]) => !disabled)
  }, [attributeDisabling, attributeVisibility])

  const handleToggleCollapseAll = useCallback(() => {
    setAttributeCollapsed((prev) =>
      Object.keys(prev).reduce((acc, key) => {
        acc[key] = isAllCollapsed ? attributeDisabling[key] : true
        return acc
      }, {} as AttrBooleanState)
    )
  }, [attributeDisabling, isAllCollapsed])

  const handleAttributeCollapse = useCallback((attribute: string, collapsed: boolean) => {
    setAttributeCollapsed((prev) => ({
      ...prev,
      [attribute]: collapsed
    }))
  }, [])

  const autoExpandSearchedItems = useDebouncedCallback((searchTerm: string) => {
    setAttributeCollapsed((prev) =>
      allFilters.reduce((acc, filter) => {
        acc[filter.label] = searchTerm
          ? attributeDisabling[filter.label] || (attributeVisibility[filter.label] ? false : prev[filter.label])
          : startCollapsed(filter) && !appliedFilters?.includes(filter.label)
        return acc
      }, {} as AttrBooleanState)
    )
  }, 200)

  useUpdateEffect(() => {
    autoExpandSearchedItems(searchTerm)
  }, [searchTerm])

  return {
    attributeCollapsed,
    attributeDisabling,
    attributeVisibility,
    availableHiddenFiltersCount,
    filtersToRender,
    handleAttributeCollapse,
    handleToggleCollapseAll,
    isAllCollapsed,
    isToggleCollapseAllEnabled,
    noMatches
  }
}
